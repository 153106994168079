import React from 'react';
import { Typography, Container, Grid, Box, List, ListItem } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { StartCollectingSearch, ImgTag } from 'components/shared';
import bannerImg1 from 'assets/img/banner-img1.png';
import bannerImg2 from 'assets/img/banner-img2.png';
import bannerImg3 from 'assets/img/banner-img3.png';
import bannerImg4 from 'assets/img/banner-img4.png';

const BANNER_LIST = [
    {
        text: 'Create & sell media and earn tokens',
        color: 'brand-orange',
    },
    {
        text: 'Buy collections',
        color: 'brand-red',
    },
    {
        text: 'Become a diamond collector',
        color: 'brand-gold',
    },
    {
        text: 'Find collaboraters to create epic projects',
        color: 'brand-green',
    },
];

const Banner = () => {
    return (
        <div className="banner">
            <Container maxWidth="xl">
                <Grid container justifyContent="space-between" spacing={3} alignItems="center">
                    <Grid item md={6} className="banner-left">
                        <Typography variant="h1">Find Your Treasure</Typography>
                        <Box mt={2} mb={3}>
                            <Typography variant="h4" className="text-capitalize">
                                Algorand Digital Asset Exchange
                            </Typography>
                        </Box>
                        <StartCollectingSearch id="banner-search" />
                        <Box mt={4}>
                            <List>
                                {BANNER_LIST.map((item, i) => (
                                    <ListItem key={i} disableGutters>
                                        <Box
                                            height={24}
                                            width={24}
                                            mr={2}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            className={`circle bg-${item.color}`}
                                        >
                                            <ArrowForward style={{ fontSize: 12 }} />
                                        </Box>
                                        <Box
                                            width={'calc(100% - 24px'}
                                            fontWeight="fontWeightBold"
                                            fontSize={'1.125rem'}
                                            lineHeight={'1.5rem'}
                                        >
                                            {item.text}
                                        </Box>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Grid>

                    <Grid item md={6} className="banner-right">
                        <div className="banner-images">
                            <div className="left">
                                <ImgTag
                                    src={bannerImg1}
                                    alt="Banner image"
                                    tag="video"
                                    className="img-1 rounded"
                                />
                                <ImgTag
                                    src={bannerImg2}
                                    alt="Banner image"
                                    tag="img"
                                    className="img-2 rounded"
                                />
                            </div>
                            <div className="right">
                                <ImgTag
                                    src={bannerImg3}
                                    alt="Banner image"
                                    tag="audio"
                                    className="img-3 rounded"
                                />
                                <ImgTag
                                    src={bannerImg4}
                                    alt="Banner image"
                                    tag="img"
                                    className="img-4 rounded"
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Banner;
