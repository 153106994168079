import { combineReducers } from 'redux';
import authReducer from './auth/reducer';
import profileSettingsReducer from './profile-settings/reducer';
import profileReducer from './profile/reducer';
import assetsReducer from './asset/reducer';
import createdAssetsReducer from './createdAsset/reducer';
import marketplaceReducer from './marketplace/reducer';
import favoriteAssetsReducer from './favoriteAssets/reducer';
import bestVotedAssetsReducer from './bestVotedAssets/reducer';
import collectedAssetsReducer from './collectedAssets/reducer';
import singleAssetReducer from './singleAsset/reducer';
import algorandReducer from './algorand/reducer';
import followersReducer from './followers/reducer';

const rootReducer = combineReducers({
    auth: authReducer,
    profile: profileReducer,
    profileSettings: profileSettingsReducer,
    assets: assetsReducer,
    marketplace: marketplaceReducer,
    createdAssets: createdAssetsReducer,
    favoriteAssets: favoriteAssetsReducer,
    bestVotedAssets: bestVotedAssetsReducer,
    collectedAssets: collectedAssetsReducer,
    singleAsset: singleAssetReducer,
    algorand: algorandReducer,
    followers: followersReducer,
});

export default rootReducer;
