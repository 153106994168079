import algosdk from 'algosdk';

import MyAlgo from '@randlabs/myalgo-connect';

import { getAlgodClient } from 'transactions/algorand';
import { checkAccountAmount } from 'transactions/algorand/validations';
import { waitForConfirmation } from 'transactions/algorand/waitConfirmation';

export async function createEscrowContract(
    hostAddress,
    contract,
    assetId,
    getAccountInfo,
    setContract,
) {
    try {
        console.log('Start creating SaleByEscrow Smart contract');
        if (!contract) {
            const message = 'Does not found teal for contract';
            throw new Error(message);
        }
        const myAlgoWallet = new MyAlgo();
        const algodClient = getAlgodClient();

        const amountForContract = getAmountForContract();

        const accountAmount = await checkAccountAmount(
            hostAddress,
            amountForContract,
            getAccountInfo,
        );
        if (!accountAmount) {
            const message = 'Your account balance is not enough for this transaction.';
            throw new Error(message);
        }
        const approvalProgram = new Uint8Array(
            Buffer.from(contract.compiled_teal_result, 'base64'),
        );

        const params = await algodClient.getTransactionParams().do();
        params.fee = 1000;
        params.flatFee = true;
        const lSig = algosdk.makeLogicSig(approvalProgram);
        // Pay 1 algo from seller to escrow for fee
        const paymentTxnForAsaTxn = {
            ...params,
            fee: 1000,
            flatFee: true,
            type: 'pay',
            from: hostAddress,
            to: lSig.address(),
            amount: 1000000,
            note: new Uint8Array(Buffer.from('...')),
        };

        const signPayEscrowTx = await myAlgoWallet.signTransaction(paymentTxnForAsaTxn);
        const payForEscrow = await algodClient.sendRawTransaction(signPayEscrowTx.blob).do();
        await waitForConfirmation(algodClient, payForEscrow.txId);
        // Opt-in escrow in asset
        const optEscrowTxn = {
            ...params,
            fee: 1000,
            type: 'axfer',
            from: lSig.address(),
            to: lSig.address(),
            assetIndex: assetId,
            amount: 0, // 1 Algo
            note: new Uint8Array(Buffer.from('')),
            revocationTarget: undefined,
            closeRemainderTo: undefined,
            appArgs: [],
        };
        const txObj = new algosdk.Transaction(optEscrowTxn);
        const signEscrowTx = algosdk.signLogicSigTransactionObject(txObj, lSig);
        const optEscrowtx = await algodClient.sendRawTransaction(signEscrowTx.blob).do();
        await waitForConfirmation(algodClient, optEscrowtx.txId);
        // Send asset from seller to escrow
        const sendAssetToEscrowTxn = {
            ...params,
            type: 'axfer',
            from: hostAddress,
            to: lSig.address(),
            assetIndex: assetId,
            amount: 1, //asset.amount
            note: new Uint8Array(Buffer.from('')),
        };
        const signAssetTransferToEscrow = await myAlgoWallet.signTransaction(sendAssetToEscrowTxn);
        const assetTransferToEscrowTx = await algodClient
            .sendRawTransaction(signAssetTransferToEscrow.blob)
            .do();
        await waitForConfirmation(algodClient, assetTransferToEscrowTx.txId);
        console.log('Done', assetTransferToEscrowTx);
        await setContract(contract?.guid);
        return { status: 'success' };
    } catch (err) {
        console.log(err);
        return { status: 'error', message: err.message };
    }
}

function getAmountForContract() {
    const oneAlgoToContract = 1000000;
    const fees = 3000;
    const amountForContract = 100000;
    const allAmount = oneAlgoToContract + fees + amountForContract;
    return allAmount;
}
