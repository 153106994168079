import React, { useState } from 'react';
import { Container, Box, Typography } from '@material-ui/core';
import TopBidsCard from '../../components/elements/cards/TopBidsCard';
import MiniInfoModal from '../../components/elements/modal/miniInfoModal';

const MarketplaceAuction = () => {
    const [auctionModal, setAuctionModal] = useState(false);
    const onOpenAuctionModal = () => {
        onCloseAuctionModal();
        setAuctionModal(true);
    };
    const onCloseAuctionModal = () => {
        setAuctionModal(false);
    };
    return (
        <>
            <div className="marketplace-auction">
                <Container maxWidth="xl">
                    <Box className="marketplace-auction-title text-center" mb={3}>
                        <Typography variant="h3" component="h2">
                            <span onClick={onOpenAuctionModal}>Auction</span>
                        </Typography>
                    </Box>
                    <TopBidsCard />
                </Container>
            </div>
            <MiniInfoModal
                open={auctionModal}
                close={onCloseAuctionModal}
                title="This is coming soon! Please check it in our next release!"
            />
        </>
    );
};

export default MarketplaceAuction;
