import axios from 'axios';
import { ASSETS_CONFIG } from 'configs';

const getCollectedAssets = (username) =>
    axios.get(`${ASSETS_CONFIG.getCollectedAssets}?collector=${username}`);

const loadMoreCollectedItems = (loadMoreURL) => axios.get(loadMoreURL);
const getCollectorMetadata = (data) => axios.post(ASSETS_CONFIG.getCollectorMetadata, data);
const collectAsset = (guid, data) => axios.post(`${ASSETS_CONFIG.collectAsset}${guid}/`, data);

const upvoteCollectedAsset = (guid) => axios.post(`${ASSETS_CONFIG.upvoteAsset}${guid}/vote/`);
const unUpvoteCollectedAsset = (guid) => axios.delete(`${ASSETS_CONFIG.upvoteAsset}${guid}/vote/`);

const toFavoritesCollectedAsset = (guid) =>
    axios.post(`${ASSETS_CONFIG.favoriteAssets}${guid}/favorite/`);
const removeFromFavoritesCollected = (guid) =>
    axios.delete(`${ASSETS_CONFIG.favoriteAssets}${guid}/favorite/`);
const updateAsset = (guid, values) =>
    axios.patch(`${ASSETS_CONFIG.updateAsset}${guid}/visibility/`, values);

const collectAssetOfferByEscrow = (guid, data) =>
    axios.post(`${ASSETS_CONFIG.collectAssetOfferByEscrow}${guid}/`, data);

const data = {
    getCollectorMetadata,
    collectAsset,
    getCollectedAssets,
    loadMoreCollectedItems,
    upvoteCollectedAsset,
    unUpvoteCollectedAsset,
    toFavoritesCollectedAsset,
    removeFromFavoritesCollected,
    updateAsset,
    collectAssetOfferByEscrow,
};

export default data;
