import * as TYPES from './types.js';

const initialState = {
    user: {},
    isLoggedIn: localStorage.getItem('access') ? true : false,
    forgotPasswordSent: false,
    needToLogin: false,
};

const authReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TYPES.SIGNUP_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoggedIn: false,
            };
        case TYPES.SIGNUP_FAIL:
            return {
                ...state,
                isLoggedIn: false,
            };
        case TYPES.VERIFY_SIGNUP_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
            };
        case TYPES.VERIFY_SIGNUP_FAIL:
            return {
                ...state,
                isLoggedIn: false,
            };
        case TYPES.LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                access: payload.access,
                refresh: payload.refresh,
                user: payload.user,
            };
        case TYPES.LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        case TYPES.GET_ME_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
            };
        case TYPES.CHANGE_USER_AVATAR_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: { ...state.user, avatar: payload.data },
            };
        case TYPES.LOGIN_REFRESH_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                access: payload.access,
                refresh: payload.refresh,
            };
        case TYPES.LOGIN_REFRESH_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                access: null,
                refresh: null,
            };
        case TYPES.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
                forgotPasswordSent: true,
            };
        case TYPES.FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                forgotPasswordSent: false,
            };
        case TYPES.VERIFY_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
                forgotPasswordCode: payload.code,
            };
        case TYPES.VERIFY_FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                forgotPasswordCode: null,
            };
        case TYPES.VERIFY_EMAIL_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
                emailCode: payload.code,
            };
        case TYPES.VERIFY_EMAIL_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                emailCode: null,
            };
        case TYPES.VERIFIED_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
                forgotPasswordCode: null,
            };
        case TYPES.VERIFIED_FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                forgotPasswordCode: null,
            };
        case TYPES.LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                access: null,
                refresh: null,
                user: null,
            };
        case TYPES.NEED_TO_LOGIN:
            return {
                ...state,
                needToLogin: payload,
            };
        default:
            return state;
    }
};

export default authReducer;
