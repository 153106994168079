import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    Stepper,
    Box,
    Step,
    StepLabel,
    Button,
    Typography,
    Grid,
    Switch,
    Checkbox,
    Link,
} from '@material-ui/core';
import { UploadAssetType, Logo } from 'components/shared';
import { FormikProvider, useFormik } from 'formik';
import Field from 'components/shared/fields/Field';
import ButtonTag from 'components/shared/ButtonTag';
import { AddOutlined } from '@material-ui/icons';
import bannerImg1 from 'assets/img/banner-img1.png';
import AssetPreview from './assetPreview';
import { getAssetsTags, addAssetsTags } from 'redux/marketplace/actions';
import { useSelector, useDispatch } from 'react-redux';
import { UNIT_NAME_MESSAGE, QUANTITY_MESSAGE } from 'configs';
import Thumb from './assetImage';
import QuantityValidation from './quantityValidation';
import DropdownAsset from '../../components/shared/dropdown/dropdown-asset';
import { ALLOWED_FILE_TYPES, ALLOWED_FILE_EXTENSIONS } from 'configs/assetsConfig';
import Popover from '@material-ui/core/Popover';
import { assetCreateValidationStep3 } from 'services/yup-schemas/createAssetStep3';
import { assetCreateValidationStep2 } from 'services/yup-schemas/createAssetStep2';
import AlgoFont from '../../assets/img/algo-font.svg';
import { setNotification } from 'redux/profile/actions';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

const UploadAsset = () => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [previewDialog, setPreviewDialog] = useState(false);
    const [isSeries, setIsSeries] = useState(false);
    const [tags, setTags] = useState([]);
    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState(false);
    const steps = getSteps();
    const { assetsTags } = useSelector((state) => state.marketplace);
    const dispatch = useDispatch();
    const [openQuantityDialog, setOpenQuantityDialog] = useState(false);
    const [quantityNoRequiredValue, setQuantityNoRequiredValue] = useState(1);
    const [assetManager, setAssetManager] = useState('creator');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openPopover = Boolean(anchorEl);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const openPopover2 = Boolean(anchorEl2);

    const giveNotification = (status, message) => {
        dispatch(setNotification({ status, message }));
    };

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const handlePopoverOpen2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handlePopoverClose2 = () => {
        setAnchorEl2(null);
    };

    const onCloseDialog = () => {
        setOpenQuantityDialog(false);
    };

    useEffect(() => {
        dispatch(getAssetsTags());
    }, [dispatch]);

    const onOpenEmailDialog = () => {
        setPreviewDialog(true);
    };

    const formikStep1 = useFormik({
        initialValues: {
            usSingleAssets: true,
        },
        onSubmit: (values) => {
            handleNext();
        },
    });

    const formikStep2 = useFormik({
        initialValues: {
            title: '',
            unitName: 'NFT',
            description: '',
            tag: '',
            custom_tag: '',
        },
        validationSchema: assetCreateValidationStep2,
        onSubmit: (values) => {
            handleNext();
        },
    });

    const formikStep3 = useFormik({
        initialValues: {
            quantity: 1,
            royalties: `${(10).toFixed(2)} %`,
            frozen: true,
            physical_asset: false,
            price: (0).toFixed(3),
            seriesPrice: '',
            termsAndPrivacy: '',
            price_is_visible: false,
        },
        validationSchema: assetCreateValidationStep3,
        enablereinitialize: true,
        onSubmit: (values) => {
            if (!file) {
                return setFileError(true);
            }
            onOpenEmailDialog();
        },
    });

    const chooseForm = (activeStep) => {
        switch (activeStep) {
            case 0:
                return formikStep1;
            case 1:
                return formikStep2;
            case 2:
                return formikStep3;
            default:
                return formikStep3;
        }
    };

    const onChangeUpload = (e) => {
        const file = e.target.files[0];
        const extensionOfFile = file?.type;
        const maxAllowedSize = 50 * Math.pow(1024, 2);
        const fileSize = file?.size;
        if (!ALLOWED_FILE_TYPES.includes(extensionOfFile)) {
            return giveNotification(
                'info',
                `Please input one of these types ${ALLOWED_FILE_EXTENSIONS.toString()}`,
            );
        }
        if (fileSize > maxAllowedSize) {
            return giveNotification('info', 'Please input file less then 50 MB');
        }
        setFileError(false);
        setFile(file);
    };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const onClosePreviewDialog = () => {
        setPreviewDialog(false);
    };

    const updateTagsInfo = (value) => {
        delete formikStep2.errors.tag;
        formikStep2.values.tag = JSON.stringify(value.map((x) => x.slug));
        setTags(value);
    };

    const onSelectTag = (tag) => {
        const exist = tags.find((x) => x.category === tag.category);
        if (exist && exist.slug === tag.slug) {
            const value = tags.filter((x) => x.slug !== tag.slug);
            updateTagsInfo(value);
        } else if (exist) {
            const value = [...tags.filter((x) => x.category !== tag.category), tag];
            updateTagsInfo(value);
        } else {
            const value = [...tags, tag];
            updateTagsInfo(value);
        }
    };
    function getSteps() {
        return ['Asset Type', 'Set Information', 'Upload Asset'];
    }

    const changeQuantity = (e) => {
        const value = e.target.value;
        if (value > 10) {
            setQuantityNoRequiredValue(value);
            return setOpenQuantityDialog(true);
        }
        formikStep3.setFieldValue('quantity', value);
    };

    const confirmQuantityValue = (value) => {
        formikStep3.setFieldValue('quantity', value);
        setOpenQuantityDialog(false);
    };
    /*Switch*/
    const [switchState, setSwitchState] = React.useState({
        checkedA: false,
        checkedB: true,
    });

    const handleSwitch = (event) => {
        setSwitchState({ ...switchState, [event.target.name]: event.target.checked });
    };

    function addCustomTag(event) {
        if (
            (event.charCode || event.keyCode) === 13 &&
            event.target.name === 'custom_tag' &&
            event.target.value
        ) {
            dispatch(addAssetsTags(event.target.value));
            formikStep2.values.custom_tag = '';
        }
    }
    const blurRoyalties = (e) => {
        const value = e.target.value.replace(/\s/g, '');
        if (!value.match(/%/)) {
            formikStep3.setFieldValue('royalties', `${value} %`);
        }
    };
    // we need to know only at first or always show royalties with two decimals
    const changeRoyalities = (e) => {
        const value = e.target.value.replace(/ |%/g, '');
        const floatPart = value.split('.')[1];
        const decimalsCount = floatPart?.length || 0;
        if (decimalsCount < 3) {
            formikStep3.setFieldValue('royalties', e.target.value);
        }
    };

    const onChangeUnitname = (e) => {
        const value = e.target.value;
        if (value.length > 8) {
            return;
        }
        formikStep2.setFieldValue('unitName', e.target.value);
    };

    const changePrice = (e) => {
        const value = e.target.value;
        const lessThreeDecimal = Number.isInteger(Number(value * 10 ** 3));
        if (lessThreeDecimal) {
            formikStep3.setFieldValue('price', e.target.value);
        }
    };

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <Box
                        display="flex"
                        justifyContent="center"
                        mt={3}
                        className="asset-type-parent"
                    >
                        <UploadAssetType active={!isSeries} setIsSeries={setIsSeries} />
                        {/* <UploadAssetType
                            type="multiple"
                            active={isSeries}
                            setIsSeries={setIsSeries}
                        /> */}
                    </Box>
                );
            case 1:
                return (
                    <Box>
                        <Field
                            field="input"
                            type="text"
                            label="Title"
                            name="title"
                            {...formikStep2.getFieldProps('title')}
                        />
                        <Field
                            field="input"
                            type="text"
                            label="Unit Name (optional)"
                            tooltip={UNIT_NAME_MESSAGE}
                            name="unitName"
                            {...formikStep2.getFieldProps('unitName')}
                            onChange={onChangeUnitname}
                        />
                        <Field
                            field="input"
                            type="text"
                            label="Description"
                            name="description"
                            multiline={true}
                            rows={4}
                            {...formikStep2.getFieldProps('description')}
                        />

                        {/* START: Only for multiple type */}
                        {isSeries && (
                            <Grid container justifyContent="space-between" spacing={2}>
                                <Grid item md={6}>
                                    <Field
                                        field="input"
                                        type="text"
                                        label="Royalties"
                                        name="royalties"
                                        {...formikStep2.getFieldProps('royalties')}
                                        onBlur={blurRoyalties}
                                        onChange={changeRoyalities}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <Field
                                        field="input"
                                        type="number"
                                        label="Quantity"
                                        tooltip={QUANTITY_MESSAGE}
                                        name="quantity"
                                        {...formikStep2.getFieldProps('quantity')}
                                        onChange={changeQuantity}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {/* END: Only for multiple type */}

                        <Box>
                            <Typography variant="body1" className="label">
                                {!!formikStep2.errors.tag && formikStep2.touched.tag ? (
                                    <span style={{ color: 'red' }}>Tags are required</span>
                                ) : (
                                    'Media Type'
                                )}
                            </Typography>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                flexWrap="wrap"
                                mt={1}
                            >
                                {assetsTags?.map((item) => (
                                    <ButtonTag
                                        key={item.slug}
                                        text={item.name}
                                        slug={item.slug}
                                        icon={
                                            item.slug && (
                                                <i
                                                    className={`icon-${
                                                        item.category === 'custom'
                                                            ? 'label'
                                                            : item.slug
                                                    }`}
                                                />
                                            )
                                        }
                                        selected={!!tags.find((x) => x.slug === item.slug)}
                                        onSelectTagFromUpload={onSelectTag}
                                        category={item.category}
                                    />
                                ))}
                                <Box className="custom-label">
                                    <Box className="new-label">
                                        <i className="icon-label" style={{ fontSize: 24 }} />
                                        <Field
                                            field="input"
                                            type="text"
                                            className="no-margin"
                                            placeholder="Add Custom"
                                            name="custom_tag"
                                            onKeyDown={addCustomTag}
                                            {...formikStep2.getFieldProps('custom_tag')}
                                        />
                                    </Box>
                                    <i
                                        className="popover-icon icon-information-outline"
                                        aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={handlePopoverOpen}
                                        onMouseLeave={handlePopoverClose}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                );
            case 2:
                return (
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={2}
                        className="inner-padd"
                    >
                        <Grid item xs={12} sm={5} md={5}>
                            <Box
                                mb={1}
                                px={4}
                                py={4}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                textAlign="center"
                                className="upload-card"
                            >
                                <Box
                                    my="auto"
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                >
                                    <div className="logo-with-icon">
                                        {file ? (
                                            <Thumb file={file} style={{ width: '100%' }} />
                                        ) : (
                                            <>
                                                <Logo type="logoIcon" width="167" />
                                                <span>
                                                    <AddOutlined style={{ fontSize: 42 }} />
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <Box
                                        fontSize={20}
                                        fontWeight="bold"
                                        fontFamily="h1.fontFamily"
                                        mt={3}
                                    >
                                        Upload File
                                    </Box>
                                </Box>
                                <Box mt={4}>
                                    <Field
                                        field="input"
                                        type="file"
                                        name="file"
                                        // {...formikStep2.getFieldProps('file')}
                                        onChange={onChangeUpload}
                                        className="upload-assets-button"
                                    />
                                    {fileError && !file && (
                                        <span style={{ color: 'red' }}>File is required</span>
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={7} md={6}>
                            <Field
                                field="input"
                                type="text"
                                label="Royalties"
                                name="royalties"
                                {...formikStep3.getFieldProps('royalties')}
                                onBlur={blurRoyalties}
                                onChange={changeRoyalities}
                            />
                            <Field
                                field="input"
                                type="number"
                                label="Quantity"
                                tooltip={QUANTITY_MESSAGE}
                                name="quantity"
                                className="no-margin"
                                {...formikStep3.getFieldProps('quantity')}
                                onChange={changeQuantity}
                                disabled
                            />
                            <Box
                                mt={2}
                                mb={2}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography variant="body2" component="strong">
                                    Add to Marketplace
                                    <i
                                        className="popover-icon icon-information-outline"
                                        aria-owns={openPopover2 ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={handlePopoverOpen2}
                                        onMouseLeave={handlePopoverClose2}
                                        style={{ marginLeft: 8 }}
                                    />
                                </Typography>
                                <Switch
                                    checked={formikStep3.values.price_is_visible}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    {...formikStep3.getFieldProps('price_is_visible')}
                                />
                            </Box>
                            <Box className="price-field">
                                <Field
                                    field="input"
                                    type="number"
                                    label="Price"
                                    name="price"
                                    disabled={!formikStep3.values.price_is_visible}
                                    {...formikStep3.getFieldProps('price')}
                                    onChange={changePrice}
                                />
                                <Box className="price-field-algo">
                                    <img src={AlgoFont} alt="Algo" />
                                </Box>
                            </Box>
                            <DropdownAsset
                                assetManager={assetManager}
                                setAssetManager={setAssetManager}
                            />
                            <Box mt={2} mb={2} display="flex" alignItems="center">
                                <Checkbox
                                    name="physical_asset"
                                    color="primary"
                                    style={{ marginLeft: '-11px' }}
                                    checked={formikStep3.values.physical_asset}
                                    {...formikStep3.getFieldProps('physical_asset')}
                                    disabled
                                />
                                <Typography variant="body2" component="strong">
                                    Physical asset
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                );
            case 3: // for multiple assets
                return (
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item md={6}>
                            <Box pr={3}>
                                <Box
                                    mt={2}
                                    mb={2}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography variant="body2" component="strong">
                                        Add to Marketplace
                                        <i
                                            className="popover-icon icon-information-outline"
                                            aria-owns={
                                                openPopover2 ? 'mouse-over-popover' : undefined
                                            }
                                            aria-haspopup="true"
                                            onMouseEnter={handlePopoverOpen2}
                                            onMouseLeave={handlePopoverClose2}
                                            style={{ marginLeft: 8 }}
                                        />
                                    </Typography>
                                    <Switch
                                        checked={switchState.checkedB}
                                        onChange={handleSwitch}
                                        color="primary"
                                        name="checkedB"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </Box>
                                <Field
                                    field="input"
                                    type="text"
                                    label="Price of Series"
                                    name="seriesPrice"
                                    className="no-margin"
                                    {...formikStep2.getFieldProps('seriesPrice')}
                                    onChange={changePrice}
                                />
                                <Box mt={2} mb={2} display="flex" alignItems="center">
                                    <Checkbox
                                        name="termsAndPrivacy"
                                        color="primary"
                                        style={{ marginLeft: '-11px' }}
                                        checked={Boolean(formikStep2.values.termsAndPrivacy)}
                                        onChange={formikStep2.handleChange}
                                        disabled
                                    />
                                    <Typography variant="body2" component="strong">
                                        Series of Physical Assets
                                    </Typography>
                                </Box>
                                <Grid container spacing={2}>
                                    <Grid item md={6}>
                                        <Box className="wallet-card w-auto" component="li" mx={0}>
                                            <Logo type="logoIconGray" width="53" />
                                            <Typography
                                                variant="body2"
                                                component="strong"
                                                className="name"
                                            >
                                                Upload Files
                                            </Typography>
                                            <Link to="/">Upload</Link>
                                        </Box>
                                    </Grid>
                                    <Grid item md={6}>
                                        <img
                                            className="uploaded-image active"
                                            src={bannerImg1}
                                            alt="dummy"
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <img
                                            className="uploaded-image"
                                            src={bannerImg1}
                                            alt="dummy"
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <img
                                            className="uploaded-image"
                                            src={bannerImg1}
                                            alt="dummy"
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Field
                                field="input"
                                type="text"
                                label="name"
                                name="name"
                                {...formikStep2.getFieldProps('name')}
                            />
                            <Field
                                field="input"
                                type="text"
                                label="Unit Name (optional)"
                                tooltip={UNIT_NAME_MESSAGE}
                                name="unitName"
                                {...formikStep2.getFieldProps('unitName')}
                                onChange={onChangeUnitname}
                            />
                            <Field
                                field="input"
                                type="text"
                                label="Description"
                                name="description"
                                className="no-margin"
                                {...formikStep2.getFieldProps('description')}
                            />
                            <Box
                                mt={2}
                                mb={2}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography variant="body2" component="strong">
                                    Add to Marketplace
                                    <i
                                        className="popover-icon icon-information-outline"
                                        aria-owns={openPopover2 ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={handlePopoverOpen2}
                                        onMouseLeave={handlePopoverClose2}
                                        style={{ marginLeft: 8 }}
                                    />
                                </Typography>
                                <Switch
                                    checked={switchState.checkedA}
                                    onChange={handleSwitch}
                                    color="primary"
                                    name="checkedA"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </Box>
                            <Field
                                field="input"
                                type="text"
                                label="Price"
                                name="price"
                                className="no-margin"
                                {...formikStep2.getFieldProps('price')}
                                onChange={changePrice}
                            />
                            <Box mt={2} mb={2} display="flex" alignItems="center">
                                <Checkbox
                                    name="termsAndPrivacy"
                                    color="primary"
                                    style={{ marginLeft: '-11px' }}
                                    checked={Boolean(formikStep2.values.termsAndPrivacy)}
                                    onChange={formikStep2.handleChange}
                                />
                                <Typography variant="body2" component="strong">
                                    Add to Marketplace
                                    <i
                                        className="popover-icon icon-information-outline"
                                        aria-owns={openPopover2 ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={handlePopoverOpen2}
                                        onMouseLeave={handlePopoverClose2}
                                        style={{ marginLeft: 8 }}
                                    />
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                );
            default:
        }
    }

    return (
        <>
            <Container maxWidth="xl">
                <div
                    className="
                upload-content"
                >
                    <Box className="upload-assets">
                        <Box display="flex" justifyContent="center" mt={3}>
                            <Typography variant="h4">Upload Asset</Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" mt={1} mb={{ xs: 1, sm: 5 }}>
                            <Typography variant="body2">Start sharing your treasure.</Typography>
                        </Box>
                        <Stepper activeStep={activeStep} alternativeLabel className="stepper">
                            {steps?.map((label) => (
                                <Step key={label}>
                                    <StepLabel className="step-label">{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <div>
                            {activeStep === steps.length ? (
                                <div>
                                    <Typography className={classes.instructions}>
                                        All steps completed
                                    </Typography>
                                    <Button onClick={handleReset}>Reset</Button>
                                </div>
                            ) : (
                                <div>
                                    <FormikProvider value={chooseForm(activeStep)}>
                                        <Typography
                                            className={classes.instructions}
                                            component="span"
                                        >
                                            {getStepContent(activeStep)}
                                        </Typography>
                                        <Box display="flex" justifyContent="center" mt={5}>
                                            {activeStep !== 0 && (
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    size="large"
                                                    onClick={handleBack}
                                                    className={classes.backButton}
                                                >
                                                    Back
                                                </Button>
                                            )}
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                onClick={chooseForm(activeStep).handleSubmit}
                                                type="submit"
                                            >
                                                {activeStep === steps.length - 1
                                                    ? 'Publish'
                                                    : 'Next'}
                                            </Button>
                                        </Box>
                                    </FormikProvider>
                                </div>
                            )}
                        </div>
                    </Box>
                </div>
            </Container>
            <QuantityValidation
                openQuantityDialog={openQuantityDialog}
                onCloseDialog={onCloseDialog}
                quantityNoRequiredValue={quantityNoRequiredValue}
                confirmQuantityValue={confirmQuantityValue}
            />
            <AssetPreview
                formValues={{
                    ...formikStep2.values,
                    ...formikStep3.values,
                    isSeries,
                    assetManager,
                }}
                file={file}
                previewDialog={previewDialog}
                onClosePreviewDialog={onClosePreviewDialog}
            />
            <Popover
                className={`${classes.popover} info-popover`}
                classes={{
                    paper: classes.paper,
                }}
                open={openPopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography>
                    You can write a custom tag and hit enter to save it. You can select only one
                    custom tag out of those that you have created
                </Typography>
            </Popover>
            <Popover
                className={`${classes.popover} info-popover`}
                classes={{
                    paper: classes.paper,
                }}
                open={openPopover2}
                anchorEl={anchorEl2}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography>
                    If you chose to add it to the marketplace, we will post it for sale via escrow
                </Typography>
            </Popover>
        </>
    );
};

export default UploadAsset;
