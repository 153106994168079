import React, { useState } from 'react';
import { Button, Box, Dialog, Avatar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getMetadataForOfferByEscrow } from 'redux/algorand/actions';
import { collectAssetOfferByEscrow } from 'redux/collectedAssets/actions';
import { primarySale } from 'transactions/smart-contract/escrow/offerByEscrow/primarySale';
import { secondarySale } from 'transactions/smart-contract/escrow/offerByEscrow/secondarySale';
import Lottie from 'lottie-react';
import loader from 'assets/img/lf30_editor_dwdfl6eb.json';
import { Link } from 'react-router-dom';
import AlgoFont from 'assets/img/algo-font.svg';

const Offer = ({ contract, setRealoadAsset, nodeGuid }) => {
    const dispatch = useDispatch();
    const { selectedWallet } = useSelector((state) => state.profile);
    const [loading, setLoading] = useState(false);

    const collectorMetadata = async (guid) => {
        const response = await dispatch(getMetadataForOfferByEscrow(guid));
        const IpfsHash = response?.data?.IpfsHash;
        return IpfsHash;
    };

    const sendCollectedAsset = (data) => {
        dispatch(collectAssetOfferByEscrow(nodeGuid, data)).then((response) => {
            if (response?.status === 201) {
                setLoading(false);
                setRealoadAsset((prev) => prev + 1);
            }
        });
    };

    const acceptContract = async (contract) => {
        try {
            if (!selectedWallet) {
                return alert('You need to connect your wallet');
            }
            setLoading(true);
            if (selectedWallet?.address === contract?.teal_context?.asset_creator) {
                await primarySale(contract, collectorMetadata, sendCollectedAsset, setLoading);
            } else {
                await secondarySale(
                    contract,
                    selectedWallet?.address,
                    collectorMetadata,
                    sendCollectedAsset,
                    setLoading,
                );
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    };

    return (
        <Box component="li">
            <Box className="image">
                <Avatar
                    alt={contract?.maker?.username}
                    src={!!contract?.maker?.avatar ? contract?.maker?.avatar : './'}
                    style={{ display: 'flex' }}
                />
            </Box>
            <Box className="info">
                <Box className="title">
                    <Box
                        fontWeight="bold"
                        fontFamily="h1.fontFamily"
                        className="link primary"
                        component={Link}
                        to="/"
                    >
                        @{contract?.maker?.username}
                    </Box>{' '}
                    made an offer for your artwork
                    <Box component="span" display="inline-block" ml={0.5}>
                        <Box fontSize={20} fontWeight="bold" className="price-algo">
                            <img src={AlgoFont} alt="Algo" />
                            <span>
                                {Number(contract?.teal_context?.offer_amount / 1000000).toFixed(3)}
                            </span>
                        </Box>
                    </Box>
                </Box>
                {loading ? (
                    <Lottie
                        animationData={loader}
                        style={{ height: '42px', width: '116px', marginBottom: '12px' }}
                    />
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        className="bid-btn"
                        onClick={() => acceptContract(contract)}
                    >
                        Accept
                    </Button>
                )}
            </Box>
        </Box>
    );
};

const MakeAnOfferModal = ({ openModal, onCloseModal, offersForMe, nodeGuid, setRealoadAsset }) => {
    return (
        <>
            <Dialog open={openModal} onClose={onCloseModal} scroll="body">
                <Box className="modal-body">
                    <Box style={{ padding: '20px' }}>
                        <Box component="ul" className="user-notifications-list">
                            {offersForMe.map((contract) => (
                                <Offer
                                    contract={contract}
                                    key={contract?.guid}
                                    nodeGuid={nodeGuid}
                                    setRealoadAsset={setRealoadAsset}
                                />
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </>
    );
};

export default MakeAnOfferModal;
