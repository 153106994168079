import { getAlgodIndexer } from './index';

export async function checkAssetAmount(hostAddress, assetIndex) {
    const indexerClient = getAlgodIndexer();

    // show address which have asset
    const assetInfo = await indexerClient.lookupAssetBalances(assetIndex).do();
    const haveAsset = assetInfo?.balances?.find(
        (account) => account.address === hostAddress && account.amount !== 0,
    );
    return haveAsset ? true : false;
}

export async function checkOptIn(address, assetIndex) {
    const indexerClient = getAlgodIndexer();

    // show address which have asset
    const assetInfo = await indexerClient.lookupAssetBalances(assetIndex).do();
    const optIn = assetInfo?.balances?.find((account) => account.address === address);
    return optIn ? true : false;
}

export async function checkAccountAmount(account, transactionFee, getAccountInfo) {
    const accountInfo = await getAccountInfo(account);
    if (!accountInfo?.account) {
        const message = 'This address does not exists or your account balance is 0';
        throw new Error(message);
    }

    const minBalance = accountInfo.account['min-balance'];
    const amount = accountInfo.account.amount;

    const balanceAfterTransaction = amount - transactionFee;

    return balanceAfterTransaction >= minBalance;
}
