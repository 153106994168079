import React from 'react';
import { Tabs } from 'components/shared';
import { IconButton } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { changeProfileTabesType } from '../../redux/profile/actions';
import { TABS } from 'configs';

const ProfileTabs = ({ username, type, tabNumber }) => {
    const dispatch = useDispatch();
    // const auctionBtn = (
    //     <Button variant="contained" color="secondary" size="large" component={Link} to="/auction">
    //         Auction
    //     </Button>
    // );

    const profileTabs = !username ? TABS.PROFILE_TABS_OWN : TABS.PROFILE_TABS;

    const backFromFollowers = () => {
        dispatch(changeProfileTabesType({ type: 'default', tabNumber: 0 }));
    };

    const backBtn = (
        <IconButton
            className="back-btn icon-btn md containedPrimary rect"
            disableRipple
            disableFocusRipple
            onClick={backFromFollowers}
        >
            <ChevronLeft style={{ fontSize: 26 }} />
        </IconButton>
    );

    return (
        <div className="profile-tabs">
            <Tabs
                tabs={type === 'follow' ? TABS.PROFILE_FOLLOWER_TABS : profileTabs}
                tabNumber={tabNumber}
                leftComponent={type === 'follow' ? backBtn : null}
            />
        </div>
    );
};

export default ProfileTabs;
