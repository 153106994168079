import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import MyAlgo from '@randlabs/myalgo-connect';
import { setMyAlgoAccount } from 'redux/profile/actions';
import { sendAssetIds } from 'redux/asset/actions';
import { needToLoginAction } from 'redux/auth/actions';
import { AppBar, Toolbar, Container, Box, Button, Avatar } from '@material-ui/core';
import { Logo, StartCollectingSearch, Notification } from 'components/shared';
import ProfileMenu from './ProfileMenu';
import {
    SignUp,
    VerifyYourEmail,
    Login,
    ForgotPassword,
    VerifyForgotPasswordEmail,
} from 'components/elements';
import algorandLogo from 'assets/img/algorand-logo.svg';
import MobileMenu from './mobileMenu';
import { AboutAnchorContext } from '../MainRoute';
import { MARKETPLACE } from '../../../configs/routes';
import algosdk from 'algosdk';
import ChangeWallet from 'components/elements/modal/changeWallet';

// import UserNotifications from '../user-notifications';

const Header = ({ hasSearch }) => {
    const history = useHistory();
    const path = history.location.pathname;

    /*User Notifications State*/
    // const [userNotifications, setNotifications] = useState(true);
    // const openUserNotifications = (e) => {
    //     setNotifications(!userNotifications);
    // };

    const [scrolled, setScrolled] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [signupDialog, setSignupDialog] = useState(false);
    const [verifyDialog, setVerifyDialog] = useState(false);
    const [loginDialog, setLoginDialog] = useState(false);
    // const [notificationMessage, setNotificationMessage] = useState(initialErrorMessage);
    const [forgotPasswordDialog, setForgotPasswordDialog] = useState(false);
    const [emailFromForgotPassword, setEmailFromForgotPassword] = useState(false);
    const [verifyForgotPasswordDialog, setVerifyForgotPasswordDialog] = useState(false);
    const [profileMenuEl, setProfileMenuEl] = useState(null);
    const { user, isLoggedIn, needToLogin } = useSelector((state) => state.auth);
    const { selectedWallet, notifications } = useSelector((state) => state.profile);
    const anchorRef = useContext(AboutAnchorContext);
    const myAlgoWallet = new MyAlgo();
    const dispatch = useDispatch();
    const [changeWallet, setChangeWallet] = useState(false);

    const onScroll = useCallback(() => {
        setScrolled(window.pageYOffset > 20);

        if (hasSearch) {
            const searchOffset = document
                .getElementById('banner-search')
                ?.getBoundingClientRect().top;
            setShowSearch(searchOffset < 30);
        }
    }, [hasSearch]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', onScroll);
        }
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [onScroll]);

    useEffect(() => {
        const inSingleAsset = path.split('/').includes('asset');
        if (inSingleAsset) {
            setShowSearch(true);
        }
    }, [path]);

    useEffect(() => {
        if (needToLogin) {
            setLoginDialog(true);
            dispatch(needToLoginAction(false));
        }
    }, [needToLogin, dispatch]);

    const onOpenChangeWallet = () => {
        setChangeWallet(true);
    };
    const onCloseChangeWallet = () => {
        setChangeWallet(false);
    };

    const onScrollToAbout = () =>
        window.scrollTo({ top: anchorRef.current.offsetTop, behavior: 'smooth' });

    const connectToMyAlgo = async () => {
        try {
            const accounts = await myAlgoWallet.connect();
            dispatch(setMyAlgoAccount(accounts)).then((response) => {
                if (response.length !== 0) {
                    getAssetsIds(response);
                }
            });
        } catch (err) {
            console.error(err);
        }
    };

    const openProfileMenu = (e) => {
        setProfileMenuEl(e.currentTarget);
    };

    const closeProfileMenu = () => {
        setProfileMenuEl(null);
    };

    const onOpenSignupDialog = () => {
        onCloseLoginDialog();
        setSignupDialog(true);
    };

    const onCloseSignupDialog = () => {
        setSignupDialog(false);
    };

    const onOpenVerifyDialog = () => {
        setVerifyDialog(true);
    };

    const onCloseVerifyDialog = () => {
        setVerifyDialog(false);
    };

    const onOpenLoginDialog = () => {
        onCloseSignupDialog();
        setLoginDialog(true);
    };

    const onCloseLoginDialog = () => {
        setLoginDialog(false);
    };

    const onOpenForgotPasswordDialog = () => {
        onCloseLoginDialog();
        setForgotPasswordDialog(true);
    };

    const onCloseForgotPasswordDialog = () => {
        setForgotPasswordDialog(false);
    };

    const onOpenVerifyForgotPasswordDialog = () => {
        setVerifyForgotPasswordDialog(true);
    };

    const onCloseVerifyForgotPasswordDialog = () => {
        setVerifyForgotPasswordDialog(false);
    };
    const onSubmitMarketplace = () => {
        history.push('/marketplace');
    };

    async function getAssetsIds(accounts) {
        try {
            const algodClient = getAlgodClient();

            const addressWithAssets = await Promise.all(
                accounts.map(async (account) => {
                    let accountInfo = await algodClient.accountInformation(account.address).do();
                    const createdAssets = accountInfo['created-assets'];
                    const assetsIds = createdAssets.map((asset) => asset.index);
                    return { address: account.address, assets: assetsIds };
                }),
            );

            const haveAssets = addressWithAssets.filter((account) => account.assets.length !== 0);
            if (haveAssets.length !== 0) {
                dispatch(sendAssetIds(haveAssets));
            }
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <>
            <Box className="notification-container">
                {notifications?.map((notification, i) => (
                    <Notification
                        key={notification?.guid}
                        type={notification?.status}
                        title={notification?.status}
                        message={notification?.message}
                        guid={notification?.guid}
                    />
                ))}
            </Box>
            <AppBar position="fixed" className={`header  ${scrolled ? 'shadow' : ''}`}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        {/*For window width > 980*/}
                        <Box display={{ xs: 'none', md: 'flex' }} alignItems="center" flexGrow="1">
                            {!showSearch ? (
                                <Link to="/" className="logo">
                                    <Logo type="logo" width="203" />
                                </Link>
                            ) : (
                                <Box display="flex" alignItems="center">
                                    <Link to="" className="logo">
                                        <Logo type="logoIcon" width="53" />
                                    </Link>
                                    <StartCollectingSearch />
                                </Box>
                            )}
                        </Box>
                        {/*For window width (600-980) */}
                        <Box
                            display={{ xs: 'none', sm: 'flex', md: 'none' }}
                            alignItems="center"
                            flexGrow="1"
                        >
                            <Link to="/" className="logo">
                                <Logo type="logo" width="160" />
                            </Link>
                        </Box>
                        {/*For window width < 600*/}
                        <Box display={{ xs: 'flex', sm: 'none' }} alignItems="center" flexGrow="1">
                            <MobileMenu
                                connectToMyAlgo={connectToMyAlgo}
                                onOpenChangeWallet={onOpenChangeWallet}
                            />
                        </Box>

                        <ul className="menu">
                            {path === '/' ? (
                                <li className="menu-link menu-link-about">
                                    <button onClick={onScrollToAbout}>About Us</button>
                                </li>
                            ) : (
                                ''
                            )}
                            <li
                                className={`menu-btn menu-btn-marketplace ${
                                    path === MARKETPLACE ? 'menu-btn-arrow' : ''
                                }`}
                            >
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="large"
                                    onClick={onSubmitMarketplace}
                                >
                                    Marketplace
                                </Button>
                            </li>
                            {isLoggedIn ? (
                                <>
                                    {!selectedWallet && (
                                        <li className="menu-btn menu-btn-wallet">
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                size="large"
                                                onClick={connectToMyAlgo}
                                            >
                                                Connect Wallet
                                            </Button>
                                        </li>
                                    )}
                                    <li className="menu-link" onClick={openProfileMenu}>
                                        <Avatar alt={user?.first_name} src={user?.avatar} />
                                    </li>
                                    <ProfileMenu
                                        anchorEl={profileMenuEl}
                                        closeMenu={closeProfileMenu}
                                    />
                                    {/* <li className="menu-notif">
                                        <button
                                            className="notif-btn hover-opacity unread"
                                            onClick={openUserNotifications}
                                        >
                                            <i
                                                className="icon-notifications-outline"
                                                style={{ fontSize: 24 }}
                                            />
                                        </button>
                                    </li> */}

                                    {selectedWallet && (
                                        <li
                                            className="menu-btn menu-btn-algo"
                                            onClick={onOpenChangeWallet}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <img src={algorandLogo} alt="Algorand" />
                                        </li>
                                    )}
                                </>
                            ) : (
                                <li className="menu-btn">
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        size="large"
                                        onClick={onOpenLoginDialog}
                                    >
                                        Sign In/Sign Up
                                    </Button>
                                </li>
                            )}
                        </ul>
                    </Toolbar>
                </Container>
            </AppBar>

            <SignUp
                signupDialog={signupDialog}
                onCloseSignupDialog={onCloseSignupDialog}
                onOpenLoginDialog={onOpenLoginDialog}
                onOpenVerifyDialog={onOpenVerifyDialog}
            />
            <VerifyYourEmail
                verifyDialog={verifyDialog}
                onCloseVerifyDialog={onCloseVerifyDialog}
            />
            {loginDialog && (
                <Login
                    loginDialog={loginDialog}
                    onCloseLoginDialog={onCloseLoginDialog}
                    onOpenSignupDialog={onOpenSignupDialog}
                    onOpenForgotPasswordDialog={onOpenForgotPasswordDialog}
                />
            )}
            <VerifyForgotPasswordEmail
                verifyForgotPasswordDialog={verifyForgotPasswordDialog}
                onCloseVerifyForgotPasswordDialog={onCloseVerifyForgotPasswordDialog}
                emailFromForgotPassword={emailFromForgotPassword}
            />
            <ForgotPassword
                forgotPasswordDialog={forgotPasswordDialog}
                onCloseForgotPasswordDialog={onCloseForgotPasswordDialog}
                onOpenLoginDialog={onOpenLoginDialog}
                onOpenVerifyForgotPasswordDialog={onOpenVerifyForgotPasswordDialog}
                setEmailFromForgotPassword={setEmailFromForgotPassword}
            />
            {selectedWallet && (
                <ChangeWallet
                    changeWallet={changeWallet}
                    onCloseChangeWallet={onCloseChangeWallet}
                />
            )}

            {/*User Notifications*/}
            {/* <UserNotifications notifications={userNotifications} /> */}
        </>
    );
};

export default Header;

function getAlgodClient() {
    const server = window.__RUNTIME_CONFIG__.REACT_APP_ALGORAND_SERVER;
    const token = { 'X-API-Key': window.__RUNTIME_CONFIG__.REACT_APP_ALGORAND_TOKEN_KEY };
    const port = '';

    const algodClient = new algosdk.Algodv2(token, server, port);
    return algodClient;
}
