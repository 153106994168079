import React from 'react';
import { Box, Dialog } from '@material-ui/core';
import algoLogo from '../../../assets/img/algorand-logo.svg';
import algoFont from '../../../assets/img/algo-font.svg';

const WalletInfoModal = ({ open, close, info }) => {
    return (
        <>
            <Dialog open={open} onClose={close} scroll="body" maxWidth="xs" className="mini-modal">
                <Box className="modal-body">
                    <Box display="flex" justifyContent="center" mb={3}>
                        <img src={algoLogo} alt="Algo Logo" />
                    </Box>

                    <Box className="wallet-modal">
                        <Box
                            className="wallet-modal-item"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Box className="wallet-modal-item-title">Wallet Address:</Box>
                            <Box className="wallet-modal-item-result wallet-address">
                                {info?.address}
                            </Box>
                        </Box>
                        <Box
                            className="wallet-modal-item"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Box className="wallet-modal-item-title">Balance:</Box>
                            <Box display="flex" className="wallet-modal-item-result">
                                {Number(info?.amount) / 1000000}
                                <img
                                    src={algoFont}
                                    style={{ width: '10px', marginLeft: '2px' }}
                                    alt="Algo Logo"
                                />
                            </Box>
                        </Box>
                        <Box
                            className="wallet-modal-item"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Box className="wallet-modal-item-title">Minimum Balance:</Box>
                            <Box display="flex" className="wallet-modal-item-result">
                                {info ? Number(info['min-balance']) / 1000000 : ''}
                                <img
                                    src={algoFont}
                                    style={{ width: '10px', marginLeft: '2px' }}
                                    alt="Algo Logo"
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </>
    );
};

export default WalletInfoModal;
