import * as Yup from 'yup';

export const editAssetValidation = Yup.object({
    price: Yup.number('Enter number').min(0, 'Enter greater than 0'),
    royalties: Yup.string()
        .test('number', 'The value must be number', (data) => {
            return Number(data?.replace(/%/, ' ')) || Number(data?.replace(/%/, ' ')) === 0;
        })
        .test('min', 'Enter greater than 0', (data) => Number(data?.replace(/%/, '') >= 0))
        .test('max', 'Enter less than 20', (data) => Number(data?.replace(/%/, '') <= 20))
        .test(
            'maxDigitsAfterDecimal',
            'number field must have 3 digits after decimal or less',
            (number) => Number.isInteger(Number(number?.replace(/%/, '')) * 10 ** 3),
        )
        .required('Royality is required'),
});
