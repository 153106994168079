import React from 'react';
import { selectTag } from '../../redux/profile-settings/actions';
import { useDispatch } from 'react-redux';

const ButtonTag = ({
    text,
    icon,
    selected,
    slug,
    setFilterByTag,
    onSelectTagFromUpload,
    category,
}) => {
    const dispatch = useDispatch();

    const selectTags = (event) => {
        if (setFilterByTag) {
            if (slug === 'all') return setFilterByTag([]);
            return setFilterByTag((prev) =>
                prev.includes(slug) ? prev.filter((x) => x !== slug) : [...prev, slug],
            );
        }
        const data = { selected: !selected };
        dispatch(selectTag(data, slug));
    };

    return (
        <label
            className={`button-tag ${selected ? 'selected' : ''} ${
                category === 'custom' ? 'new-label' : ''
            }`}
            onClick={(e) =>
                onSelectTagFromUpload ? onSelectTagFromUpload({ slug, category }) : selectTags(e)
            }
        >
            {icon && <span className="icon">{icon}</span>}
            {text && <span className="text">{text}</span>}
        </label>
    );
};

export default ButtonTag;
