import React from 'react';
import { Tag } from 'components/shared';

const ImgTag = ({ src, alt, tag, className }) => {
    return (
        <div className={`img-tag ${className}`}>
            <Tag text={tag} className="secondary sm top-left rotated" />
            <img src={src} alt={alt} />
        </div>
    );
};

export default ImgTag;
