import React, { useState } from 'react';
import { Container, Box, Typography, Button, Grid } from '@material-ui/core';
import BreadcrumbsNav from '../../components/shared/Breadcrumbs';
import AssetCard from '../../components/elements/cards/assetCard';
import TabSearch from '../../components/shared/TabSearch';
import ButtonTag from '../../components/shared/ButtonTag';
// import DropdownFilter from '../../components/shared/dropdown/dropdown-filter';
import DropdownSortAsset from '../../components/shared/dropdown/dropdown-sort-asset';
import LoadMoreBtn from '../../components/shared/LoadMoreBtn';
import DropdownAuctions from '../../components/shared/dropdown/dropdown-auctions';
import { Link } from 'react-router-dom';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import BidModal from '../../components/elements/modal/placeBid';
import BidDoneModal from '../../components/elements/modal/bidDone';
import AlgoFont from '../../assets/img/algo-font.svg';

/*Assets List*/
const ASSETS_LIST = [
    {
        id: 1,
        img: 'https://picsum.photos/680/800?random=1',
        tag: 'img',
        favorite: false,
        timeLeft: '2 hours left',
        title: 'AlgoZest ',
        authorAvatar: 'https://via.placeholder.com/150',
        author: '@comingsoon',
        currentBid: '1.02',
        numberOfBids: 120,
        votes: 180,
        voted: true,
        button: true,
        isSeries: true,
        isEditable: true,
    },
    {
        id: 2,
        img: 'https://picsum.photos/680/800?random=2',
        tag: 'img',
        favorite: true,
        timeLeft: '2 hours left',
        title: 'AlgoZest ',
        authorAvatar: 'https://via.placeholder.com/150',
        author: '@comingsoon',
        currentBid: '1.02',
        numberOfBids: 120,
        votes: 180,
        voted: true,
        button: false,
    },
    {
        id: 3,
        img: 'https://picsum.photos/680/800?random=3',
        tag: 'img',
        favorite: true,
        timeLeft: '2 hours left',
        title: 'AlgoZest ',
        authorAvatar: 'https://via.placeholder.com/150',
        author: '@comingsoon',
        currentBid: '1.02',
        numberOfBids: 120,
        votes: 180,
        voted: true,
        button: true,
    },
    {
        id: 4,
        img: 'https://picsum.photos/680/800?random=4',
        tag: 'img',
        favorite: true,
        timeLeft: '2 hours left',
        title: 'AlgoZest ',
        authorAvatar: 'https://via.placeholder.com/150',
        author: '@comingsoon',
        currentBid: '1.02',
        numberOfBids: 120,
        votes: 180,
        voted: true,
        button: true,
    },
    {
        id: 5,
        img: 'https://picsum.photos/680/800?random=5',
        tag: 'img',
        favorite: true,
        timeLeft: '2 hours left',
        title: 'AlgoZest ',
        authorAvatar: 'https://via.placeholder.com/150',
        author: '@comingsoon',
        currentBid: '1.02',
        myBid: '1.02',
        numberOfBids: 120,
        votes: 180,
        voted: true,
        button: true,
        isSeries: true,
    },
    {
        id: 6,
        img: 'https://picsum.photos/680/800?random=6',
        tag: 'img',
        favorite: true,
        timeLeft: '2 hours left',
        title: 'AlgoZest ',
        authorAvatar: 'https://via.placeholder.com/150',
        author: '@comingsoon',
        currentBid: '1.02',
        myBid: '1.02',
        numberOfBids: 120,
        votes: 180,
        voted: true,
        button: false,
    },
    {
        id: 7,
        img: 'https://picsum.photos/680/800?random=7',
        tag: 'img',
        favorite: true,
        timeLeft: '2 hours left',
        title: 'AlgoZest ',
        authorAvatar: 'https://via.placeholder.com/150',
        author: '@comingsoon',
        currentBid: '1.02',
        numberOfBids: 120,
        votes: 180,
        voted: true,
        button: true,
    },
    {
        id: 8,
        img: 'https://picsum.photos/680/800?random=8',
        tag: 'img',
        favorite: true,
        timeLeft: '2 hours left',
        title: 'AlgoZest ',
        authorAvatar: 'https://via.placeholder.com/150',
        author: '@comingsoon',
        currentBid: '1.02',
        numberOfBids: 120,
        votes: 180,
        voted: true,
        button: false,
    },
    {
        id: 9,
        img: 'https://picsum.photos/680/800?random=9',
        tag: 'img',
        favorite: true,
        timeLeft: '2 hours left',
        title: 'AlgoZest ',
        authorAvatar: 'https://via.placeholder.com/150',
        author: '@comingsoon',
        currentBid: '1.02',
        myBid: '1.02',
        numberOfBids: 120,
        votes: 180,
        voted: true,
        button: true,
    },
];
/*Tag Buttons*/
const TAG_BUTTONS = [
    {
        id: 1,
        text: 'All',
        slag: 'all',
    },
    {
        id: 2,
        text: 'Virtual Reality',
        slag: 'virtual_veality',
        icon: 'icon-virtual-reality',
    },
    {
        id: 3,
        text: 'Audio',
        slag: 'audio',
        icon: 'icon-audio',
    },
    {
        id: 4,
        text: 'Graphic',
        slag: 'graphic',
        icon: 'icon-graphic',
        selected: true,
    },
    {
        id: 5,
        text: 'Animation',
        slag: 'animation',
        icon: 'icon-animation',
        selected: true,
    },
    {
        id: 6,
        text: 'Video',
        slag: 'video',
        icon: 'icon-video',
    },
    {
        id: 67,
        text: 'Custom',
        slag: 'Custom',
        icon: 'icon-label',
        className: 'custom',
    },
];

const Auction = () => {
    /*Bid Modal & Done Modal*/
    const [bidModal, setBidModal] = React.useState(false);
    const [submittedBid, setSubmittedBid] = React.useState(false);

    const onOpenBidModal = () => {
        setBidModal(true);
    };
    const onCloseBidModal = () => {
        setBidModal(false);
    };
    const onOpenSubmittedBid = () => {
        setBidModal(false);
        setSubmittedBid(true);
    };
    const onCloseSubmittedBid = () => {
        setSubmittedBid(false);
    };

    /*My Auction Activity*/
    const [activity, setActivity] = useState(false);

    const openActivityMenu = (e) => {
        setActivity(!activity);
    };

    return (
        <>
            <Box className="auction">
                {/*My Auctions Activity*/}
                <Box className={`auctions-activity ${activity ? 'open' : ''}`}>
                    <Typography variant="h6" className="auctions-activity-title">
                        My Auctions Activity
                    </Typography>
                    {/*My Auctions Activity List (Examples) all cases*/}
                    <Box component="ul" className="auctions-activity-list">
                        <Box component="li">
                            <Box className="image">
                                <img src="https://via.placeholder.com/150" alt="" />
                            </Box>
                            <Box className="info">
                                <Box className="title">
                                    Auction has ended. Price
                                    <Box component="span" display="inline-block" ml={0.5}>
                                        <Box fontSize={20} fontWeight="bold" className="price-algo">
                                            <img src={AlgoFont} alt="Algo" />
                                            <span>120.99</span>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="time">50 min ago</Box>
                            </Box>
                        </Box>
                        <Box component="li">
                            <Box className="image">
                                <img src="https://via.placeholder.com/150" alt="" />
                            </Box>
                            <Box className="info">
                                <Box className="title">1 hour to finish the auction.</Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="bid-btn"
                                    onClick={onOpenBidModal}
                                >
                                    Place a Bid
                                </Button>
                                <Box className="time">2 haur ago</Box>
                            </Box>
                        </Box>
                        <Box component="li">
                            <Box className="image">
                                <img src="https://via.placeholder.com/150" alt="" />
                            </Box>
                            <Box className="info">
                                <Box className="title">
                                    <Box
                                        fontWeight="bold"
                                        fontFamily="h1.fontFamily"
                                        className="link primary"
                                        component={Link}
                                        to="/"
                                    >
                                        @Roxanne
                                    </Box>
                                    makes a new bid
                                    <Box component="span" display="inline-block" ml={0.5}>
                                        <Box fontSize={20} fontWeight="bold" className="price-algo">
                                            <img src={AlgoFont} alt="Algo" />
                                            <span>120.99</span>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="asset-image">
                                    <img src="https://via.placeholder.com/150" alt="" />
                                </Box>
                                <Box className="time">1 day ago</Box>
                            </Box>
                        </Box>
                        <Box component="li">
                            <Box className="image">
                                <img src="https://via.placeholder.com/150" alt="" />
                            </Box>
                            <Box className="info">
                                <Box className="title">
                                    Your creation was put to auction by
                                    <Box
                                        fontWeight="bold"
                                        fontFamily="h1.fontFamily"
                                        className="link primary"
                                        component={Link}
                                        to="/"
                                    >
                                        @Roxanne
                                    </Box>
                                </Box>
                                <Box className="time">2 days ago</Box>
                            </Box>
                        </Box>
                        <Box component="li">
                            <Box className="image">
                                <img src="https://via.placeholder.com/150" alt="" />
                            </Box>
                            <Box className="info">
                                <Box className="title">
                                    Auction has ended. Price
                                    <Box component="span" display="inline-block" ml={0.5}>
                                        <Box fontSize={20} fontWeight="bold" className="price-algo">
                                            <img src={AlgoFont} alt="Algo" />
                                            <span>120.99</span>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="time">2 haur ago</Box>
                            </Box>
                        </Box>
                        <Box component="li">
                            <Box className="image">
                                <img src="https://via.placeholder.com/150" alt="" />
                            </Box>
                            <Box className="info">
                                <Box className="title">
                                    Auction has ended. Price
                                    <Box component="span" display="inline-block" ml={0.5}>
                                        <Box fontSize={20} fontWeight="bold" className="price-algo">
                                            <img src={AlgoFont} alt="Algo" />
                                            <span>120.99</span>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="time">2 haur ago</Box>
                            </Box>
                        </Box>
                        <Box component="li">
                            <Box className="image">
                                <img src="https://via.placeholder.com/150" alt="" />
                            </Box>
                            <Box className="info">
                                <Box className="title">1 hour to finish the auction.</Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="bid-btn"
                                    onClick={onOpenBidModal}
                                >
                                    Place a Bid
                                </Button>
                                <Box className="time">2 haur ago</Box>
                            </Box>
                        </Box>
                        <Box component="li">
                            <Box className="image">
                                <img src="https://via.placeholder.com/150" alt="" />
                            </Box>
                            <Box className="info">
                                <Box className="title">
                                    Your creation was put to auction by
                                    <Box
                                        fontWeight="bold"
                                        fontFamily="h1.fontFamily"
                                        className="link primary"
                                        component={Link}
                                        to="/"
                                    >
                                        @Roxanne
                                    </Box>
                                </Box>
                                <Box className="time">2 days ago</Box>
                            </Box>
                        </Box>
                        <Box component="li">
                            <Box className="image">
                                <img src="https://via.placeholder.com/150" alt="" />
                            </Box>
                            <Box className="info">
                                <Box className="title">
                                    Auction has ended. Price
                                    <Box component="span" display="inline-block" ml={0.5}>
                                        <Box fontSize={20} fontWeight="bold" className="price-algo">
                                            <img src={AlgoFont} alt="Algo" />
                                            <span>120.99</span>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="time">2 haur ago</Box>
                            </Box>
                        </Box>
                    </Box>
                    <Button
                        className="auctions-activity-navigate"
                        variant="contained"
                        color="primary"
                        onClick={openActivityMenu}
                    >
                        {activity ? (
                            <ChevronLeftIcon style={{ color: '#fff' }} />
                        ) : (
                            <ChevronRightIcon style={{ color: '#fff' }} />
                        )}
                    </Button>
                </Box>

                <Container
                    maxWidth="xl"
                    className={`auction-container ${activity ? 'collapsed' : ''}`}
                >
                    <BreadcrumbsNav />

                    <Box
                        className="auction-title"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h2">Auction</Typography>
                        <Link
                            className="post-auction-btn"
                            variant="contained"
                            color="primary"
                            to="/post-auction"
                        >
                            Post an Auction
                        </Link>
                    </Box>

                    {/*Search with tab*/}
                    <TabSearch />

                    <Box
                        display={{ sm: 'block', md: 'flex' }}
                        justifyContent="space-between"
                        mb={2}
                        className="filter-list"
                    >
                        {/*Tags*/}
                        <Box className="button-tag-list">
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                flexWrap="wrap"
                            >
                                {TAG_BUTTONS.map((item) => (
                                    <ButtonTag
                                        key={item.id}
                                        text={item.text}
                                        selected={item.selected}
                                        slug={item.slag}
                                        className={`${item.selected ? 'selected' : ''} ${
                                            item.className
                                        }`}
                                        icon={item.icon && <i className={item.icon} />}
                                    />
                                ))}
                            </Box>
                        </Box>

                        {/*Filter DropdownDate*/}
                        <Box className="filter-sort">
                            {/*Dropdown with checkboxes Auctions*/}
                            <DropdownAuctions />
                            {/*Dropdown with checkboxes*/}
                            {/*<DropdownFilter />*/}
                            {/*Dropdown with Radio Buttons for Assets*/}
                            <DropdownSortAsset />
                        </Box>
                    </Box>

                    <Box className="auction-result">
                        <Grid container spacing={3} className="list-with-link">
                            {/*Assets*/}
                            {ASSETS_LIST.map((bid, i) => (
                                <Grid item md={4} sm={6} xs={12} className="card-item" key={i}>
                                    <AssetCard
                                        key={bid.id}
                                        tag={bid.tag}
                                        favorite={bid.favorite}
                                        isEditable={bid.isEditable}
                                        img={bid.img}
                                        timeLeft={bid.timeLeft}
                                        title={bid.title}
                                        author={bid.author}
                                        authorAvatar={bid.authorAvatar}
                                        currentBid={bid.currentBid}
                                        myBid={bid.myBid}
                                        numberOfBids={bid.numberOfBids}
                                        voted={bid.voted}
                                        votes={bid.votes}
                                        button={bid.button}
                                        isSeries={bid.isSeries}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    {/*Load More Button*/}
                    <LoadMoreBtn />
                </Container>
            </Box>
            <BidModal
                bidModal={bidModal}
                onCloseBidModal={onCloseBidModal}
                onOpenSubmittedBid={onOpenSubmittedBid}
            />
            <BidDoneModal submittedBid={submittedBid} onCloseSubmittedBid={onCloseSubmittedBid} />
        </>
    );
};

export default Auction;
