import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const DropdownPeople = ({ setSortPeopleByRole, sortPeopleByRole }) => {
    const [cardMenuEl, setCardMenuEl] = useState(null);

    const openCardMenu = (e) => {
        setCardMenuEl(e.currentTarget);
    };

    const closeCardMenu = () => {
        setCardMenuEl(null);
    };

    const title = () => {
        switch (sortPeopleByRole) {
            case '':
                return 'All People';
            case 'creator':
                return 'Creator';
            case 'collector':
                return 'Collector';
            default:
                return 'All People';
        }
    };

    return (
        <>
            <button className="dropdown-btn-people" onClick={openCardMenu}>
                {title()}
                <ExpandMoreIcon />
            </button>
            <Menu
                anchorEl={cardMenuEl}
                keepMounted
                open={Boolean(cardMenuEl)}
                onClose={closeCardMenu}
                className="people-dropdown"
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    className={`people-dropdown-item ${sortPeopleByRole === '' ? 'active' : ''}`}
                    onClick={() => setSortPeopleByRole('')}
                >
                    All People
                </MenuItem>
                <MenuItem
                    className={`people-dropdown-item ${
                        sortPeopleByRole === 'creator' ? 'active' : ''
                    }`}
                    onClick={() => setSortPeopleByRole('creator')}
                >
                    Creator
                </MenuItem>
                <MenuItem
                    className={`people-dropdown-item ${
                        sortPeopleByRole === 'collector' ? 'active' : ''
                    }`}
                    onClick={() => setSortPeopleByRole('collector')}
                >
                    Collector
                </MenuItem>
            </Menu>
        </>
    );
};

export default DropdownPeople;
