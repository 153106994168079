import axios from 'axios';
import * as TYPES from './types.js';
import AuthService from './services';

export const needToLoginAction = (payload) => ({ type: TYPES.NEED_TO_LOGIN, payload });

export const signup = (data) => (dispatch) => {
    return AuthService.signup(data).then(
        (response) => {
            dispatch({
                type: TYPES.SIGNUP_SUCCESS,
                payload: { signUpFormValues: data },
            });
            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: TYPES.SIGNUP_FAIL,
            });
            return Promise.reject(error);
        },
    );
};

export const verifySignup = (code) => (dispatch) =>
    AuthService.verifySignup(code).then(
        (response) => {
            dispatch({
                type: TYPES.VERIFY_SIGNUP_SUCCESS,
            });
            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: TYPES.VERIFY_SIGNUP_FAIL,
            });
            return Promise.reject();
        },
    );

export const login = (email, password) => (dispatch) => {
    return AuthService.login(email, password)
        .then(
            (response) => {
                const data = response?.data;
                return data;
            },
            (error) => {
                dispatch({
                    type: TYPES.LOGIN_FAIL,
                });
                return Promise.reject(error);
            },
        )
        .then(
            (data) => {
                if (data.access && data.refresh) {
                    localStorage.setItem('access', data.access);
                    localStorage.setItem('refresh', data.refresh);
                }
                dispatch({
                    type: TYPES.LOGIN_SUCCESS,
                    payload: data,
                });
                dispatch({
                    type: TYPES.LOGIN_REFRESH_SUCCESS,
                    payload: data,
                });
            },
            (error) => {
                dispatch({
                    type: TYPES.LOGIN_FAIL,
                });

                return Promise.reject(error);
            },
        );
};
export const loginRefresh = (refreshToken) => (dispatch) => {
    return AuthService.loginRefresh(refreshToken).then(
        (response) => {
            if (response) {
                if (response.data.access && response.data.refresh) {
                    localStorage.setItem('access', response.data.access);
                    localStorage.setItem('refresh', response.data.refresh);
                }
                dispatch({
                    type: TYPES.LOGIN_REFRESH_SUCCESS,
                    payload: response.data,
                });
                return Promise.resolve();
            }
        },

        (error) => {
            dispatch({
                type: TYPES.LOGIN_REFRESH_FAIL,
            });

            return Promise.reject();
        },
    );
};
export const forgotPassword = (email) => (dispatch) => {
    return AuthService.forgotPassword(email).then(
        (response) => {
            dispatch({
                type: TYPES.FORGOT_PASSWORD_SUCCESS,
            });

            Promise.resolve();
        },
        (error) => {
            dispatch({
                type: TYPES.FORGOT_PASSWORD_FAIL,
            });
            return Promise.reject();
        },
    );
};
export const verifyForgotPassword = (code) => (dispatch) =>
    AuthService.verifyForgotPassword(code).then(
        (response) => {
            if (response) {
                dispatch({
                    type: TYPES.VERIFY_FORGOT_PASSWORD_SUCCESS,
                    payload: {
                        code: code,
                    },
                });
                return Promise.resolve();
            }
        },
        (error) => {
            dispatch({
                type: TYPES.VERIFY_FORGOT_PASSWORD_FAIL,
            });
            return Promise.reject();
        },
    );

export const verifyEmail = (code) => (dispatch) =>
    AuthService.verifyEmail(code).then(
        (response) => {
            if (response) {
                dispatch({
                    type: TYPES.VERIFY_EMAIL_SUCCESS,
                    payload: {
                        code: code,
                    },
                });
                return Promise.resolve();
            }
        },
        (error) => {
            dispatch({
                type: TYPES.VERIFY_EMAIL_FAIL,
            });
            return Promise.reject();
        },
    );

export const verifiedForgotPassword = (code, password) => (dispatch) => {
    return AuthService.verifiedForgotPassword(code, password).then(
        (response) => {
            if (response) {
                dispatch({
                    type: TYPES.VERIFIED_FORGOT_PASSWORD_SUCCESS,
                });
                return Promise.resolve();
            }
        },
        (error) => {
            dispatch({
                type: TYPES.VERIFIED_FORGOT_PASSWORD_FAIL,
            });
            return Promise.reject();
        },
    );
};
export const logout = (refreshToken) => (dispatch) => {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    delete axios.defaults.headers.common['Authorization'];
    return AuthService.logout(refreshToken).then(
        (response) => {
            dispatch({
                type: TYPES.LOGOUT,
            });
            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: TYPES.LOGOUT,
            });
            return Promise.reject();
        },
    );
};
export const logoutFromLocalStorage = () => (dispatch) => {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    delete axios.defaults.headers.common['Authorization'];
    dispatch({
        type: TYPES.LOGOUT,
    });
};

export const getUser = (username) => (dispatch) => {
    return AuthService.getUser(username)
        .then((response) => {
            if (response && response.status === 200) {
                dispatch({
                    type: TYPES.GET_ME_SUCCESS,
                    payload: { user: response.data },
                });
                return response;
            }
        })
        .catch((error) => {
            console.log(error);
        });
};

export const changeUserAvatar = (data) => ({
    type: TYPES.CHANGE_USER_AVATAR_SUCCESS,
    payload: { data },
});
