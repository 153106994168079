import algosdk from 'algosdk';

import MyAlgo from '@randlabs/myalgo-connect';
import { assignGroupId, combineSignedTxs } from '../util/transactions';
import { getAlgodClient } from 'transactions/algorand';
import { waitForConfirmation } from 'transactions/algorand/waitConfirmation';

export const cancelOldContract = async (contract, assetId, account) => {
    try {
        console.log('Start canceling');
        if (!contract) {
            return { status: 'none' };
        }
        const myAlgoWallet = new MyAlgo();
        const algodClient = getAlgodClient();
        const approvalProgram = new Uint8Array(
            Buffer.from(contract.compiled_teal_result, 'base64'),
        );

        const params = await algodClient.getTransactionParams().do();
        params.fee = 1000;
        params.flatFee = true;

        const lSig = algosdk.makeLogicSig(approvalProgram);
        const LSigAddress = lSig.address();
        const info = await algodClient.accountInformation(LSigAddress).do();
        if (info?.amount === 0) {
            return { status: 'revoke' };
        }

        const asset = info?.assets?.find((asset) => asset['asset-id'] === assetId);

        if (asset) {
            const txnCloseOutAsset = {
                ...params,
                from: LSigAddress,
                to: account.address,
                assetIndex: assetId,
                type: 'axfer',
                amount: 0,
                note: new Uint8Array(Buffer.from('...')),
                closeRemainderTo: account.address,
            };
            const txnPayment = {
                ...params,
                from: account.address,
                to: account.address,
                assetIndex: assetId,
                type: 'pay',
                amount: 0,
                note: new Uint8Array(Buffer.from('...')),
            };

            const txnGroup = assignGroupId([txnCloseOutAsset, txnPayment]);

            const txObj = new algosdk.Transaction(txnGroup[0]);
            txObj.group = txnGroup[0].group;
            const signEscrowTx = algosdk.signLogicSigTransactionObject(txObj, lSig);

            const signedTxn = await myAlgoWallet.signTransaction(txnGroup[1]);
            const combinedTxs = combineSignedTxs([signEscrowTx, signedTxn]);

            const optEscrowtx = await algodClient.sendRawTransaction(combinedTxs).do();
            await waitForConfirmation(algodClient, optEscrowtx.txId);
        }

        const txnCloseOutTxn = {
            ...params,
            from: LSigAddress,
            to: account.address,
            type: 'pay',
            amount: 0,
            note: new Uint8Array(Buffer.from('...')),
            closeRemainderTo: account.address,
        };

        const txCloseOutTxnObj = new algosdk.Transaction(txnCloseOutTxn);
        const signEscrowCloseOutTx = algosdk.signLogicSigTransactionObject(txCloseOutTxnObj, lSig);
        const optEscrowCloseOuttx = await algodClient
            .sendRawTransaction(signEscrowCloseOutTx.blob)
            .do();
        await waitForConfirmation(algodClient, optEscrowCloseOuttx.txId);
        console.log('CloseOut');
        return { status: 'revoke' };
    } catch (err) {
        console.log(err);
        return { status: 'error', message: err.message };
    }
};
