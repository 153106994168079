import * as TYPES from './types.js';

const initialState = {
    createdAssets: [],
    loadMoreURL: null,
    createdAssetLoading: false,
    failMessage: {
        error: false,
        message: '',
    },
    visibilityLoading: false,
    loadMoreLoading: false,
};

const createdAssetsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TYPES.GET_CREATED_ASSETS_REQUEST:
            return {
                ...state,
                createdAssetLoading: true,
            };
        case TYPES.GET_CREATED_ASSETS_SUCCESS:
            return {
                ...state,
                createdAssets: payload.data,
                loadMoreURL: payload.next,
                createdAssetLoading: false,
            };
        case TYPES.GET_CREATED_ASSETS_FAIL:
            return {
                ...state,
                failMessage: {
                    error: true,
                    message: payload.message,
                },
                createdAssetLoading: false,
            };
        case TYPES.CLEAN_CREATED_ASSETS_SUCCESS:
            return {
                ...state,
                createdAssets: [],
                loadMoreURL: '',
            };
        // case TYPES.UPDATE_VISIBILITY_CREATED_ASSET_REQUEST:
        //     return {
        //         ...state,
        //         visibilityLoading: true,
        //     };
        case TYPES.UPDATE_VISIBILITY_CREATED_ASSET_SUCCESS:
            return {
                ...state,
                createdAssets: state.createdAssets.map((item) =>
                    item.guid === payload.assetGuid ? payload.updatedAsset : item,
                ),
            };
        case TYPES.UPDATE_VISIBILITY_CREATED_ASSET_FAIL:
            return {
                ...state,
                visibilityLoading: false,
            };
        case TYPES.EMPTY_CREATED_ASSETS:
            return {
                ...state,
                createdAssets: [],
                loadMoreURL: null,
            };
        case TYPES.LOAD_MORE_ASSETS_REQUEST:
            return {
                ...state,
                loadMoreLoading: true,
            };
        case TYPES.LOAD_MORE_ASSETS_SUCCESS:
            return {
                ...state,
                createdAssets: [...state.createdAssets, ...payload.data],
                loadMoreURL: payload.next,
                loadMoreLoading: false,
            };
        case TYPES.LOAD_MORE_ASSETS_FAIL:
            return {
                ...state,
                failMessage: {
                    error: true,
                    message: payload.message,
                },
                loadMoreLoading: false,
            };
        case TYPES.UPVOTING_CREATED_ASSET_SUCCESS:
            return {
                ...state,
                createdAssets: state.createdAssets.map((asset) =>
                    asset.guid === payload.guid
                        ? { ...asset, voted: true, vote_count: ++asset.vote_count }
                        : { ...asset },
                ),
                createdAssetLoading: false,
            };
        case TYPES.UNUPVOTING_CREATED_ASSET_SUCCESS:
            return {
                ...state,
                createdAssets: state.createdAssets.map((asset) =>
                    asset.guid === payload.guid
                        ? { ...asset, voted: false, vote_count: --asset.vote_count }
                        : { ...asset },
                ),
                createdAssetLoading: false,
            };
        case TYPES.TO_FAVORITES_CREATED_SUCCESS:
            return {
                ...state,
                createdAssets: state.createdAssets.map((asset) =>
                    asset.guid === payload.guid ? { ...asset, favorite: true } : { ...asset },
                ),
                createdAssetLoading: false,
            };
        case TYPES.REMOVE_FROM_FAVORITES_CREATED_SUCCESS:
            return {
                ...state,
                createdAssets: state.createdAssets.map((asset) =>
                    asset.guid === payload.guid ? { ...asset, favorite: false } : { ...asset },
                ),
                createdAssetLoading: false,
            };
        default:
            return state;
    }
};

export default createdAssetsReducer;
