const apiUrl = window.__RUNTIME_CONFIG__.REACT_APP_API_URL;

export const MARKETPLACE_CONFIG = {
    getUsers: `${apiUrl}/api/account/profiles/`,
    getAssets: `${apiUrl}/api/marketplace/items/`,
    updateAsset: `${apiUrl}/api/marketplace/nodes/`,
    getAssetsTags: `${apiUrl}/api/marketplace/media-types/`,
    addAssetsTags: `${apiUrl}/api/marketplace/media-types/`,
    getPeopleTags: `${apiUrl}/api/account/tags/?type=static `,
};

const assetFilter = {
    status: ['buy_now', 'auction'],
    type: ['single', 'series'],
    price: ['1-10', '10-50', '50-100', '50-100', '100-200', '200-'],
};

const popular = 'popular';
const recently = 'recently';
const mostFollowed = 'most_followed';

export const FILTER_CONFIG = {
    assetFilter,
    popular,
    recently,
    mostFollowed,
};
