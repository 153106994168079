import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Grid, Card, Button } from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';
import { Logo } from 'components/shared';
import AssetCard from 'components/elements/cards/assetCard';
import BecomeCreator from './dialogs/BecomeCreator';
import RequestSent from './dialogs/RequestSent';
import { useDispatch, useSelector } from 'react-redux';
import {
    emptyCreatedAssets,
    changeCreatedAssetVisibility,
    getCreatedAssets,
    loadMoreCreatedItems,
    upvoteCreatedAsset,
    unUpvoteCreatedAsset,
    toFavoritesCreatedAssets,
    removeFromFavoritesCreatedAssets,
    cleanCreatedAssets,
} from 'redux/createdAsset/actions';
import { CREATOR } from 'configs';
import Lottie from 'lottie-react';
import loader from 'assets/img/lf30_editor_dwdfl6eb.json';

const ProfileCreated = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { username } = useParams();

    const [creatorDialog, setCreatorDialog] = useState(false);
    const [requestSentDialog, setRequestSentDialog] = useState(false);

    const { user } = useSelector((state) => state.profile);
    const { user: authUser } = useSelector((state) => state.auth);

    const { createdAssets, loadMoreURL, loadMoreLoading } = useSelector(
        (state) => state.createdAssets,
    );
    useEffect(() => {
        if (username || authUser?.username) {
            dispatch(getCreatedAssets(username || authUser?.username));
        }
    }, [dispatch, username, authUser]);

    useEffect(() => {
        return () => {
            dispatch(cleanCreatedAssets());
        };
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(emptyCreatedAssets());
        };
    }, [dispatch]);

    const onOpenCreatorDialog = () => {
        setCreatorDialog(true);
    };
    const onCloseCreatorDialog = () => {
        setCreatorDialog(false);
    };
    const onOpenRequestSentDialog = () => {
        setCreatorDialog(false);
        setRequestSentDialog(true);
    };
    const onCloseRequestSentDialog = () => {
        setRequestSentDialog(false);
    };
    const onSubmitCreate = () => {
        history.push('/upload-asset');
    };
    const loadMore = () => {
        dispatch(loadMoreCreatedItems(loadMoreURL));
    };
    return (
        <div>
            <Grid
                container
                spacing={3}
                justifyContent={createdAssets.length === 0 ? 'center' : 'flex-start'}
            >
                {user.role && (
                    <Grid item lg={4} md={6} sm={6} xs={12} className="mb-2 white-card">
                        <Card className="h-100">
                            <Box
                                mb={1}
                                px={4}
                                py={4}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                textAlign="center"
                                height="100%"
                            >
                                <Box
                                    my="auto"
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                >
                                    <div className="logo-with-icon">
                                        <Logo type="logoIcon" width="167" />
                                        <span>
                                            <AddOutlined style={{ fontSize: 42 }} />
                                        </span>
                                    </div>
                                    <Box
                                        fontSize={20}
                                        fontWeight="bold"
                                        fontFamily="h1.fontFamily"
                                        mt={3}
                                    >
                                        Upload File
                                    </Box>
                                    <Box fontSize={16} color="text.black70" mt={1.5}>
                                        Begin creating your gallery
                                    </Box>
                                    {/*After Submission*/}
                                    {/*<Box fontSize={16} color="text.black70" mt={1.5}>
                                    Your request to become an artist is under review.
                                </Box>*/}
                                </Box>
                                <Box width="100%" mt={{ xs: 2, sm: 4 }}>
                                    {user.role === CREATOR || user.is_become_creator ? (
                                        <Button
                                            variant="contained"
                                            size="large"
                                            fullWidth
                                            onClick={onSubmitCreate}
                                            disabled={user.role !== CREATOR}
                                            color={user.role === CREATOR ? 'primary' : 'default'}
                                        >
                                            Create
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            fullWidth
                                            onClick={onOpenCreatorDialog}
                                        >
                                            Become a Creator
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                )}
                {createdAssets
                    ?.filter((x) => x.asset)
                    ?.map((item) => (
                        <Grid item lg={4} md={6} sm={6} xs={12} key={item?.guid} className="mb-2">
                            <AssetCard
                                tag={item?.media_types?.find((x) => x.category === 'static')?.name}
                                favorite={item?.favorite}
                                title={item?.title}
                                img={item?.asset?.content_url}
                                thumbnail={item?.asset?.thumbnail?.url}
                                content_type={item?.asset?.content_type}
                                timeLeft={item?.timeLeft}
                                price={item?.price}
                                numberOfBids={item?.numberOfBids}
                                voted={item?.voted}
                                votes={item?.vote_count}
                                guid={item?.guid}
                                upvoteAsset={upvoteCreatedAsset}
                                unUpvoteAsset={unUpvoteCreatedAsset}
                                toFavoritesAssets={toFavoritesCreatedAssets}
                                removeFromFavoritesAssets={removeFromFavoritesCreatedAssets}
                                isLoggedIn={true}
                                changeAssetVisibility={changeCreatedAssetVisibility}
                                nodes={item?.nodes}
                                bestOffer={item?.base_node}
                                creator={item?.creator}
                                assetId={item?.asset?.asset_id}
                            />
                        </Grid>
                    ))}
            </Grid>

            {loadMoreURL &&
                (loadMoreLoading ? (
                    <Lottie
                        mt={5}
                        animationData={loader}
                        style={{ height: '49px', width: '100%', marginTop: '40px' }}
                    />
                ) : (
                    <Box textAlign="center" mt={5}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            size="large"
                            onClick={loadMore}
                        >
                            Load More
                        </Button>
                    </Box>
                ))}
            <BecomeCreator
                creatorDialog={creatorDialog}
                onCloseCreatorDialog={onCloseCreatorDialog}
                onOpenRequestSentDialog={onOpenRequestSentDialog}
            />
            <RequestSent
                requestSentDialog={requestSentDialog}
                onCloseRequestSentDialog={onCloseRequestSentDialog}
            />
        </div>
    );
};

export default ProfileCreated;
