import React, { useState } from 'react';
import { FormControl, OutlinedInput, Box, InputAdornment, Button } from '@material-ui/core';
import { Search } from '@material-ui/icons';

const TabSearch = ({ viewType, setAssetsType, setSearchValue, searchFromHomePage }) => {
    const [value, setValue] = useState(searchFromHomePage || '');

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const search = () => {
        setSearchValue(value);
    };

    const onClickBtn = (key) => {
        setAssetsType((prev) => (prev === key ? null : key));
        if (value) {
            setValue('');
            setSearchValue('');
        }
    };

    return (
        <form className="tab-search mb-5">
            <FormControl variant="outlined">
                <OutlinedInput
                    placeholder="Search"
                    onChange={handleChange}
                    onKeyUp={search}
                    value={value}
                    startAdornment={
                        <InputAdornment position="start">
                            <Search color="disabled" />
                        </InputAdornment>
                    }
                />
            </FormControl>
            <Box component="span" m={1} className="tab-search-btns">
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => onClickBtn('assets')}
                    style={viewType === 'assets' ? { background: 'black', color: 'white' } : {}}
                >
                    Assets
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => onClickBtn('people')}
                    style={viewType === 'people' ? { background: 'black', color: 'white' } : {}}
                >
                    People
                </Button>
            </Box>
        </form>
    );
};

export default TabSearch;
