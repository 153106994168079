export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';

export const CHANGE_EMAIL_REQUEST = 'CHANGE_EMAIL_REQUEST';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_FAIL = 'CHANGE_EMAIL_FAIL';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const CHANGE_IMAGE_REQUEST = 'CHANGE_IMAGE_REQUEST';
export const CHANGE_IMAGE_SUCCESS = 'CHANGE_IMAGE_SUCCESS';
export const CHANGE_IMAGE_FAIL = 'CHANGE_IMAGE_FAIL';

export const ADD_CUSTOM_TAG_REQUEST = 'ADD_CUSTOM_TAG_REQUEST';
export const ADD_CUSTOM_TAG_SUCCESS = 'ADD_CUSTOM_TAG_SUCCESS';
export const ADD_CUSTOM_TAG_FAIL = 'ADD_CUSTOM_TAG_FAIL';

export const GET_TAGS_REQUEST = 'GET_TAGS_REQUEST';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_FAIL = 'GET_TAGS_FAIL';

export const SET_TAGS = 'SET_TAGS';

export const SELECT_TAG_REQUEST = 'SELECT_TAG_REQUEST';
export const SELECT_TAG_SUCCESS = 'SELECT_TAG_SUCCESS';
export const SELECT_TAG_FAIL = 'SELECT_TAG_FAIL';
