import { v4 as uuid } from 'uuid';
import MyAlgo from '@randlabs/myalgo-connect';
import algosdk from 'algosdk';
import { waitForConfirmation } from 'transactions/algorand/waitConfirmation';

export const createAsset = async (args) => {
    const {
        file,
        gettingIPFSHash,
        formValues,
        user,
        account,
        visibility,
        addAssetToDB,
        getErrorMessage,
        zestBloomManagerAddress,
        getAccountInfo,
    } = args;
    try {
        const server = window.__RUNTIME_CONFIG__.REACT_APP_ALGORAND_SERVER;
        const token = { 'X-API-Key': window.__RUNTIME_CONFIG__.REACT_APP_ALGORAND_TOKEN_KEY };
        const port = '';

        const algodClient = new algosdk.Algodv2(token, server, port);

        const accountPositiveAmount = await checkAccountAmount(
            account.address,
            algodClient,
            101000,
            getAccountInfo,
        );
        if (!accountPositiveAmount) {
            const message = 'Your account balance is not enough for this transaction.';
            throw new Error(message);
        }

        const myAlgoWallet = new MyAlgo();
        const data = new FormData();
        data.append('file', file);

        const metadata = JSON.stringify({
            name: formValues.title,
            description: formValues.description,
            properties: {
                creator: [{ name: user.username, description: user.bio, address: account.address }],
                royalty: Number(formValues.royalties.replace(/%/, '')),
                // contributor: [user.username], must be when we have collaborations
                keyWords: JSON.parse(formValues.tag),
                publisher: 'ZestBloom',
                itemListElement: 1,
                numberOfItems: 1,
            },
        });
        data.append('metadata', metadata);

        const hashes = await gettingIPFSHash(data);
        if (hashes.status === 400) {
            return getErrorMessage(hashes.message);
        }

        const { ipfsHashForFile, ipfsHashFormetaData } = hashes;

        const noteData = {
            metadataurl: `ipfs://${ipfsHashFormetaData}`,
            'specs-version': '0.1',
            'specs-data': [],
        };

        const noteDataJson = toJson(noteData);

        const guid = uuid();
        const enc = new TextEncoder();
        const note = enc.encode(noteDataJson);
        const defaultFrozen = false;
        const assetDecimals = 0;
        const assetTotal = parseInt(formValues.quantity);
        const assetUnitName = formValues.unitName;
        const assetName = formValues.title;
        const assetURL = `ipfs://${ipfsHashForFile}`;
        const assetMetadataHash = btoa(guid.replace(/-/g, ''));
        const assetManager = getAssetManager(
            account.address,
            zestBloomManagerAddress,
            formValues.assetManager,
        );
        const assetReserve = account.address;
        const assetFreeze = undefined;
        const assetClawback = account.address;

        const params = await algodClient.getTransactionParams().do();
        const txn = {
            ...params,
            fee: 1000,
            flatFee: true,
            type: 'acfg',
            from: account.address,
            assetDecimals,
            assetTotal,
            assetUnitName,
            assetName,
            assetFreeze,
            assetManager,
            assetReserve,
            assetClawback,
            assetDefaultFrozen: false,
            defaultFrozen,
            assetURL,
            assetMetadataHash,
            note,
        };
        const rawSignedTxn = await myAlgoWallet.signTransaction(txn);
        const tx = await algodClient.sendRawTransaction(rawSignedTxn.blob).do();
        // wait for transaction to be confirmed
        await waitForConfirmation(algodClient, tx.txId);
        // Get the new asset's information from the creator account
        const ptx = await algodClient.pendingTransactionInformation(tx.txId).do();
        // const deCode = new TextDecoder();
        // const noteFromTransaction = deCode.decode(ptx.txn.txn.note);
        const assetID = ptx['asset-index'];

        const assetsData = {
            asset_id: assetID,
            description: formValues.description,
            royalties: Number(formValues.royalties.replace(/%/, '')),
            quantity: formValues.quantity,
            media_types: JSON.parse(formValues.tag),
            price_is_visible: formValues.price_is_visible,
            price: formValues.price_is_visible ? formValues.price : 0,
            visibility,
            physical_entity: formValues.physical_asset,
        };

        const createEscroeBySaleContract = formValues.price_is_visible && formValues.price;
        const addAssetToDBArrg = {
            algodClient,
            account: account.address,
            assetID,
            assetsData,
            guid,
            addAssetToDB,
            createEscroeBySaleContract,
            getErrorMessage,
        };
        await printCreatedAsset(addAssetToDBArrg);
    } catch (err) {
        console.log(err);
        getErrorMessage(err?.message);
    }
};

function toJson(data) {
    return JSON.stringify(data);
}

async function printCreatedAsset(addAssetToDBArrg) {
    const {
        algodClient,
        account,
        assetID,
        assetsData,
        guid,
        addAssetToDB,
        createEscroeBySaleContract,
        getErrorMessage,
    } = addAssetToDBArrg;
    try {
        let accountInfo = await algodClient.accountInformation(account).do();
        const createdAsset = accountInfo['created-assets'].find((item) => item.index === assetID);
        if (createdAsset) {
            addAssetToDB(assetsData, guid, account, assetID, createEscroeBySaleContract);
        }
    } catch (err) {
        getErrorMessage(err?.message);
        console.log(err);
    }
}

async function checkAccountAmount(account, algodClient, transactionFee, getAccountInfo) {
    const accountInfo = await getAccountInfo(account);
    if (!accountInfo?.account) {
        const message = 'This address does not exist or your account balance is 0';
        throw new Error(message);
    }

    const minBalance = accountInfo.account['min-balance'];
    const amount = accountInfo.account.amount;

    const balanceAfterTransaction = amount - transactionFee;

    return balanceAfterTransaction >= minBalance;
}

function getAssetManager(creator, zestBloom, assetManager) {
    switch (assetManager) {
        case 'creator':
            return creator;
        case 'zestBloom':
            return zestBloom;
        case 'unmanaged':
            return undefined;
        default:
            return creator;
    }
}
