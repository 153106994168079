export const GET_FOLLOWERS_REQUEST = 'GET_FOLLOWERS_REQUEST';
export const GET_FOLLOWERS_SUCCESS = 'GET_FOLLOWERS_SUCCESS';
export const GET_FOLLOWERS_FAIL = 'GET_FOLLOWERS_FAIL';

export const LOAD_MORE_FOLLOWERS_REQUEST = 'LOAD_MORE_FOLLOWERS_REQUEST';
export const LOAD_MORE_FOLLOWERS_SUCCESS = 'LOAD_MORE_FOLLOWERS_SUCCESS';
export const LOAD_MORE_FOLLOWERS_FAIL = 'LOAD_MORE_FOLLOWERS_FAIL';

export const GET_FOLLOWING_USERS_REQUEST = 'GET_FOLLOWING_USERS_REQUEST';
export const GET_FOLLOWING_USERS_SUCCESS = 'GET_FOLLOWING_USERS_SUCCESS';
export const GET_FOLLOWING_USERS_FAIL = 'GET_FOLLOWING_USERS_FAIL';

export const LOAD_MORE_FOLLOWING_USERS_REQUEST = 'LOAD_MORE_FOLLOWING_USERS_REQUEST';
export const LOAD_MORE_FOLLOWING_USERS_SUCCESS = 'LOAD_MORE_FOLLOWING_USERS_SUCCESS';
export const LOAD_MORE_FOLLOWING_USERS_FAIL = 'LOAD_MORE_FOLLOWING_USERS_FAIL';
