import React from 'react';

// import { Tag } from 'components/shared';
const Auction = () => {
    return (
        <>
            {/*Time Tag*/}
            {/*  for Auction */}
            {/* <Tag text="05d   23h  43m  left" className="brand-gold md" /> */}
            {/*Bid-Price*/}
            {/* {currentAsset?.auction && (
        <>
            <Box component="p">Current bid</Box>
            <Box className="price-algo">
                <img src={AlgoFont} alt="Algo" />
                <Box component="span" className="bid-price">
                    {Number(currentAsset?.base_node?.price)?.toFixed(3)}
                </Box>
            </Box>
        </>
    )}
    
    {!currentAsset?.auction &&
        (currentAsset?.base_node?.price_is_visible &&
        currenContract?.length !== 0 ? (
            <Box className="price-algo">
                <img src={AlgoFont} alt="Algo" />
                <Box component="span" className="bid-price">
                    {Number(currentAsset?.base_node?.price)?.toFixed(3)}
                </Box>
            </Box>
        ) : (
            <Box component="p">No Price</Box>
        ))} */}
        </>
    );
};

export default Auction;
