export const CHANGE_BANNER_REQUEST = 'CHANGE_BANNER_REQUEST';
export const CHANGE_BANNER_SUCCESS = 'CHANGE_BANNER_SUCCESS';
export const CHANGE_BANNER_FAIL = 'CHANGE_BANNER_FAIL';

export const PUBLIC_USER_SUCCESS = 'PUBLIC_USER_SUCCESS';
export const PUBLIC_USER_FAIL = 'PUBLIC_USER_FAIL';

export const FOLLOW_USER = 'FOLLOW_USER';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';
export const GET_FOLLOWING = 'GET_FOLLOWING';

export const SET_MY_ALGO_ACCOUNT_REQUEST = 'SET_MY_ALGO_ACCOUNT_REQUEST';
export const SET_MY_ALGO_ACCOUNT_SUCCESS = 'SET_MY_ALGO_ACCOUNT_SUCCESS';
export const SET_MY_ALGO_ACCOUNT_FAIL = 'SET_MY_ALGO_ACCOUNT_FAIL';

export const DISSCONNECT_MY_ALGO = 'DISSCONNECT_MY_ALGO';

export const CHANGE_IS_BECOME_A_CREATOR_REQUEST = 'CHANGE_IS_BECOME_A_CREATOR_REQUEST';
export const CHANGE_IS_BECOME_A_CREATOR_SUCCESS = 'CHANGE_IS_BECOME_A_CREATOR_SUCCESS';
export const CHANGE_IS_BECOME_A_CREATOR_FAIL = 'CHANGE_IS_BECOME_A_CREATOR_FAIL';

export const EMPTY_USER = 'EMPTY_USER';

export const GET_PDF_FILE_REQUEST = 'GET_PDF_FILE_REQUEST';
export const GET_PDF_FILE_SUCCESS = 'GET_PDF_FILE_SUCCESS';
export const GET_PDF_FILE_FAIL = 'GET_PDF_FILE_FAIL';

export const CHOOSE_PROFILE_TABES_TYPE = 'CHOOSE_PROFILE_TABES_TYPE';

export const SELECTED_WALLET_SUCCESS = 'SELECTED_WALLET_SUCCESS';

export const SET_NOTIFICATION_SUCCESS = 'SET_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
