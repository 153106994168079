import axios from 'axios';
import { ASSETS_CONFIG } from 'configs';

const getCreatedAssets = (username) =>
    axios.get(`${ASSETS_CONFIG.getCreatedAsset}?creator=${username}`);
const loadMoreCreatedItems = (loadMoreURL) => axios.get(loadMoreURL);
const updateAsset = (guid, values) =>
    axios.patch(`${ASSETS_CONFIG.updateAsset}${guid}/visibility/`, values);
const upvoteAsset = (guid) => axios.post(`${ASSETS_CONFIG.upvoteAsset}${guid}/vote/`);
const unUpvoteAsset = (guid) => axios.delete(`${ASSETS_CONFIG.upvoteAsset}${guid}/vote/`);

const addToFavorites = (guid) => axios.post(`${ASSETS_CONFIG.favoriteAssets}${guid}/favorite/`);
const removeFromFavorites = (guid) =>
    axios.delete(`${ASSETS_CONFIG.favoriteAssets}${guid}/favorite/`);

const data = {
    getCreatedAssets,
    loadMoreCreatedItems,
    unUpvoteAsset,
    upvoteAsset,
    removeFromFavorites,
    addToFavorites,
    updateAsset,
};

export default data;
