import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ButtonTag from 'components/shared/ButtonTag';
import { getAssetsStaticTags, getPeopleTags } from 'redux/marketplace/actions';

const Tags = ({ filterByTag, setFilterByTag, viewType }) => {
    const { assetsStaticTags, peopleTags } = useSelector((state) => state.marketplace);
    const dispatch = useDispatch();

    useEffect(() => {
        if (viewType === 'people') {
            dispatch(getAssetsStaticTags());
        } else {
            dispatch(getPeopleTags());
        }
    }, [dispatch, viewType]);

    const getIcon = (item) =>
        viewType === 'people'
            ? item.icon && <i className={item.icon} />
            : item.slug && <i className={`icon-${item.slug}`} />;

    const staticTags = viewType === 'people' ? peopleTags : assetsStaticTags;
    return (
        <>
            {staticTags?.map((item) => (
                <ButtonTag
                    key={item.slug}
                    text={item.name}
                    selected={filterByTag.includes(item.slug)}
                    slug={item.slug}
                    icon={getIcon(item)}
                    setFilterByTag={setFilterByTag}
                    category={item.category}
                />
            ))}
            <ButtonTag
                key="custom"
                text="Custom"
                selected={filterByTag.includes('custom')}
                slug="custom"
                icon={<i className="icon-label" />}
                setFilterByTag={setFilterByTag}
                category="custom"
            />
        </>
    );
};

export default Tags;
