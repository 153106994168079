import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toFavoriteCurrentAsset, removeFromFavoritesCurrentAsset } from 'redux/singleAsset/actions';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { Star, StarBorder } from '@material-ui/icons';

const ToFavorite = ({ currentAsset, canFollowAndVoted }) => {
    const dispatch = useDispatch();
    const [disableFollowing, setDisableFollowing] = useState(false);

    const toFavorites = (guid) => {
        if (canFollowAndVoted && !disableFollowing) {
            setDisableFollowing(true);
            dispatch(toFavoriteCurrentAsset(guid)).then((res) => {
                setDisableFollowing(false);
            });
        }
    };

    const removeFromFavorites = (guid) => {
        if (canFollowAndVoted && !disableFollowing) {
            setDisableFollowing(true);
            dispatch(removeFromFavoritesCurrentAsset(guid)).then((res) => {
                setDisableFollowing(false);
            });
        }
    };

    const handleChangeFavorites = (guid) => {
        if (currentAsset?.favorite) {
            removeFromFavorites(guid);
        } else {
            toFavorites(guid);
        }
    };
    return (
        <FormControlLabel
            className="hover-opacity"
            control={
                <Checkbox
                    disableRipple
                    checked={currentAsset?.favorite}
                    onChange={() => handleChangeFavorites(currentAsset?.guid)}
                    className="fav-icon"
                    icon={<StarBorder style={{ fontSize: 24 }} />}
                    checkedIcon={<Star style={{ fontSize: 24 }} color="primary" />}
                />
            }
        />
    );
};
export default ToFavorite;
