import axios from 'axios';
import { ASSETS_CONFIG } from 'configs';

const addAssets = (data) => axios.post(ASSETS_CONFIG.addAsset, data);
const sendAssetIds = (data) => axios.post(ASSETS_CONFIG.sendAssetIds, data);

const data = {
    addAssets,
    sendAssetIds,
};

export default data;
