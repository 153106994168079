import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Container,
    FormControlLabel,
    Radio,
    RadioGroup,
    Switch,
    Typography,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, FormikProvider } from 'formik';
import Field from 'components/shared/fields/Field';
import { addAssetsTags } from 'redux/marketplace/actions';
import ButtonTag from 'components/shared/ButtonTag';
import loader from 'assets/img/lf30_editor_dwdfl6eb.json';
import Lottie from 'lottie-react';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import AssetImage from '../assetImage';
import { editAssetValidation } from 'services/yup-schemas/editAssetValidation';
import AlgoFont from '../../../assets/img/algo-font.svg';

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));
const AssetEdit = ({ update, loaderValue }) => {
    const dispatch = useDispatch();
    const { assetsTags } = useSelector((state) => state.marketplace);
    const { currentAsset } = useSelector((state) => state.singleAsset);
    const [tags, setTags] = useState(currentAsset?.media_types || []);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        setTags(currentAsset?.media_types || []);
    }, [currentAsset]);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);

    const nodeOfAsset = currentAsset?.nodes?.find((node) => node.owner.username === user.username);

    const formik = useFormik({
        initialValues: {
            royalties: currentAsset?.royalties
                ? `${currentAsset?.royalties} %`
                : `${(10).toFixed(2)} %`,
            price_is_visible: nodeOfAsset?.price_is_visible || false,
            price: (nodeOfAsset?.price || 0).toFixed(3),
            tags: currentAsset?.media_types
                ? JSON.stringify(currentAsset?.media_types.map((x) => x.slug))
                : '',
            visibility: nodeOfAsset?.visibility || 'private',
            custom_tag: '',
        },
        enableReinitialize: true,
        validationSchema: editAssetValidation,
        onSubmit: (values) => {
            const royaltiesToNumber = Number(values?.royalties?.replace(/%/, ''));
            const data = {
                price_is_visible: values.price_is_visible,
                price: Number(values.price),
                media_types: JSON.parse(values.tags),
                visibility: values.visibility,
                royalties: royaltiesToNumber,
                old_contract: nodeOfAsset ? nodeOfAsset?.contracts[0]?.guid : '',
            };
            return update(data);
        },
    });

    if (!nodeOfAsset) return null;

    const updateTagsInfo = (value) => {
        const data = JSON.stringify(value.map((x) => x.slug));
        formik.setFieldValue('tags', data);
        setTags(value);
    };

    const onSelectTag = (tag) => {
        const exist = tags.find((x) => x.category === tag.category);
        if (exist && exist.slug === tag.slug) {
            const value = tags.filter((x) => x.slug !== tag.slug);
            updateTagsInfo(value);
        } else if (exist) {
            const value = [...tags.filter((x) => x.category !== tag.category), tag];
            updateTagsInfo(value);
        } else {
            const value = [...tags, tag];
            updateTagsInfo(value);
        }
    };

    function addCustomTag(event) {
        if (
            (event.charCode || event.keyCode) === 13 &&
            event.target.name === 'custom_tag' &&
            event.target.value
        ) {
            dispatch(addAssetsTags(event.target.value));
            formik.values.custom_tag = '';
        }
    }

    const handleChange = (event) => {
        formik.setFieldValue('visibility', event.target.value);
    };

    const blurRoyalties = (e) => {
        const value = e.target.value.replace(/\s/g, '');
        if (!value.match(/%/) && value) {
            formik.setFieldValue('royalties', `${value} %`);
        }
    };

    const changeRoyalities = (e) => {
        const value = e.target.value.replace(/ |%/g, '');
        const floatPart = value.split('.')[1];
        const decimalsCount = floatPart?.length || 0;
        if (decimalsCount < 3) {
            formik.setFieldValue('royalties', e.target.value);
        }
    };

    const changePrice = (e) => {
        const value = e.target.value;
        const lessThreeDecimal = Number.isInteger(Number(value * 10 ** 3));
        if (lessThreeDecimal) {
            formik.setFieldValue('price', value);
        }
    };

    return (
        <>
            <Container maxWidth="xl" className="edit-asset">
                <Typography variant="h4" style={{ marginBottom: '1.75rem' }}>
                    {currentAsset?.title}
                </Typography>
                <Box display="flex" className="edit-asset-body">
                    <Box className="edit-asset-left">
                        <AssetImage currentAsset={currentAsset} />
                    </Box>
                    <Box className="edit-asset-right">
                        <form onSubmit={formik.handleSubmit}>
                            <FormikProvider value={formik}>
                                {nodeOfAsset?.user_type === 'creator' && (
                                    <Box mb={3}>
                                        <Typography variant="subtitle2">Media Type</Typography>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="center"
                                            flexWrap="wrap"
                                            mt={1}
                                        >
                                            {assetsTags?.map((item) => (
                                                <ButtonTag
                                                    key={item.slug}
                                                    text={item.name}
                                                    slug={item.slug}
                                                    icon={
                                                        item.slug && (
                                                            <i
                                                                className={`icon-${
                                                                    item.category === 'custom'
                                                                        ? 'label'
                                                                        : item.slug
                                                                }`}
                                                            />
                                                        )
                                                    }
                                                    selected={
                                                        !!tags.find((x) => x.slug === item.slug)
                                                    }
                                                    onSelectTagFromUpload={onSelectTag}
                                                    category={item.category}
                                                />
                                            ))}{' '}
                                            <Box className="custom-label">
                                                <Box className="new-label">
                                                    <i
                                                        className="icon-label"
                                                        style={{ fontSize: 24 }}
                                                    />
                                                    <Field
                                                        field="input"
                                                        type="text"
                                                        className="no-margin"
                                                        placeholder="Add Custom"
                                                        name="custom_tag"
                                                        onKeyDown={addCustomTag}
                                                        {...formik.getFieldProps('custom_tag')}
                                                    />
                                                </Box>
                                                <i
                                                    className="popover-icon icon-information-outline"
                                                    aria-owns={
                                                        openPopover
                                                            ? 'mouse-over-popover'
                                                            : undefined
                                                    }
                                                    aria-haspopup="true"
                                                    onMouseEnter={handlePopoverOpen}
                                                    onMouseLeave={handlePopoverClose}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                                {nodeOfAsset?.user_type === 'creator' && (
                                    <Box display="flex" mb={4} className="half-flex">
                                        <Box>
                                            <Field
                                                field="input"
                                                type="text"
                                                label="Royalties"
                                                name="royalties"
                                                {...formik.getFieldProps('royalties')}
                                                onBlur={blurRoyalties}
                                                onChange={changeRoyalities}
                                            />{' '}
                                        </Box>
                                        {/* <Box>
                                <Typography variant="subtitle2">Quantity</Typography>
                                <TextField fullWidth variant="outlined" />
                            </Box> */}
                                    </Box>
                                )}
                                <Box mb={1}>
                                    <Typography
                                        variant="body2"
                                        component="strong"
                                        style={{ marginRight: 48 }}
                                    >
                                        List on Marketplace
                                    </Typography>
                                    <Switch
                                        checked={formik.values.price_is_visible}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                        {...formik.getFieldProps('price_is_visible')}
                                        color="primary"
                                    />
                                </Box>
                                <Box mb={3} className="edit-price">
                                    {/* <Typography variant="subtitle2">Price</Typography> */}
                                    <FormControl fullWidth variant="outlined">
                                        {/* <OutlinedInput
                                    type="number"
                                    name="price"
                                    disabled={!formik.values.price_is_visible}
                                    {...formik.getFieldProps('price')}
                                    endAdornment={
                                        <InputAdornment position="end">ALGO</InputAdornment>
                                    }
                                /> */}
                                        <Box className="price-field">
                                            <Field
                                                field="input"
                                                type="number"
                                                label="Price"
                                                name="price"
                                                disabled={!formik.values.price_is_visible}
                                                {...formik.getFieldProps('price')}
                                                onChange={changePrice}
                                            />
                                            <Box className="price-field-algo">
                                                <img src={AlgoFont} alt="Algo" />
                                            </Box>
                                        </Box>
                                    </FormControl>
                                </Box>
                                <Box mb={3} className="edit-visibility">
                                    <Typography variant="subtitle2">Visibility</Typography>
                                    <Box display="flex" mb={4}>
                                        <RadioGroup
                                            aria-label="state"
                                            name="state"
                                            value={formik.values.visibility}
                                            className="radio-group"
                                            onChange={handleChange}
                                            style={{ flexDirection: 'row', alignItems: 'center' }}
                                        >
                                            <FormControlLabel
                                                value="private"
                                                control={<Radio color="primary" />}
                                                label="Private"
                                                style={{ marginRight: 24 }}
                                            />
                                            <FormControlLabel
                                                value="public"
                                                control={<Radio color="primary" />}
                                                label="Public"
                                            />
                                        </RadioGroup>
                                    </Box>
                                    {/* <Box display="flex" alignItems="center">
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox color="primary" />}
                                    label="Physical asset"
                                    labelPlacement="end"
                                    disabled
                                />
                            </Box> */}
                                </Box>
                                <Box
                                    className="edit-buttons"
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    {/* <Button variant="outlined" className="cancel-btn">
                                Cancel
                            </Button> */}
                                    {loaderValue ? (
                                        <div>
                                            <Lottie
                                                animationData={loader}
                                                style={{ height: '50px', width: '153px' }}
                                            />
                                        </div>
                                    ) : (
                                        <Button type="submit" variant="contained" color="primary">
                                            Save
                                        </Button>
                                    )}
                                </Box>
                            </FormikProvider>
                        </form>
                    </Box>
                </Box>
            </Container>
            <Popover
                className={`${classes.popover} info-popover`}
                classes={{
                    paper: classes.paper,
                }}
                open={openPopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography>
                    You can write a custom tag and hit enter to save it. You can select only one
                    custom tag out of those that you have created
                </Typography>
            </Popover>
        </>
    );
};

export default AssetEdit;
