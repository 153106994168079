import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Card, Typography, Avatar, Button, List, ListItem, Divider } from '@material-ui/core';
// import { ChevronRight } from '@material-ui/icons';
import { SettingsOutlined, WarningRounded } from '@material-ui/icons';
import { Tag } from 'components/shared';
import { useDispatch, useSelector } from 'react-redux';
import {
    followUser,
    unFollowUser,
    getProfileUser,
    getFollowing,
    empty_user,
    changeProfileTabesType,
} from '../../redux/profile/actions';
import SocialIcons from './SocialIcons';
import { changeImage } from 'redux/profile-settings/actions';
import { changeUserAvatar } from 'redux/auth/actions';

const Sidebar = ({ username, user }) => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [staticTag, setStaticTag] = useState(null);
    const [customTag, setCustomTag] = useState(null);
    const [avatar, setAvatar] = useState(null);

    const following = useSelector(({ profile }) => profile.following);
    let { user: authUser } = useSelector((state) => state.auth);
    const isAuthUser = authUser?.username === username;
    useEffect(() => {
        const stTag = user?.selected_tags?.find((tag) => tag.type === 'static');
        setStaticTag(stTag);
        const custTag = user?.selected_tags?.find((tag) => tag.type === 'custom');
        setCustomTag(custTag);
    }, [user]);

    const getUserInfo = () => {
        dispatch(getFollowing());
        dispatch(getProfileUser(username ?? 'me'));
        return () => {
            dispatch(empty_user());
            dispatch(changeProfileTabesType({ type: 'default', tabNumber: 0 }));
            setAvatar(null);
        };
    };

    useEffect(getUserInfo, [dispatch, username]);

    const isFollowed = useMemo(() => {
        if (following && username) {
            return following.results.find((follow) => follow.username === username);
        }
        return false;
    }, [following, username]);

    const showFollowers = () => {
        if (!username) {
            dispatch(changeProfileTabesType({ type: 'follow', tabNumber: 0 }));
        }
    };
    const showFollowing = () => {
        if (!username) {
            dispatch(changeProfileTabesType({ type: 'follow', tabNumber: 1 }));
        }
    };

    const handleFollow = () => {
        setIsLoading(true);
        if (isFollowed) {
            dispatch(unFollowUser(username)).then(() => setIsLoading(false));
        } else {
            dispatch(followUser(username)).then(() => setIsLoading(false));
        }
    };

    const openInputFile = () => {
        if (!username) {
            document.getElementById('selectImage').click();
        }
    };

    const handlePictureSelected = (event) => {
        const params = {
            avatar: event.target.files[0],
            username: user?.username,
        };
        dispatch(changeImage(params))
            .then((response) => {
                setAvatar(response.avatar);
                dispatch(changeUserAvatar(response.avatar));
            })
            .catch(() => {});
    };
    return (
        <div className="profile-sidebar">
            <Card variant="outlined">
                <Box
                    pt={4.5}
                    pb={3.5}
                    px={4}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    <Box
                        mb={3}
                        onClick={openInputFile}
                        className={!username ? 'avatar-container' : ''}
                    >
                        <Avatar
                            alt={user?.first_name}
                            src={avatar ?? user?.avatar}
                            className="xxl"
                        />
                        {!username && (
                            <Box
                                fontSize={20}
                                color="text.white"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                height="100%"
                                className="avatar-upload-icon"
                            >
                                <i className="icon-file-upload" style={{ fontSize: 48 }} />
                            </Box>
                        )}
                    </Box>
                    {!username && (
                        <Box mt={2}>
                            <input
                                id="selectImage"
                                hidden
                                type="file"
                                onChange={handlePictureSelected.bind(this)}
                            />
                        </Box>
                    )}

                    <Box className="profile-sidebar-tags">
                        {staticTag && (
                            <Tag text={staticTag.name} className="secondary sm relative" />
                        )}
                        {customTag && (
                            <Tag text={customTag.name} className="secondary sm relative" />
                        )}
                    </Box>

                    <Box mt={2.5} mb={1}>
                        <Typography variant="h4">
                            {user?.first_name} {user?.last_name}
                        </Typography>
                    </Box>

                    <Box
                        fontSize={16}
                        lineHeight="20px"
                        fontWeight="bold"
                        fontFamily="h1.fontFamily"
                        className="link primary ellipsis"
                        component={Link}
                        to="/"
                    >
                        @{user?.username}
                    </Box>

                    {user?.bio ? (
                        <Box mt={2.5} mb={3.5} textAlign="center">
                            {user?.bio}
                        </Box>
                    ) : null}
                    <SocialIcons user={user} />
                    {/* {!username && !isAuthUser && (
                        <Button
                            variant="outlined"
                            color="secondary" // primary when active
                            fullWidth
                            className="justify-between"
                            component={Link}
                            to="/"
                            endIcon={<ChevronRight style={{ fontSize: 32, margin: '2px 0' }} />}
                        >
                            Activity
                        </Button>
                    )} */}
                </Box>
                <List className="sidebar-menu" disablePadding>
                    <ListItem>
                        <button onClick={showFollowers}>
                            <p>Followers:</p>
                            <span>{user?.followers_count}</span>
                        </button>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <button onClick={showFollowing}>
                            <p>Following:</p>
                            <span>{user?.following_count}</span>
                        </button>
                    </ListItem>
                    <Divider />
                    {username && !isAuthUser ? (
                        <Box mx={3.5} mt={5} mb={8}>
                            <Button
                                variant={isFollowed ? 'outlined' : 'contained'}
                                color={isFollowed ? 'secondary' : 'primary'}
                                size="large"
                                fullWidth
                                onClick={handleFollow}
                                disabled={isLoading}
                            >
                                {isFollowed ? 'Unfollow' : 'Follow'}
                            </Button>
                        </Box>
                    ) : (
                        <>
                            <ListItem>
                                <Link to="/profile/settings" className="settings-link">
                                    <p>
                                        <SettingsOutlined style={{ fontSize: 24 }} />
                                        <span>Settings</span>
                                    </p>
                                    {/* warning btn  */}
                                    {authUser.settings_warning && (
                                        <Box color="error.light" className="settings-warning">
                                            <WarningRounded style={{ fontSize: 24 }} />
                                        </Box>
                                    )}
                                </Link>
                            </ListItem>
                            <Divider />
                        </>
                    )}
                </List>

                {!username ? isAuthUser && <SocialIcons user={user} /> : null}
            </Card>
        </div>
    );
};

export default Sidebar;
