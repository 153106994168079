import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import Lottie from 'lottie-react';
import loader from 'assets/img/lf30_editor_dwdfl6eb.json';
import { checkEscrowAccount } from 'transactions/smart-contract/escrow/saleByEscrow/checkEscrowAccount';
import { cancelOldContract } from 'transactions/smart-contract/escrow/saleByEscrow/cancelOldContract';
import { useDispatch } from 'react-redux';
import { deleteEscrowContract } from 'redux/algorand/actions';
import { deleteContract } from 'redux/singleAsset/actions';
import { algorandBaseUrl } from 'transactions/algorand/index';
import DraftedContractsModal from 'components/elements/modal/draftedContracts';

const SaleByEcrowBtns = ({ currentAsset, giveNotification, selectedWallet }) => {
    const [loadingOpenEscrow, setLoadingOpenEscrow] = useState(false);
    const [loadingEndListing, setLoadingEndListing] = useState(false);
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);

    const onSubmit = () => {
        setOpenModal(true);
    };

    const onCloseModal = () => {
        setOpenModal(false);
    };
    const activeContract = currentAsset?.base_node?.contracts?.find(
        (contract) => contract.status === 'ACTIVE',
    );
    const draftedContract = currentAsset?.base_node?.contracts?.filter(
        (contract) => contract.status === 'DRAFT',
    );

    const openEscrow = async () => {
        try {
            setLoadingOpenEscrow(true);
            const teal = currentAsset?.base_node?.contracts[0];

            if (!teal) {
                setLoadingOpenEscrow(false);
                return giveNotification('info', 'This asset does not have contract');
            }

            await checkEscrowAccount(teal, currentAsset?.asset?.asset_id);
            const a = document.createElement('a');
            a.href = `${algorandBaseUrl}/address/${teal?.compiled_teal_address}`;
            a.target = '_blank';
            a.click();
            setLoadingOpenEscrow(false);
        } catch (err) {
            setLoadingOpenEscrow(false);
            giveNotification('info', err.message);
        }
    };

    const showResponseOfCanceling = (status, message) => {
        switch (status) {
            case 'none':
                return giveNotification('info', 'This asset does not have contract');
            case 'bought':
                return giveNotification(
                    'info',
                    'In this asset you do not have contract or it has already been bought ',
                );
            case 'revoke':
                return giveNotification('success', 'You have successfully terminated the contract');
            case 'error':
                return giveNotification('error', message);
            default:
                return giveNotification('error', 'Something went wrong');
        }
    };

    const endListing = async () => {
        try {
            if (!selectedWallet) {
                return giveNotification('info', 'You need to connect your wallet');
            }
            setLoadingEndListing(true);

            const teal = currentAsset?.base_node?.contracts[0];

            if (!teal) {
                setLoadingEndListing(false);
                return giveNotification('info', 'You do not have contract in this asset');
            }

            const holder = teal?.teal_context?.new_seller || teal?.teal_context?.asset_creator;

            if (selectedWallet.address !== holder) {
                setLoadingEndListing(false);
                return giveNotification(
                    'error',
                    'Your wallet address does not match the address of the asset creator, you cant edit asset',
                );
            }

            const cancelResult = await cancelOldContract(
                teal,
                currentAsset?.asset?.asset_id,
                selectedWallet,
            );

            // send end contract to backend, and hide this buttons

            dispatch(deleteEscrowContract(currentAsset?.base_node?.contracts[0]?.guid))
                .then((response) => {
                    if (response?.status === 204) {
                        setLoadingEndListing(false);
                        showResponseOfCanceling(cancelResult?.status);
                        dispatch(deleteContract());
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setLoadingEndListing(false);
                });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            {activeContract && (
                <Box display="flex" className="escrow-buttons">
                    {loadingOpenEscrow ? (
                        <Lottie animationData={loader} style={{ height: '50px', width: '100%' }} />
                    ) : (
                        <Button variant="outlined" onClick={openEscrow} fullWidth>
                            View Escrow
                        </Button>
                    )}
                    {loadingEndListing ? (
                        <Lottie animationData={loader} style={{ height: '50px', width: '100%' }} />
                    ) : (
                        <Button variant="outlined" onClick={endListing} fullWidth>
                            End Listing
                        </Button>
                    )}
                </Box>
            )}
            {draftedContract?.length !== 0 && (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={onSubmit}
                        style={{ width: '100%', marginTop: '48px' }}
                    >
                        Drafted contracts
                    </Button>
                    <DraftedContractsModal
                        draftedContracts={draftedContract}
                        openModal={openModal}
                        onCloseModal={onCloseModal}
                        type={'sale'}
                    />
                </>
            )}
        </>
    );
};
export default SaleByEcrowBtns;
