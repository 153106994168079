export const GET_TEAL_FOR_CONTRACT_REQUEST = 'GET_TEAL_FOR_CONTRACT_REQUEST';
export const GET_TEAL_FOR_CONTRACT_SUCCESS = 'GET_TEAL_FOR_CONTRACT_SUCCESS';
export const GET_TEAL_FOR_CONTRACT_FAIL = 'GET_TEAL_FOR_CONTRACT_FAIL';

export const GET_TEAL_FOR_SALE_CONTRACT_REQUEST = 'GET_TEAL_FOR_SALE_CONTRACT_REQUEST';
export const GET_TEAL_FOR_SALE_CONTRACT_SUCCESS = 'GET_TEAL_FOR_SALE_CONTRACT_SUCCESS';
export const GET_TEAL_FOR_SALE_CONTRACT_FAIL = 'GET_TEAL_FOR_SALE_CONTRACT_FAIL';

export const GET_MANAGER_ADDRESS_REQUEST = 'GET_MANAGER_ADDRESS_REQUEST';
export const GET_MANAGER_ADDRESS_SUCCESS = 'GET_MANAGER_ADDRESS_SUCCESS';
export const GET_MANAGER_ADDRESS_FAIL = 'GET_MANAGER_ADDRESS_FAIL';
