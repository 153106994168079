import React, { useState } from 'react';

import { Box, CardMedia } from '@material-ui/core';
import { Height } from '@material-ui/icons';

import { Tag } from 'components/shared';
import SlickSlider from '../upload-asset/Slider';
import OpenPicturePopover from '../../components/elements/cards/fullScreen';

const AssetImage = ({ staticTag, currentAsset }) => {
    const [fullScreenPhoto, setFullScreenPhoto] = useState({ isOpen: false, src: '' });
    const pictureData = getPictureData(currentAsset?.asset);

    return (
        <>
            <Box className="image">
                {/*Only one image case*/}
                {staticTag && (
                    <Tag text={staticTag.name} className="secondary sm top-left rotated" />
                )}
                <Box
                    border={1}
                    borderColor="text.black20"
                    borderRadius={10}
                    bgcolor="text.white"
                    overflow="hidden"
                    height={48}
                    width={48}
                    style={{
                        backgroundColor: 'rgba(255,255,255,.5)',
                        position: 'absolute', // temprorary need to delete after create make an offer page
                        right: '15px',
                        top: '15px',
                    }}
                    className="card-image-open"
                    onClick={() =>
                        setFullScreenPhoto({
                            isOpen: true,
                            src: currentAsset?.asset?.content_url,
                        })
                    }
                >
                    <button>
                        <Height style={{ fontSize: 30 }} />
                    </button>
                </Box>
                {currentAsset?.series ? (
                    <SlickSlider />
                ) : (
                    pictureData && (
                        <CardMedia
                            component={pictureData?.component}
                            src={pictureData?.src}
                            muted={pictureData?.muted}
                            controls
                            style={pictureData?.component === 'audio' ? { height: '135px' } : {}}
                        />
                    )
                )}
            </Box>
            {fullScreenPhoto.isOpen && (
                <OpenPicturePopover
                    fullScreenPhoto={fullScreenPhoto}
                    onClose={() => {
                        setFullScreenPhoto({ isOpen: false, src: '' });
                    }}
                    content_type={currentAsset?.asset?.content_type}
                />
            )}
        </>
    );
};

export default AssetImage;

function getPictureData(data) {
    if (!data) return null;
    const type = data?.content_type?.split('/')[0];
    switch (type) {
        case 'image':
            return { component: 'img', src: data.content_url };
        case 'audio':
            return { component: 'audio', src: data.content_url };
        case 'video':
            return { component: 'video', src: data.content_url, autoplay: 'autoplay', muted: true };
        case 'text':
        case 'application':
            return { component: 'iframe', src: data.content_url };
        default:
            return { component: 'img', src: data.content_url };
    }
}
