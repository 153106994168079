import React from 'react';

import { Grid } from '@material-ui/core';
import PeopleCard from 'components/elements/cards/peopleCard';

const People = ({ users }) => {
    return users?.map((artist) => (
        <Grid item lg={4} md={4} sm={6} xs={12} key={artist?.username}>
            <PeopleCard
                key={artist.username}
                tags={artist.selected_tags}
                img={artist.banner}
                author={artist.username}
                firstName={artist.first_name}
                lastName={artist.last_name}
                authorAvatar={artist.avatar}
                authorName={artist.authorName}
                followers={artist.followers_count}
            />
        </Grid>
    ));
};

export default People;
