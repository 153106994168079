import React from 'react';
import { Box } from '@material-ui/core';
import BuyNow from './buyNow';
import AlgoFont from 'assets/img/algo-font.svg';
import MakeAnOffer from './makeAnOffer';
import Auction from './auction';
import OfferedItems from './offeredItems';

const PriceAndActions = ({ currentAsset, giveNotification, setRealoadAsset }) => {
    if (Object.keys(currentAsset).length === 0) {
        return null;
    }

    const bestSale = currentAsset?.base_node?.contracts?.find(
        (contract) => contract.type === 'SaleByEscrow' && contract.status === 'ACTIVE',
    );

    return (
        <Box className="asset-right-center">
            <Box display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center" flexWrap="wrap">
                    <Auction />
                    <Box className="price-algo">
                        <img src={AlgoFont} alt="Algo" />
                        <Box component="span" className="bid-price">
                            {bestSale ? Number(currentAsset?.base_node?.price)?.toFixed(3) : '-'}
                        </Box>
                    </Box>
                </Box>
                <BuyNow
                    currentAsset={currentAsset}
                    giveNotification={giveNotification}
                    setRealoadAsset={setRealoadAsset}
                    bestSale={bestSale}
                />
                <MakeAnOffer currentAsset={currentAsset} />
                <OfferedItems currentAsset={currentAsset} setRealoadAsset={setRealoadAsset} />

                {/*Place a Bid Button*/}
                {/* {!createdByMe() && currentAsset?.auction && (
                    <Button variant="contained" color="primary" size="large">
                        Place a Bid
                    </Button>
                )} */}
            </Box>
        </Box>
    );
};

export default PriceAndActions;
