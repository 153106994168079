import React, { useState } from 'react';
import { Button, Menu, Box, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AlgoFont from '../../../assets/img/algo-font.svg';

const DropdownFilter = ({ setFilterObj, filterObj }) => {
    const checked = (key, event) => {
        const value = event.target.name;
        if (filterObj[key].length === 1 && filterObj[key].includes(value)) {
            return;
        }
        setFilterObj((prev) => ({
            ...prev,
            [key]: prev[key].includes(value)
                ? prev[key].filter((x) => x !== value)
                : [...prev[key], value],
        }));
    };

    const handleChangeStatus = (event) => {
        return checked('status', event);
    };
    const handleChangePrice = (event) => {
        return checked('price', event);
    };
    const handleChangeType = (event) => {
        return checked('type', event);
    };

    const handleChangeStatusAll = (event) => {
        if (filterObj.status.length === 2) return;

        setFilterObj((prev) => ({
            ...prev,
            status: ['buy_now', 'auction'],
        }));
    };

    const handleChangeTypeAll = (event) => {
        if (filterObj.type.length === 2) return;

        setFilterObj((prev) => ({
            ...prev,
            type: ['single', 'series'],
        }));
    };

    const handleChangePriceAll = (event) => {
        if (filterObj.price.length === 6) return;

        setFilterObj((prev) => ({
            ...prev,
            price: ['1-10', '10-50', '50-100', '50-100', '100-200', '200-'],
        }));
    };

    const [cardMenuEl, setCardMenuEl] = useState(null);

    const openCardMenu = (e) => {
        setCardMenuEl(e.currentTarget);
    };

    const closeCardMenu = () => {
        setCardMenuEl(null);
    };

    const getAlgoPrice = (from, to) => {
        return (
            <span className="dropdown-prices">
                <img src={AlgoFont} alt="algoFont" /> {from} - <img src={AlgoFont} alt="algoFont" />{' '}
                {to}
            </span>
        );
    };
    const getAlgoPricePlus = (from) => {
        return (
            <span className="dropdown-prices">
                <img src={AlgoFont} alt="algoFont" /> {from} +
            </span>
        );
    };

    return (
        <>
            <Button className="dropdown-btn" variant="outlined" onClick={openCardMenu}>
                Filter
                <ExpandMoreIcon />
            </Button>
            <Menu
                anchorEl={cardMenuEl}
                keepMounted
                open={Boolean(cardMenuEl)}
                onClose={closeCardMenu}
                className="sort-dropdown"
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box display="flex" alignItems="flex-start" className="filter-checkboxes">
                    {/*Status*/}
                    <Box className="filter-checkboxes-column">
                        <Typography variant="body1" className="checklist-title text-capitalize">
                            Status
                        </Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleChangeStatusAll}
                                        name="all"
                                        color="primary"
                                        checked={filterObj.status.length === 2}
                                    />
                                }
                                label="All"
                                disabled
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleChangeStatus}
                                        name="buy_now"
                                        color="primary"
                                        checked={filterObj.status.includes('buy_now')}
                                    />
                                }
                                label="Buy now"
                                disabled
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleChangeStatus}
                                        name="auction"
                                        color="primary"
                                        checked={filterObj.status.includes('auction')}
                                    />
                                }
                                label="Auction"
                                disabled
                            />
                        </FormGroup>
                    </Box>
                    {/*Num of items*/}
                    <Box className="filter-checkboxes-column">
                        <Typography variant="body1" className="checklist-title text-capitalize">
                            Num of items
                        </Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleChangeTypeAll}
                                        name="all"
                                        color="primary"
                                        checked={filterObj.type.length === 2}
                                    />
                                }
                                label="All"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleChangeType}
                                        name="single"
                                        color="primary"
                                        checked={filterObj.type.includes('single')}
                                    />
                                }
                                label="Single Item"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleChangeType}
                                        name="series"
                                        color="primary"
                                        checked={filterObj.type.includes('series')}
                                    />
                                }
                                label="Bundle"
                            />
                        </FormGroup>
                    </Box>
                    {/*Price*/}
                    <Box className="filter-checkboxes-column">
                        <Typography variant="body1" className="checklist-title text-capitalize">
                            Price
                        </Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleChangePriceAll}
                                        name="all"
                                        color="primary"
                                        checked={filterObj.price.length === 6}
                                    />
                                }
                                label="All"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleChangePrice}
                                        name="1-10"
                                        color="primary"
                                        checked={filterObj.price.includes('1-10')}
                                    />
                                }
                                label={getAlgoPrice(1, 10)}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleChangePrice}
                                        name="10-50"
                                        color="primary"
                                        checked={filterObj.price.includes('10-50')}
                                    />
                                }
                                label={getAlgoPrice(10, 50)}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleChangePrice}
                                        name="50-100"
                                        color="primary"
                                        checked={filterObj.price.includes('50-100')}
                                    />
                                }
                                label={getAlgoPrice(50, 100)}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleChangePrice}
                                        name="100-200"
                                        color="primary"
                                        checked={filterObj.price.includes('100-200')}
                                    />
                                }
                                label={getAlgoPrice(100, 200)}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleChangePrice}
                                        name="200-"
                                        color="primary"
                                        checked={filterObj.price.includes('200-')}
                                    />
                                }
                                label={getAlgoPricePlus(200)}
                            />
                        </FormGroup>
                    </Box>
                </Box>
            </Menu>
        </>
    );
};

export default DropdownFilter;
