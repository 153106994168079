import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Typography, Box, Container, Grid, Card, CardMedia, Avatar } from '@material-ui/core';
import { ThumbUpOutlined, ThumbUp, ChevronRight } from '@material-ui/icons';
import { Tag } from 'components/shared';
import {
    getBestVoted,
    setValueBestVoted,
    upvoteAsset,
    unUpvoteAsset,
} from 'redux/bestVotedAssets/actions';
import { useDispatch, useSelector } from 'react-redux';
import SeeMoreBtn from '../../components/shared/SeeMoreBtn';

const BestVoted = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { bestVotedAssets } = useSelector((state) => state.bestVotedAssets);
    const { user } = useSelector((state) => state.auth);
    const { isLoggedIn } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(getBestVoted());
    }, [dispatch, isLoggedIn]);

    const onUpvoted = (guid, username) => {
        if (isLoggedIn && user.username !== username) {
            dispatch(upvoteAsset(guid));
        }
    };
    const onUnUpvoted = (guid, username) => {
        if (isLoggedIn && user.username !== username) {
            dispatch(unUpvoteAsset(guid));
        }
    };
    const redirectToMarketplace = () => {
        dispatch(setValueBestVoted(true));
    };

    const showAsset = (assetId) => {
        if (isLoggedIn) {
            history.push(`asset/${assetId}`);
        }
    };
    return (
        <div className="home-best-voted">
            <Container maxWidth="xl">
                <Box mb={4} textAlign="center">
                    <Typography variant="h2">Voted best creators & Collections</Typography>
                </Box>
                <Grid container spacing={3} className="list-with-link">
                    {bestVotedAssets?.map((item) => {
                        const pictureData = getPictureData(item?.asset);
                        return (
                            <Grid
                                item
                                md={4}
                                xs={12}
                                key={item?.guid}
                                onClick={() => showAsset(item?.asset?.asset_id)}
                                style={{ cursor: isLoggedIn ? 'pointer' : 'default' }}
                            >
                                <Card className="h-100">
                                    <Box p={1.5} className="card-img-wrap">
                                        <div className="card-img">
                                            {pictureData && (
                                                <CardMedia
                                                    component={pictureData?.component}
                                                    src={pictureData?.src}
                                                    muted={pictureData?.muted}
                                                    // controls
                                                    loop
                                                    autoPlay={pictureData?.autoplay}
                                                    style={{ height: 360, objectFit: 'cover' }}
                                                />
                                            )}
                                            <Tag
                                                text="TOP"
                                                className="brand-red md bottom-left rotated"
                                                icon={
                                                    <i
                                                        className="icon-fire"
                                                        style={{ fontSize: 16 }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </Box>

                                    <Box
                                        pt={1}
                                        pb={3}
                                        px={3}
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <div>
                                            <Box fontSize={16} mb={1}>
                                                {item?.title}
                                            </Box>
                                            <Box fontSize={16} display="flex" alignItems="center">
                                                <Avatar
                                                    alt={item?.creator?.username}
                                                    src={item?.creator?.avatar}
                                                    className="sm"
                                                />
                                                <Box
                                                    fontWeight="bold"
                                                    fontFamily="h1.fontFamily"
                                                    ml={1}
                                                    className="link primary ellipsis"
                                                    component={Link}
                                                    to="/"
                                                >
                                                    @{item?.creator?.username}
                                                </Box>
                                            </Box>
                                        </div>

                                        <Box
                                            fontSize={18}
                                            mt={1}
                                            textAlign="right"
                                            display="flex"
                                            alignItems="center"
                                            className={`${item?.voted ? 'color-primary' : ''}`}
                                        >
                                            {item?.voted ? (
                                                <ThumbUp
                                                    style={{ fontSize: 24 }}
                                                    className="pointer hover-opacity"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onUnUpvoted(
                                                            item?.guid,
                                                            item?.creator?.username,
                                                        );
                                                    }}
                                                />
                                            ) : (
                                                <ThumbUpOutlined
                                                    style={{ fontSize: 24 }}
                                                    className="pointer hover-opacity"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onUpvoted(
                                                            item?.guid,
                                                            item?.creator?.username,
                                                        );
                                                    }}
                                                />
                                            )}
                                            <Box component="span" ml={1}>
                                                {item?.vote_count}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                        );
                    })}

                    {/*Desktop "see more" button (blue round with arrow)*/}
                    <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
                        <Link
                            to="/marketplace"
                            className="list-link"
                            onClick={redirectToMarketplace}
                        >
                            <ChevronRight style={{ fontSize: 56 }} />
                        </Link>
                    </Box>

                    {/*Mobile "see more" button*/}
                    <Box
                        display={{ sm: 'flex', md: 'none' }}
                        justifyContent="center"
                        style={{ width: '100%' }}
                    >
                        <SeeMoreBtn />
                    </Box>
                </Grid>
            </Container>
        </div>
    );
};

export default BestVoted;

function getPictureData(data) {
    if (!data) return null;
    const type = data?.content_type?.split('/')[0];
    switch (type) {
        case 'image':
            return { component: 'img', src: data.content_url };
        case 'video':
            return { component: 'video', src: data.content_url, autoplay: 'autoPlay', muted: true };
        case 'audio':
        case 'text':
        case 'application':
            return { component: 'img', src: data?.thumbnail?.url };
        default:
            return { component: 'img', src: data.content_url };
    }
}
