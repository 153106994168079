export const getFilteredPeopleElements = (
    sortPeople,
    filterPeopleByTag,
    searchPeople,
    sortPeopleByRole,
) => {
    const filterElements = [];
    if (sortPeople) {
        filterElements.push(`sort_by=${sortPeople}`);
    }
    if (filterPeopleByTag.length > 0 && filterPeopleByTag.length < 6) {
        filterElements.push(`tags=${filterPeopleByTag.join(',')}`);
    }
    if (searchPeople) {
        filterElements.push(`search=${searchPeople}`);
    }
    if (sortPeopleByRole) {
        filterElements.push(`role=${sortPeopleByRole}`);
    }
    const filterPath = filterElements.length !== 0 ? `?${filterElements.join('&')}` : '';
    return filterPath;
};

export const getFilteredAssetElements = (
    filterObj,
    sortAssets,
    filterAssetsByTag,
    searchAssets,
) => {
    const filterElements = ['page_size=12'];
    if (filterObj.status.length !== 2) {
        filterElements.push(`status=${filterObj.status[0]}`);
    }
    if (filterObj.type.length !== 2) {
        filterElements.push(`type=${filterObj.type[0]}`);
    }
    if (filterObj.price.length !== 6) {
        filterElements.push(`price=${filterObj.price.join(',')}`);
    }
    if (sortAssets) {
        filterElements.push(`sort_by=${sortAssets}`);
    }
    if (filterAssetsByTag.length > 0 && filterAssetsByTag.length < 6) {
        filterElements.push(`media_types=${filterAssetsByTag.join(',')}`);
    }
    if (searchAssets) {
        filterElements.push(`search=${searchAssets}`);
    }
    const filterPath = filterElements.length !== 0 ? `?${filterElements.join('&')}` : '';
    return filterPath;
};
