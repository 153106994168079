import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Box, Typography, Grid } from '@material-ui/core';
import { loadMoreMarketplaceItems } from 'redux/marketplace/actions';
import { loadMoreMarketplaceUsers } from 'redux/marketplace/actions';
import TabSearch from '../../components/shared/TabSearch';
import PeopleMinCard from '../../components/elements/cards/peopleMinCard';
import MarketplaceAuction from './Auction';
// import DropdownDate from '../../components/shared/dropdown/dropdown-date';
import LoadMoreBtn from '../../components/shared/LoadMoreBtn';
import DropdownPeople from '../../components/shared/dropdown/dropdown-people';
import DropdownSortAsset from '../../components/shared/dropdown/dropdown-sort-asset';
import DropdownSortPeople from '../../components/shared/dropdown/dropdown-sort-people';
import DropdownFilter from '../../components/shared/dropdown/dropdown-filter';
import { getUsers } from 'redux/marketplace/actions';
import {
    setValueSearchFromHome,
    setFilterByTagFromSide,
    moveMarketplaceFromFeatured,
} from 'redux/marketplace/actions';
import { setValueBestVoted } from 'redux/bestVotedAssets/actions';
import Lottie from 'lottie-react';
import loader from 'assets/img/lf30_editor_dwdfl6eb.json';
import { FILTER_CONFIG } from 'configs/marketplaceConfig';
import { getFilteredPeopleElements } from './util';
import Assets from './items/Assets';
import People from './items/People';
import Tags from './items/Tags';

const Marketplace = () => {
    const [viewType, setViewType] = useState(null);
    const {
        loadMoreMarkURL,
        loadMoreUsersURL,
        users,
        peopleFromFooter,
        loadMoreLoading,
        fromFeaturedArtist,
    } = useSelector((state) => state.marketplace);
    const { searchFromHomePage, filterByTagFromSide } = useSelector((state) => state.marketplace);
    const { fromBestVoted } = useSelector((state) => state.bestVotedAssets);
    const dispatch = useDispatch();
    const [filterObj, setFilterObj] = useState(FILTER_CONFIG.assetFilter);

    const [sortAssets, setSortAssets] = useState(
        fromBestVoted ? FILTER_CONFIG.popular : FILTER_CONFIG.recently,
    );
    const [filterAssetsByTag, setFilterAssetsByTag] = useState([]);

    const [sortPeople, setSortPeople] = useState(FILTER_CONFIG.most_followed);
    const [filterPeopleByTag, setFilterPeopleByTag] = useState([]);

    const [searchAssets, setSearchAssets] = useState(searchFromHomePage);
    const [searchPeople, setSearchPeople] = useState('');

    const [sortPeopleByRole, setSortPeopleByRole] = useState('');

    useEffect(() => {
        if (peopleFromFooter?.sort) {
            setViewType(peopleFromFooter?.viewType);
            setSortPeopleByRole(peopleFromFooter?.sort);
        }
    }, [peopleFromFooter]);

    useEffect(() => {
        if (fromFeaturedArtist) {
            setViewType('people');
        }
        return () => {
            dispatch(moveMarketplaceFromFeatured(false));
        };
    }, [fromFeaturedArtist, dispatch]);

    useEffect(() => {
        if (filterByTagFromSide) setFilterAssetsByTag([filterByTagFromSide]);
    }, [filterByTagFromSide]);

    const getPeople = useCallback(() => {
        const filterPath = getFilteredPeopleElements(
            sortPeople,
            filterPeopleByTag,
            searchPeople,
            sortPeopleByRole,
        );
        dispatch(getUsers(filterPath));
    }, [dispatch, filterPeopleByTag, sortPeople, searchPeople, sortPeopleByRole]);

    useEffect(() => {
        getPeople();
    }, [getPeople]);

    useEffect(() => {
        return () => {
            dispatch(setValueSearchFromHome(null));
            dispatch(setValueBestVoted(false));
            dispatch(setFilterByTagFromSide(null));
        };
    }, [dispatch]);

    const loadMoreAssets = () => {
        dispatch(loadMoreMarketplaceItems(loadMoreMarkURL));
    };
    const loadMoreUsers = () => {
        dispatch(loadMoreMarketplaceUsers(loadMoreUsersURL));
    };
    return (
        <div className="marketplace">
            {/*Marketplace Auction*/}
            <MarketplaceAuction />

            <div className="marketplace-content">
                <Container maxWidth="xl">
                    {/*Search with tab*/}
                    <TabSearch
                        viewType={viewType}
                        searchFromHomePage={searchFromHomePage}
                        setAssetsType={setViewType}
                        setSearchValue={viewType === 'people' ? setSearchPeople : setSearchAssets}
                    />

                    <Box
                        display={{ sm: 'block', md: 'flex' }}
                        justifyContent="space-between"
                        className="filter-list"
                    >
                        {/*Tags*/}
                        <Box className="button-tag-list">
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                flexWrap="wrap"
                            >
                                {viewType === 'people' ? (
                                    <Tags
                                        filterByTag={filterPeopleByTag}
                                        setFilterByTag={setFilterPeopleByTag}
                                        viewType={viewType}
                                    />
                                ) : (
                                    <Tags
                                        filterByTag={filterAssetsByTag}
                                        setFilterByTag={setFilterAssetsByTag}
                                        viewType={viewType}
                                    />
                                )}
                            </Box>
                        </Box>

                        {/*Filter DropdownDate*/}
                        <Box className="filter-sort">
                            {/*Dropdown with checkboxes*/}
                            {viewType === 'assets' && (
                                <DropdownFilter setFilterObj={setFilterObj} filterObj={filterObj} />
                            )}
                            {/*Dropdown with Radio Buttons for Assets*/}
                            {viewType &&
                                (viewType === 'assets' ? (
                                    <DropdownSortAsset
                                        setSortAssets={setSortAssets}
                                        sortAssets={sortAssets}
                                    />
                                ) : (
                                    <DropdownSortPeople
                                        sortPeople={sortPeople}
                                        setSortPeople={setSortPeople}
                                    />
                                ))}
                        </Box>
                    </Box>

                    <Box
                        display="flex"
                        alignItems="center"
                        style={{ paddingTop: 12, paddingBottom: 8 }}
                    >
                        {/*Simple Text Dropdown for People*/}
                        {viewType !== 'assets' && (
                            <DropdownPeople
                                setSortPeopleByRole={setSortPeopleByRole}
                                sortPeopleByRole={sortPeopleByRole}
                            />
                        )}
                        {/*Simple Text Dropdown for Date*/}
                        {/* {!viewType && <DropdownDate />} */}
                    </Box>

                    {/*People mini cards*/}
                    {!viewType && (
                        <Box display="flex" flexWrap="wrap" justifyContent="space-between" py={3}>
                            {users?.map((item) => (
                                <PeopleMinCard
                                    key={item.username}
                                    tags={item.selected_tags}
                                    authorAvatar={item.avatar}
                                    author={item.username}
                                />
                            ))}
                        </Box>
                    )}

                    {/*Search result*/}
                    <div className="marketplace-result">
                        {/*Title (Assets / People)*/}
                        <Typography variant="h3" className="text-capitalize mb-5">
                            {viewType !== 'people' && 'Assets'}
                        </Typography>

                        <Grid container spacing={3} className="list-with-link">
                            {/*People*/}
                            {viewType === 'people' && <People users={users} />}
                            {/*Assets*/}
                            {viewType !== 'people' && (
                                <Assets
                                    searchAssets={searchAssets}
                                    filterObj={filterObj}
                                    sortAssets={sortAssets}
                                    filterAssetsByTag={filterAssetsByTag}
                                />
                            )}

                            {/*No results found*/}
                            {/*<Typography  className="no-results-found">
                                No results found
                            </Typography>*/}
                        </Grid>
                    </div>

                    {loadMoreMarkURL &&
                        (loadMoreLoading ? (
                            <Lottie
                                mt={5}
                                animationData={loader}
                                style={{ height: '49px', width: '100%', marginTop: '40px' }}
                            />
                        ) : (
                            <LoadMoreBtn
                                loadMoreAssets={() =>
                                    viewType === 'people' ? loadMoreUsers() : loadMoreAssets()
                                }
                            />
                        ))}
                </Container>
            </div>
        </div>
    );
};

export default Marketplace;
