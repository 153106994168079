import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAlgorandAccountInfo, setContract } from 'redux/algorand/actions';
import { getCurrentAsset, updateAsset, emptyCurrentAsset } from 'redux/singleAsset/actions';
import { getAssetsTags } from 'redux/marketplace/actions';
import { createEscrowContract } from 'transactions/smart-contract/escrow/saleByEscrow/createContract';
import { cancelOldContract } from 'transactions/smart-contract/escrow/saleByEscrow/cancelOldContract';
import AssetEdit from './edit';
import { setNotification } from 'redux/profile/actions';

const AssetEditContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { assetId } = useParams();
    const { currentAsset } = useSelector((state) => state.singleAsset);
    const { selectedWallet } = useSelector((state) => state.profile);
    const [loaderValue, setLoaderValue] = useState(false);
    const { user } = useSelector((state) => state.auth);

    const nodeOfAsset = currentAsset?.nodes?.find((node) => node.owner.username === user.username);
    useEffect(() => {
        if (nodeOfAsset?.user_type === 'creator') {
            dispatch(getAssetsTags());
        }
    }, [dispatch, nodeOfAsset]);

    useEffect(() => {
        if (assetId) {
            dispatch(getCurrentAsset(assetId));
        }
        return () => {
            dispatch(emptyCurrentAsset());
        };
    }, [assetId, dispatch]);

    const giveNotification = (status, message) => {
        dispatch(setNotification({ status, message }));
    };

    const finishUpdate = () => {
        setLoaderValue(false);
        history.push(`/asset/${currentAsset?.asset?.asset_id}`);
    };

    if (!nodeOfAsset) return null;

    const checkIsChange = (newValues) => {
        const myNode = currentAsset?.nodes?.find((x) => x.owner.username === user.username);
        if (myNode) {
            return (
                newValues.price_is_visible !== myNode.price_is_visible ||
                newValues.price !== myNode.price ||
                newValues.royalties !== currentAsset.royalties ||
                JSON.stringify(newValues.media_types) !==
                    JSON.stringify(currentAsset.media_types.map((x) => x.slug)) ||
                newValues.visibility !== myNode?.visibility
            );
        }
        return false;
    };

    const changeContract = (newValues) => {
        const myNode = currentAsset?.nodes?.find((x) => x.owner.username === user.username);

        if (myNode) {
            return (
                (newValues.price_is_visible !== myNode.price_is_visible ||
                    newValues.price !== myNode.price ||
                    newValues.royalties !== currentAsset.royalties) &&
                newValues.price_is_visible &&
                newValues.price > 0
            );
        }
        return false;
    };

    const sendCreatedContract = async (guid) => {
        return await dispatch(setContract(guid));
    };

    const getAccountInfo = (account) => {
        return dispatch(getAlgorandAccountInfo(account));
    };

    const createContract = async (contract) => {
        try {
            if (!contract) {
                return giveNotification(
                    'error',
                    contract?.data?.error || 'Does not found teal for contract',
                );
            }
            const response = await createEscrowContract(
                selectedWallet?.address,
                contract,
                currentAsset?.asset?.asset_id,
                getAccountInfo,
                sendCreatedContract,
            );
            if (response.status === 'success') {
                finishUpdate();
                return giveNotification('success', 'The contract was created successfully!');
            }
            setLoaderValue(false);
            return giveNotification('error', response.message);
        } catch (err) {
            setLoaderValue(false);
            return giveNotification('error', err?.message);
        }
    };

    const update = async (values) => {
        if (!checkIsChange(values)) {
            return giveNotification('info', 'You do not change something');
        }

        setLoaderValue(true);
        if (changeContract(values)) {
            if (!selectedWallet) {
                setLoaderValue(false);
                return giveNotification('info', 'You need to connect your wallet');
            }

            if (nodeOfAsset?.holder_address !== selectedWallet?.address) {
                setLoaderValue(false);
                return giveNotification(
                    'error',
                    'Your wallet address does not match the address where this asset is.Please select the correct wallet.',
                );
            }

            const oldContract = nodeOfAsset.contracts[0];
            const actionsWithOldContract = await cancelOldContract(
                oldContract,
                currentAsset?.asset?.asset_id,
                selectedWallet,
            );
            if (actionsWithOldContract.status === 'bought') {
                setLoaderValue(false);
                return giveNotification(
                    'warning',
                    'This asset has already been bought. You can not make changes',
                );
            }
            if (actionsWithOldContract.status === 'error') {
                setLoaderValue(false);
                return giveNotification('error', actionsWithOldContract.message);
            }
        }
        dispatch(updateAsset(nodeOfAsset.guid, values)).then((response) => {
            if (response?.status === 200) {
                if (changeContract(values)) {
                    giveNotification(
                        'success',
                        'The assets was updated successfully! Start create contract',
                    );
                    createContract(response?.data?.contract);
                } else {
                    finishUpdate();
                }
            } else {
                setLoaderValue(false);
                giveNotification('error', 'Asset was not updated, please try again');
            }
        });
    };

    return (
        <>
            <AssetEdit update={update} loaderValue={loaderValue} />
        </>
    );
};

export default AssetEditContainer;
