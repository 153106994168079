import * as Yup from 'yup';

export const assetCreateValidationStep3 = Yup.object({
    quantity: Yup.number('Enter quantity').required('Quantity is required'),
    royalties: Yup.string()
        .test(
            'number',
            'The value must be number',
            (data) => Number(data?.replace(/%/, ' ')) || Number(data?.replace(/%/, ' ')) === 0,
        )
        .test('min', 'Enter greater than 0', (data) => Number(data?.replace(/%/, '') >= 0))
        .test('max', 'Enter less than 20', (data) => Number(data?.replace(/%/, '') <= 20))
        .test(
            'maxDigitsAfterDecimal',
            'number field must have 2 digits after decimal or less',
            (number) => Number.isInteger(Number(number?.replace(/%/, '')) * 10 ** 2),
        )
        .required('Royality is required'),
});
