export const GET_ONE_ASSET_REQUEST = 'GET_ONE_ASSET_REQUEST';
export const GET_ONE_ASSET_SUCCESS = 'GET_ONE_ASSET_SUCCESS';
export const GET_ONE_ASSET_FAIL = 'GET_ONE_ASSET_FAIL';

export const UPVOTING_ONE_ASSET_REQUEST = 'UPVOTING_ONE_ASSET_REQUEST';
export const UPVOTING_ONE_ASSET_SUCCESS = 'UPVOTING_ONE_ASSET_SUCCESS';
export const UPVOTING_ONE_ASSET_FAIL = 'UPVOTING_ONE_ASSET_FAIL';

export const UNUPVOTING_ONE_ASSET_REQUEST = 'UNUPVOTING_ONE_ASSET_REQUEST';
export const UNUPVOTING_ONE_ASSET_SUCCESS = 'UNUPVOTING_ONE_ASSET_SUCCESS';
export const UNUPVOTING_ONE_ASSET_FAIL = 'UNUPVOTING_ONE_ASSET_FAIL';

export const ADD_TO_FAVORITES_ONE_ASSET_REQUEST = 'ADD_TO_FAVORITES_ONE_ASSET_REQUEST';
export const ADD_TO_FAVORITES_ONE_ASSET_SUCCESS = 'ADD_TO_FAVORITES_ONE_ASSET_SUCCESS';
export const ADD_TO_FAVORITES_ONE_ASSET_FAIL = 'ADD_TO_FAVORITES_ONE_ASSET_FAIL';

export const REMOVE_FROM_FAVORITES_ONE_ASSET_REQUEST = 'REMOVE_FROM_FAVORITES_ONE_ASSET_REQUEST';
export const REMOVE_FROM_FAVORITES_ONE_ASSET_SUCCESS = 'REMOVE_FROM_FAVORITES_ONE_ASSET_SUCCESS';
export const REMOVE_FROM_FAVORITES_ONE_ASSET_FAIL = 'REMOVE_FROM_FAVORITES_ONE_ASSET_FAIL';

export const GET_ASSETS_WITH_SAME_TAG_REQUEST = 'GET_ASSETS_WITH_SAME_TAG_REQUEST';
export const GET_ASSETS_WITH_SAME_TAG_SUCCESS = 'GET_ASSETS_WITH_SAME_TAG_SUCCESS';
export const GET_ASSETS_WITH_SAME_TAG_FAIL = 'GET_ASSETS_WITH_SAME_TAG_FAIL';

export const UPVOTING_ASSET_WITH_SAME_TAG_REQUEST = 'UPVOTING_ASSET_WITH_SAME_TAG_REQUEST';
export const UPVOTING_ASSET_WITH_SAME_TAG_SUCCESS = 'UPVOTING_ASSET_WITH_SAME_TAG_SUCCESS';
export const UPVOTING_ASSET_WITH_SAME_TAG_FAIL = 'UPVOTING_ASSET_WITH_SAME_TAG_FAIL';

export const UNUPVOTING_ASSET_WITH_SAME_TAG_REQUEST = 'UNUPVOTING_ASSET_WITH_SAME_TAG_REQUEST';
export const UNUPVOTING_ASSET_WITH_SAME_TAG_SUCCESS = 'UNUPVOTING_ASSET_WITH_SAME_TAG_SUCCESS';
export const UNUPVOTING_ASSET_WITH_SAME_TAG_FAIL = 'UNUPVOTING_ASSET_WITH_SAME_TAG_FAIL';

export const ADD_TO_FAVORITES_ASSET_WITH_SAME_TAG_REQUEST =
    'ADD_TO_FAVORITES_ASSET_WITH_SAME_TAG_REQUEST';
export const ADD_TO_FAVORITES_ASSET_WITH_SAME_TAG_SUCCESS =
    'ADD_TO_FAVORITES_ASSET_WITH_SAME_TAG_SUCCESS';
export const ADD_TO_FAVORITES_ASSET_WITH_SAME_TAG_FAIL =
    'ADD_TO_FAVORITES_ASSET_WITH_SAME_TAG_FAIL';

export const REMOVE_FROM_FAVORITES_ASSET_WITH_SAME_TAG_REQUEST =
    'REMOVE_FROM_FAVORITES_ASSET_WITH_SAME_TAG_REQUEST';
export const REMOVE_FROM_FAVORITES_ASSET_WITH_SAME_TAG_SUCCESS =
    'REMOVE_FROM_FAVORITES_ASSET_WITH_SAME_TAG_SUCCESS';
export const REMOVE_FROM_FAVORITES_ASSET_WITH_SAME_TAG_FAIL =
    'REMOVE_FROM_FAVORITES_ASSET_WITH_SAME_TAG_FAIL';

export const CHANGE_ASSET_VISIBILITY_REQUEST = 'CHANGE_ASSET_VISIBILITY_REQUEST';
export const CHANGE_ASSET_VISIBILITY_SUCCESS = 'CHANGE_ASSET_VISIBILITY_SUCCESS';
export const CHANGE_ASSET_VISIBILITY_FAIL = 'CHANGE_ASSET_VISIBILITY_FAIL';

export const UPDATE_ASSET_REQUEST = 'UPDATE_ASSET_REQUEST';
export const UPDATE_ASSET_SUCCESS = 'UPDATE_ASSET_SUCCESS';
export const UPDATE_ASSET_FAIL = 'UPDATE_ASSET_FAIL';

export const EMPTY_ONE_ASSET = 'EMPTY_ONE_ASSET';

export const GET_REPORT_TEMPLATES_REQUEST = 'GET_REPORT_TEMPLATES_REQUEST';
export const GET_REPORT_TEMPLATES_SUCCESS = 'GET_REPORT_TEMPLATES_SUCCESS';
export const GET_REPORT_TEMPLATES_FAIL = 'GET_REPORT_TEMPLATES_FAIL';

export const SEND_REPORTS_REQUEST = 'SEND_REPORTS_REQUEST';
export const SEND_REPORTS_SUCCESS = 'SEND_REPORTS_SUCCESS';
export const SEND_REPORTS_FAIL = 'SEND_REPORTS_FAIL';

export const DELETE_CONTRACT_SUCCESS = 'DELETE_CONTRACT_SUCCESS';

export const CANCEL_OFFER_REQUEST = 'CANCEL_OFFER_REQUEST';
export const CANCEL_OFFER_SUCCESS = 'CANCEL_OFFER_SUCCESS';
export const CANCEL_OFFER_FAIL = 'CANCEL_OFFER_FAIL';

export const GET_SINGLE_ASSET_ACTIVITIES_REQUEST = 'GET_SINGLE_ASSET_ACTIVITIES_REQUEST';
export const GET_SINGLE_ASSET_ACTIVITIES_SUCCESS = 'GET_SINGLE_ASSET_ACTIVITIES_SUCCESS';
export const GET_SINGLE_ASSET_ACTIVITIES_FAIL = 'GET_SINGLE_ASSET_ACTIVITIES_FAIL';

export const LOAD_MORE_SINGLE_ASSET_ACTIVITIES_REQUEST =
    'LOAD_MORE_SINGLE_ASSET_ACTIVITIES_REQUEST';
export const LOAD_MORE_SINGLE_ASSET_ACTIVITIES_SUCCESS =
    'LOAD_MORE_SINGLE_ASSET_ACTIVITIES_SUCCESS';
export const LOAD_MORE_SINGLE_ASSET_ACTIVITIES_FAIL = 'LOAD_MORE_SINGLE_ASSET_ACTIVITIES_FAIL';
