export const GET_FAVORITES_ASSETS_REQUEST = 'GET_FAVORITES_ASSETS_REQUEST';
export const GET_FAVORITES_ASSETS_SUCCESS = 'GET_FAVORITES_ASSETS_SUCCESS';
export const GET_FAVORITES_ASSETS_FAIL = 'GET_FAVORITES_ASSETS_FAIL';

export const LOAD_MORE_FAVORITES_ASSETS_REQUEST = 'LOAD_MORE_FAVORITES_ASSETS_REQUEST';
export const LOAD_MORE_FAVORITES_ASSETS_SUCCESS = 'LOAD_MORE_FAVORITES_ASSETS_SUCCESS';
export const LOAD_MORE_FAVORITES_ASSETS_FAIL = 'LOAD_MORE_FAVORITES_ASSETS_FAIL';

export const UPVOTING_FAVORITE_ASSET_REQUEST = 'UPVOTING_FAVORITE_ASSET_REQUEST';
export const UPVOTING_FAVORITE_ASSET_SUCCESS = 'UPVOTING_FAVORITE_ASSET_SUCCESS';
export const UPVOTING_FAVORITE_ASSET_FAIL = 'UPVOTING_FAVORITE_ASSET_FAIL';

export const UNUPVOTING_FAVORITE_ASSET_REQUEST = 'UNUPVOTING_FAVORITE_ASSET_REQUEST';
export const UNUPVOTING_FAVORITE_ASSET_SUCCESS = 'UNUPVOTING_FAVORITE_ASSET_SUCCESS';
export const UNUPVOTING_FAVORITE_ASSET_FAIL = 'UNUPVOTING_FAVORITE_ASSET_FAIL';

export const REMOVE_FROM_FAVORITES_ASSETS_REQUEST = 'REMOVE_FROM_FAVORITES_ASSETS_REQUEST';
export const REMOVE_FROM_FAVORITES_ASSETS_SUCCESS = 'REMOVE_FROM_FAVORITES_ASSETS_SUCCESS';
export const REMOVE_FROM_FAVORITES_ASSETS_FAIL = 'REMOVE_FROM_FAVORITES_ASSETS_FAIL';

export const UPDATE_VISIBILITY_FAVORITE_ASSET_REQUEST = 'UPDATE_VISIBILITY_FAVORITE_ASSET_REQUEST';
export const UPDATE_VISIBILITY_FAVORITE_ASSET_SUCCESS = 'UPDATE_VISIBILITY_FAVORITE_ASSET_SUCCESS';
export const UPDATE_VISIBILITY_FAVORITE_ASSET_FAIL = 'UPDATE_VISIBILITY_FAVORITE_ASSET_FAIL';
