import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import AlgoFont from 'assets/img/algo-font.svg';

import { useFormik, FormikProvider } from 'formik';

import ChangeWallet from '../../../components/elements/modal/changeWallet';
import Field from 'components/shared/fields/Field';
import FormControl from '@material-ui/core/FormControl';
import WalletCard from 'components/shared/WalletCard';
import AssetImage from '../assetImage';
import InfoPopUp from './infoPopUp';

import { Container, Box, Typography, Button, Link } from '@material-ui/core';

import { getCurrentAsset, emptyCurrentAsset } from 'redux/singleAsset/actions';
import {
    getAlgorandAccountInfo,
    getOfferByEsscrowContract,
    activateOfferByEscrowContract,
} from 'redux/algorand/actions';

import { createContract } from 'transactions/smart-contract/escrow/offerByEscrow/createContract';
import { makeAnOfferValidation } from 'services/yup-schemas/makeAnOfferValidation';

import Lottie from 'lottie-react';
import loader from 'assets/img/lf30_editor_dwdfl6eb.json';
import { MAKE_AN_OFFER_CONFIG } from 'configs';

import { closeContract } from 'transactions/smart-contract/escrow/offerByEscrow/closeContract';
import { deleteOfferByEscrowContract } from 'redux/algorand/actions';
import { setNotification } from 'redux/profile/actions';

const MakeAnOffer = () => {
    const dispatch = useDispatch();
    const { assetId } = useParams();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const [changeWallet, setChangeWallet] = useState(false);
    const { currentAsset } = useSelector((state) => state.singleAsset);
    const { selectedWallet } = useSelector((state) => state.profile);
    const [totalAmount, setTotalAmount] = useState(0);
    const [accountInfo, setAccountInfo] = useState(null);
    const [popUpInfo, setpopUpInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const zestBloomPercent = 15;

    const staticTag = currentAsset?.media_types?.find((tag) => tag.category === 'static');
    const offerContract = currentAsset?.offers?.find(
        (offer) => offer.maker.username === user?.username,
    );

    useEffect(() => {
        getTotalAmount(10);
    }, []);

    useEffect(() => {
        if (assetId) {
            dispatch(getCurrentAsset(assetId));
        }
        return () => {
            dispatch(emptyCurrentAsset());
        };
    }, [assetId, dispatch]);

    useEffect(() => {
        const address = selectedWallet?.address;
        if (address) {
            dispatch(getAlgorandAccountInfo(address))
                .then((response) => {
                    setAccountInfo(response?.account);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [dispatch, selectedWallet]);

    const toActivateContract = async (guid) => {
        return await dispatch(activateOfferByEscrowContract(guid));
    };

    const getAccountInfo = (account) => {
        return dispatch(getAlgorandAccountInfo(account));
    };
    const giveNotification = (status, message) => {
        dispatch(setNotification({ status, message }));
        setLoading(false);
    };

    const deleteContract = async (guid) => {
        return await dispatch(deleteOfferByEscrowContract(guid));
    };

    const sendOffer = async (values) => {
        try {
            if (!selectedWallet) {
                return giveNotification('info', 'You need to connect your wallet');
            }
            setLoading(true);
            const addressAmount = checkAmount(accountInfo, totalAmount);
            if (!addressAmount) {
                return giveNotification('warning', 'Wallet balance too low to create contract');
            }
            if (offerContract) {
                await closeContract(offerContract, deleteContract);
                console.log('Cancel old contract');
            }
            const data = {
                entity: currentAsset?.guid,
                offer_maker: selectedWallet?.address,
                offer_amount: values?.price,
                requested_qty: 1,
            };

            const contract = await dispatch(getOfferByEsscrowContract(data));
            if (!contract) {
                return giveNotification('error', 'Does not found contract');
            }
            await createContract(contract, toActivateContract, giveNotification, getAccountInfo);
            history.push(`/asset/${assetId}`);
        } catch (err) {
            console.log(err.message);
            setLoading(false);
        }
    };

    const goBack = () => {
        history.push(`/asset/${assetId}`);
    };

    const formik = useFormik({
        initialValues: {
            price: (10).toFixed(3),
        },
        validationSchema: makeAnOfferValidation,
        onSubmit: (values) => {
            sendOffer(values);
        },
    });

    const onOpenChangeWallet = () => {
        setChangeWallet(true);
    };
    const onCloseChangeWallet = () => {
        setChangeWallet(false);
    };

    const handlePopoverOpen = (event, info) => {
        setAnchorEl(event.currentTarget);
        setpopUpInfo(info);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const changePrice = (e) => {
        const value = e.target.value;
        const lessThreeDecimal = Number.isInteger(Number(value * 10 ** 3));
        if (lessThreeDecimal) {
            formik.setFieldValue('price', value);
            getTotalAmount(value);
        }
    };

    const openPopover = Boolean(anchorEl);

    function getTotalAmount(offeredPrice) {
        const zestBloomAmount = (offeredPrice * zestBloomPercent) / 100;
        if (!offeredPrice) {
            return setTotalAmount(0);
        }
        const total = +offeredPrice + zestBloomAmount + 1;
        const decimalsCount = total?.toString()?.split('.')[1]?.length > 6;
        if (decimalsCount) {
            return setTotalAmount(total.toFixed(6));
        }
        setTotalAmount(total);
    }

    return (
        <>
            <Container maxWidth="md" className="asset-mini-content">
                <Typography className="asset-mini-content-title">Make an Offer</Typography>
                <Box display="flex" className="asset-mini-content-body">
                    <Box className="left">
                        <Box className="image">
                            <AssetImage staticTag={staticTag} currentAsset={currentAsset} />
                        </Box>
                        <Typography className="title-of-artwork">{currentAsset?.title}</Typography>
                    </Box>
                    <Box className="right">
                        <Box display="flex" className="wallet" alignItems="center">
                            <Box className="checked-wallet">
                                <Box component="ul" display="flex">
                                    {selectedWallet && (
                                        <WalletCard
                                            label={selectedWallet?.name}
                                            key={selectedWallet?.address}
                                            connected={true}
                                            address={selectedWallet?.address}
                                        />
                                    )}
                                    <WalletCard label="Wallet Name" connected={false} />
                                </Box>
                            </Box>
                            {selectedWallet && (
                                <Link
                                    to="#"
                                    className="color-secondary hover-opacity change-wallet-button"
                                    onClick={onOpenChangeWallet}
                                >
                                    Change Wallet
                                </Link>
                            )}
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            className="your-balance"
                        >
                            <Box component="span" className="mini-title">
                                Min. Required Balance
                                <i
                                    className="icon-information-outline"
                                    aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={(e) =>
                                        handlePopoverOpen(
                                            e,
                                            MAKE_AN_OFFER_CONFIG.minRequiredBalanceDescription,
                                        )
                                    }
                                    onMouseLeave={handlePopoverClose}
                                    style={{ marginLeft: 5 }}
                                />
                            </Box>
                            <Box className="mini-data price-algo">
                                <img src={AlgoFont} alt="Algo" />
                                <span>
                                    {accountInfo
                                        ? (accountInfo['min-balance'] / 1000000).toFixed(3)
                                        : '-'}
                                </span>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            className="your-balance"
                        >
                            <Box component="span" className="mini-title">
                                Total balance
                            </Box>
                            <Box className="mini-data price-algo">
                                <img src={AlgoFont} alt="Algo" />
                                <span>
                                    {accountInfo
                                        ? (accountInfo['amount'] / 1000000).toFixed(3)
                                        : '-'}
                                </span>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            className="marketplace-fee"
                        >
                            <Box component="span" className="mini-title">
                                Marketplace Fee
                                <i
                                    className="icon-information-outline"
                                    aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={(e) =>
                                        handlePopoverOpen(
                                            e,
                                            MAKE_AN_OFFER_CONFIG.marketplaceFeeDescription,
                                        )
                                    }
                                    onMouseLeave={handlePopoverClose}
                                    style={{ marginLeft: 5 }}
                                />
                            </Box>
                            <Box component="span" className="mini-data">
                                {zestBloomPercent}%
                            </Box>
                        </Box>
                        <form onSubmit={formik.handleSubmit}>
                            <FormikProvider value={formik}>
                                <Box mb={2} className="your-price">
                                    <Box className="price-field">
                                        <FormControl fullWidth variant="outlined">
                                            <Field
                                                field="input"
                                                type="number"
                                                label="Your Price"
                                                name="price"
                                                {...formik.getFieldProps('price')}
                                                onChange={changePrice}
                                            />
                                        </FormControl>
                                        <Box className="price-field-algo">
                                            <img src={AlgoFont} alt="Algo" />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    className="total"
                                >
                                    <Box className="total-title">
                                        Total
                                        <i
                                            className="icon-information-outline"
                                            aria-owns={
                                                openPopover ? 'mouse-over-popover' : undefined
                                            }
                                            aria-haspopup="true"
                                            onMouseEnter={(e) =>
                                                handlePopoverOpen(
                                                    e,
                                                    MAKE_AN_OFFER_CONFIG.totalAmountDescription,
                                                )
                                            }
                                            onMouseLeave={handlePopoverClose}
                                            style={{ marginLeft: 5 }}
                                        />
                                    </Box>
                                    <Box className="total-price price-algo">
                                        <img src={AlgoFont} alt="Algo" />
                                        <span>{totalAmount}</span>
                                    </Box>
                                </Box>
                                <Box
                                    className="asset-mini-content-buttons"
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                >
                                    <Button
                                        variant="text"
                                        className="cancel-btn no-hover"
                                        onClick={goBack}
                                    >
                                        Cancel
                                    </Button>
                                    {loading ? (
                                        <Lottie
                                            animationData={loader}
                                            style={{ height: '50px', width: '153px' }}
                                        />
                                    ) : (
                                        <Button variant="contained" color="primary" type="submit">
                                            Make an offer
                                        </Button>
                                    )}
                                </Box>
                            </FormikProvider>
                        </form>
                    </Box>
                </Box>
            </Container>
            {selectedWallet && (
                <ChangeWallet
                    changeWallet={changeWallet}
                    onCloseChangeWallet={onCloseChangeWallet}
                />
            )}
            <InfoPopUp
                openPopover={openPopover}
                anchorEl={anchorEl}
                handlePopoverClose={handlePopoverClose}
                info={popUpInfo}
            />
        </>
    );
};

export default MakeAnOffer;

function checkAmount(accountInfo, total) {
    const fees = 2000;
    const balance = accountInfo['amount'];
    const minBalance = accountInfo['min-balance'];
    const neededBalance = fees + total * 1000000 + minBalance;
    const result = balance - neededBalance;
    return result >= 0;
}
