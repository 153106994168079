import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { EditOutlined, MoreVert, PublicOutlined } from '@material-ui/icons';

import Lottie from 'lottie-react';
import loader from 'assets/img/lf30_editor_dwdfl6eb.json';

const CardMenu = ({ currentAsset, changeAssetVisibility, node, cardRef }) => {
    const dispatch = useDispatch();
    const [cardMenuEl, setCardMenuEl] = useState(null);
    const [loading, setLoading] = useState(false);

    const openCardMenu = (e) => {
        setCardMenuEl(e.currentTarget);
        if (cardRef) {
            const element = cardRef.current;
            element.classList.add('openCardMenu');
        }
    };
    const closeCardMenu = () => {
        setCardMenuEl(null);
        if (cardRef) {
            const element = cardRef.current;
            element.classList.remove('openCardMenu');
        }
    };
    const changeVisibility = () => {
        if (!loading) {
            const data = {
                visibility: node?.visibility === 'public' ? 'private' : 'public',
            };
            setLoading(true);
            dispatch(changeAssetVisibility(node?.guid, data, currentAsset?.guid)).then((res) => {
                setLoading(false);
            });
        }
    };
    return (
        <>
            <div className="hover-bg-secondary">
                <IconButton
                    disableRipple
                    color="secondary"
                    onClick={openCardMenu}
                    className="no-hover"
                >
                    <MoreVert style={{ fontSize: 24 }} />
                </IconButton>
            </div>
            <Menu
                anchorEl={cardMenuEl}
                keepMounted
                open={Boolean(cardMenuEl)}
                onClose={closeCardMenu}
                className="card-dropdown"
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem disableGutters>
                    <Link to={`/edit/${currentAsset?.id}`}>
                        <EditOutlined style={{ fontSize: 24 }} />
                        <span>Edit Asset</span>
                    </Link>
                </MenuItem>
                <MenuItem disableGutters>
                    <button onClick={changeVisibility}>
                        {loading ? (
                            <Lottie
                                animationData={loader}
                                style={{ height: '24px', width: '24px' }}
                            />
                        ) : (
                            <PublicOutlined style={{ fontSize: 24 }} />
                        )}
                        <span>
                            {node?.visibility === 'public' ? 'Make Private' : 'Make Public'}
                        </span>
                    </button>
                </MenuItem>
                {/* <MenuItem disableGutters>
                    <Link to="/">
                        <PhotoLibraryOutlined style={{ fontSize: 24 }} />
                        <span>Add to Series</span>
                    </Link>
                </MenuItem> */}
            </Menu>
        </>
    );
};

export default CardMenu;
