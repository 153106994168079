import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import Lottie from 'lottie-react';
import loader from 'assets/img/lf30_editor_dwdfl6eb.json';

import { buyWithPrimarySale } from 'transactions/smart-contract/escrow/saleByEscrow/primarySale';
import { buyWithSecondarySale } from 'transactions/smart-contract/escrow/saleByEscrow/secondarySale';
import { useDispatch, useSelector } from 'react-redux';
import { collectAsset, getCollectorMetadata } from 'redux/collectedAssets/actions';
import { getAlgorandAccountInfo } from 'redux/algorand/actions';

const BuyNow = ({ currentAsset, giveNotification, setRealoadAsset, bestSale }) => {
    const [toLoadBuyerProccess, setToLoadBuyerProccess] = useState(false);
    const { selectedWallet } = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    const isHolder = currentAsset?.nodes?.find((node) => node.owner.username === user?.username);

    const canBuy = () => {
        return !isHolder && bestSale;
    };
    const finishBuying = (message, status) => {
        setToLoadBuyerProccess(false);
        giveNotification(status, message);
    };

    const sendCollectedAsset = (response) => {
        dispatch(collectAsset(currentAsset?.base_node?.guid, response)).then((response) => {
            if (response?.status === 201) {
                finishBuying('The Asset was successfully bought', 'success');
                return setRealoadAsset((prev) => prev + 1); // need to reload asset
            }
            return finishBuying('You can not buy a asset', 'error');
        });
    };

    const getAccountInfo = (account) => {
        return dispatch(getAlgorandAccountInfo(account));
    };

    const buyNow = async () => {
        try {
            if (!selectedWallet) {
                giveNotification('info', 'You need to connect your wallet');
                return;
            }
            setToLoadBuyerProccess(true);
            const contract = currentAsset?.base_node?.contracts[0];
            if (!contract) {
                setToLoadBuyerProccess(false);
                giveNotification('info', 'This asset does not have contract');
                return;
            }
            const collectorMetadata = await dispatch(
                getCollectorMetadata({
                    asset: currentAsset?.guid,
                    address: selectedWallet.address,
                }),
            );
            if (currentAsset?.base_node?.user_type === 'collector') {
                await buyWithSecondarySale(
                    selectedWallet.address,
                    contract,
                    currentAsset?.asset?.asset_id,
                    finishBuying,
                    collectorMetadata,
                    getAccountInfo,
                    sendCollectedAsset,
                );
            } else {
                await buyWithPrimarySale(
                    selectedWallet.address,
                    contract,
                    currentAsset?.asset?.asset_id,
                    currentAsset?.asset?.creator_address,
                    finishBuying,
                    collectorMetadata,
                    getAccountInfo,
                    sendCollectedAsset,
                );
            }
        } catch (err) {
            setToLoadBuyerProccess(false);
            console.log(err);
        }
    };

    return (
        <>
            {canBuy() &&
                (toLoadBuyerProccess ? (
                    <Lottie animationData={loader} style={{ height: '50px', width: '153px' }} />
                ) : (
                    <Button variant="contained" color="primary" size="large" onClick={buyNow}>
                        Buy Now
                    </Button>
                ))}
        </>
    );
};

export default BuyNow;
