import React, { useEffect } from 'react';
import { Container, Box } from '@material-ui/core';
import Banner from './Banner';
import Sidebar from './Sidebar';
import ProfileTabs from './ProfileTabs';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ProfileTopMobile from './ProfileTopMobile';
import { getUser } from 'redux/auth/actions';

const Profile = () => {
    const dispatch = useDispatch();
    const { username } = useParams();
    const state = useSelector((state) => state);
    const profileInfo = username ? 'profile' : 'auth';
    const user = state[profileInfo].user;
    const banner = user?.banner;
    const { profileTabesType } = useSelector((state) => state.profile);

    useEffect(() => {
        if (profileInfo === 'auth') {
            dispatch(getUser('me'));
        }
    }, [dispatch, profileInfo]);

    return (
        <>
            <Banner banner={banner} />
            <Container maxWidth="xl">
                <Box display={{ xs: 'block', md: 'none' }}>
                    <ProfileTopMobile username={username} user={user} />
                </Box>
                <div className="profile-content">
                    <Sidebar username={username} user={user} />
                    <ProfileTabs
                        username={username}
                        type={profileTabesType?.type}
                        tabNumber={profileTabesType?.tabNumber}
                    />
                </div>
            </Container>
        </>
    );
};

export default Profile;
