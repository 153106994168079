export const GET_CREATED_ASSETS_REQUEST = 'GET_CREATED_ASSETS_REQUEST';
export const GET_CREATED_ASSETS_SUCCESS = 'GET_CREATED_ASSETS_SUCCESS';
export const GET_CREATED_ASSETS_FAIL = 'GET_CREATED_ASSETS_FAIL';

export const UPDATE_VISIBILITY_CREATED_ASSET_REQUEST = 'UPDATE_VISIBILITY_CREATED_ASSET_REQUEST';
export const UPDATE_VISIBILITY_CREATED_ASSET_SUCCESS = 'UPDATE_VISIBILITY_CREATED_ASSET_SUCCESS';
export const UPDATE_VISIBILITY_CREATED_ASSET_FAIL = 'UPDATE_VISIBILITY_CREATED_ASSET_FAIL';

export const EMPTY_CREATED_ASSETS = 'EMPTY_CREATED_ASSETS';

export const LOAD_MORE_ASSETS_REQUEST = 'LOAD_MORE_ASSETS_REQUEST';
export const LOAD_MORE_ASSETS_SUCCESS = 'LOAD_MORE_ASSETS_SUCCESS';
export const LOAD_MORE_ASSETS_FAIL = 'LOAD_MORE_ASSETS_FAIL';

export const UPVOTING_CREATED_ASSET_SUCCESS = 'UPVOTING_CREATED_ASSET_SUCCESS';
export const UNUPVOTING_CREATED_ASSET_SUCCESS = 'UNUPVOTING_CREATED_ASSET_SUCCESS';
export const TO_FAVORITES_CREATED_SUCCESS = 'TO_FAVORITES_CREATED_SUCCESS';
export const REMOVE_FROM_FAVORITES_CREATED_SUCCESS = 'REMOVE_FROM_FAVORITES_CREATED_SUCCESS';
export const CLEAN_CREATED_ASSETS_SUCCESS = 'CLEAN_CREATED_ASSETS_SUCCESS';
