import algosdk from 'algosdk';

import MyAlgo from '@randlabs/myalgo-connect';

import { getAlgodClient } from 'transactions/algorand';
import { checkAccountAmount, checkOptIn } from 'transactions/algorand/validations';
import { waitForConfirmation } from 'transactions/algorand/waitConfirmation';

export async function createContract(
    contract,
    toActivateContract,
    giveNotification,
    getAccountInfo,
) {
    try {
        console.log('Start creating offer by escroe contract');
        if (!contract) {
            console.log('Does not found teal for contract');
            return giveNotification('error', 'Does not found teal for contract');
        }
        const buyerAddress = contract.teal_context.asset_buyer;
        const assetId = contract.teal_context.asset_id;
        const fund_amount =
            1_000_000 +
            contract?.teal_context?.offer_amount +
            contract?.teal_context?.commission_amount_1_sell;

        const myAlgoWallet = new MyAlgo();
        const algodClient = getAlgodClient();

        const amountForContract = getAmountForContract(fund_amount);
        // wrong calculate
        const accountAmount = await checkAccountAmount(
            buyerAddress,
            amountForContract,
            getAccountInfo,
        );

        if (!accountAmount) {
            return giveNotification(
                'warning',
                'Your account balance is not enough for this transaction.',
            );
        }
        const approvalProgram = new Uint8Array(
            Buffer.from(contract.compiled_teal_result, 'base64'),
        );

        const checkIsOptIn = await checkOptIn(buyerAddress, assetId);

        const params = await algodClient.getTransactionParams().do();
        params.fee = 1000;
        params.flatFee = true;
        const lSig = algosdk.makeLogicSig(approvalProgram);

        if (!checkIsOptIn) {
            const optBuyerTxn = {
                ...params,
                fee: 1000,
                type: 'axfer',
                from: buyerAddress,
                to: buyerAddress,
                assetIndex: assetId,
                amount: 0, // 10Algo
                note: new Uint8Array(Buffer.from('')),
                revocationTarget: undefined,
                closeRemainderTo: undefined,
                appArgs: [],
            };

            const signPoptBuyerTx = await myAlgoWallet.signTransaction(optBuyerTxn);
            const optBuyertx = await algodClient.sendRawTransaction(signPoptBuyerTx.blob).do();
            await waitForConfirmation(algodClient, optBuyertx.txId);
        }

        // Pay 1 algo from buyer to escrow for fee
        const paymentTxnForAsaTxn = {
            ...params,
            fee: 1000,
            flatFee: true,
            type: 'pay',
            from: buyerAddress,
            to: lSig.address(),
            amount: fund_amount,
            note: new Uint8Array(Buffer.from('...')),
        };

        const signPayAsaAmountTx = await myAlgoWallet.signTransaction(paymentTxnForAsaTxn);
        const payForAsa = await algodClient.sendRawTransaction(signPayAsaAmountTx.blob).do();
        await waitForConfirmation(algodClient, payForAsa.txId);

        await toActivateContract(contract.guid);
        giveNotification('success', 'You create offer by escrow contract');
        console.log('Done', payForAsa);
        return { status: 'success' };
    } catch (err) {
        giveNotification('error', err?.message);
        console.log(err);
    }
}

export function getAmountForContract(saleAmount) {
    const fees = 2000;
    const allAmount = fees + saleAmount;
    return allAmount;
}
