import React from 'react';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

const MakeAnOffer = ({ currentAsset }) => {
    const { user } = useSelector((state) => state.auth);
    const isHolder = currentAsset?.nodes?.find((node) => node.owner.username === user?.username);
    const history = useHistory();
    const { assetId } = useParams();

    const bestSale = currentAsset?.base_node?.contracts?.find(
        (contract) => contract.type === 'SaleByEscrow',
    );

    const redirectToMakeAnOffer = () => {
        history.push(`/asset/${assetId}/make-an-offer`);
    };

    const canOffer = () => !isHolder && !bestSale;

    return canOffer() ? (
        <Button variant="contained" color="primary" size="large" onClick={redirectToMakeAnOffer}>
            Make an Offer
        </Button>
    ) : null;
};

export default MakeAnOffer;
