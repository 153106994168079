import React, { useState } from 'react';
import { Button, Box, Avatar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'lottie-react';
import loader from 'assets/img/lf30_editor_dwdfl6eb.json';
import { Link } from 'react-router-dom';
import { algorandBaseUrl } from 'transactions/algorand/index';
import { cancelOldContract } from 'transactions/smart-contract/escrow/saleByEscrow/cancelOldContract';
import { deleteEscrowContract } from 'redux/algorand/actions';
import { deleteContract } from 'redux/singleAsset/actions';
import { setNotification } from 'redux/profile/actions';

const ContractSaleByEscrow = ({ contract }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { selectedWallet } = useSelector((state) => state.profile);

    const giveNotification = (status, message) => {
        dispatch(setNotification({ status, message }));
    };

    const openEscrow = async () => {
        try {
            const a = document.createElement('a');
            a.href = `${algorandBaseUrl}/address/${contract?.compiled_teal_address}`;
            a.target = '_blank';
            a.click();
        } catch (err) {
            console.log(err);
            giveNotification('info', err.message);
        }
    };

    const showResponseOfCanceling = (status, message) => {
        switch (status) {
            case 'none':
                return giveNotification('info', 'This asset does not have contract');
            case 'revoke': {
                return giveNotification('success', 'You have successfully terminated the contract');
            }
            case 'error':
                return giveNotification('error', message);
            default:
                return giveNotification('error', 'Something went wrong');
        }
    };

    const endListing = async () => {
        try {
            if (!selectedWallet) {
                return giveNotification('info', 'You need to connect your wallet');
            }
            if (!contract) {
                return giveNotification('info', 'You do not have contract in this asset');
            }
            const holder =
                contract?.teal_context?.new_seller || contract?.teal_context?.asset_creator;
            if (selectedWallet.address !== holder) {
                return giveNotification(
                    'warning',
                    'Your wallet address does not match the address where this asset is.Please select the correct wallet.',
                );
            }
            setLoading(true);
            const cancelResult = await cancelOldContract(
                contract,
                contract.teal_context.asset_id,
                selectedWallet,
            );

            // send end contract to backend, and hide this buttons

            dispatch(deleteEscrowContract(contract?.guid)).then((response) => {
                if (response?.status === 204) {
                    setLoading(false);
                    dispatch(deleteContract());
                    showResponseOfCanceling(cancelResult?.status);
                }
            });
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    };

    return (
        <Box component="li">
            <Box className="image">
                <Avatar
                    alt={contract?.maker?.username}
                    src={!!contract?.maker?.avatar ? contract?.maker?.avatar : './'}
                    style={{ display: 'flex' }}
                />
            </Box>
            <Box className="info">
                <Box className="title">
                    <Box
                        fontWeight="bold"
                        fontFamily="h1.fontFamily"
                        className="link primary"
                        component={Link}
                        to="/"
                    >
                        Contract Address
                    </Box>{' '}
                    {contract?.compiled_teal_address}
                </Box>

                <Button
                    variant="contained"
                    color="primary"
                    className="bid-btn"
                    onClick={openEscrow}
                >
                    View Contract
                </Button>

                {loading ? (
                    <Lottie
                        animationData={loader}
                        style={{
                            height: '42px',
                            width: '116px',
                            marginLeft: '20px',
                            paddingTop: '12px',
                            display: 'inline-block',
                        }}
                    />
                ) : (
                    <Button
                        variant="outlined"
                        className="bid-btn"
                        style={{ color: 'black', marginLeft: '20px' }}
                        onClick={endListing}
                    >
                        End Listing
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default ContractSaleByEscrow;
