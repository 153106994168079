import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box, Grid, Card, Button } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { Logo } from 'components/shared';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCollectedAssets,
    loadMoreCollectedItems,
    upvoteCollectedAsset,
    unUpvoteCollectedAsset,
    toFavoritesCollectedAssets,
    removeFromFavoritesCollectedAssets,
    changeCollectedAssetVisibility,
    cleanUpCollectedItems,
} from 'redux/collectedAssets/actions';

import AssetCard from '../../components/elements/cards/assetCard';
import Lottie from 'lottie-react';
import loader from 'assets/img/lf30_editor_dwdfl6eb.json';

const ProfileCollected = () => {
    const dispatch = useDispatch();
    const { username } = useParams();
    const { user: authUser } = useSelector((state) => state.auth);

    const { collectedAssets, loadMoreURLCollectedAssets, loadMoreLoading } = useSelector(
        (state) => state.collectedAssets,
    );
    useEffect(() => {
        if (username || authUser?.username) {
            dispatch(getCollectedAssets(username || authUser?.username));
        }
    }, [dispatch, username, authUser]);

    useEffect(() => {
        dispatch(cleanUpCollectedItems());
    }, [dispatch]);

    const loadMore = () => {
        dispatch(loadMoreCollectedItems(loadMoreURLCollectedAssets));
    };

    return (
        <div>
            <Grid
                container
                spacing={3}
                justifyContent={collectedAssets.length === 0 ? 'center' : 'flex-start'}
            >
                <Grid item lg={4} md={6} sm={6} xs={12} mb={2} className="white-card mb-2">
                    <Card className="h-100">
                        <Box
                            mb={1}
                            px={4}
                            py={4}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            textAlign="center"
                            height="100%"
                        >
                            <Box
                                my="auto"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <div className="logo-with-icon">
                                    <Logo type="logoIcon" width="167" />
                                    <span>
                                        <SearchOutlined style={{ fontSize: 42 }} />
                                    </span>
                                </div>
                                <Box
                                    fontSize="1.25rem"
                                    lineHeight="1.5rem"
                                    fontWeight="bold"
                                    fontFamily="h1.fontFamily"
                                    mt={{ xs: 1.5, sm: 3 }}
                                >
                                    Explore to add collections
                                </Box>
                                <Box
                                    fontSize="1rem"
                                    lineHeight="1.5rem"
                                    color="text.black70"
                                    mt={{ xs: 1, sm: 1.5 }}
                                >
                                    See trending items, be the next hero collector
                                </Box>
                            </Box>
                            <Box width="100%" mt={{ xs: 2, sm: 4 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    component={Link}
                                    to="/"
                                >
                                    Explore
                                </Button>
                            </Box>
                        </Box>
                    </Card>
                </Grid>

                {collectedAssets?.map((item) => (
                    <Grid item lg={4} md={6} sm={6} xs={12} key={item?.guid} className="mb-2">
                        <AssetCard
                            tag={item?.media_types.find((x) => x.category === 'static')?.name}
                            favorite={item?.favorite}
                            img={item?.asset?.content_url}
                            thumbnail={item?.asset?.thumbnail?.url}
                            content_type={item?.asset?.content_type}
                            timeLeft={item?.timeLeft}
                            title={item?.title}
                            price={item?.price}
                            numberOfBids={item?.numberOfBids}
                            voted={item?.voted}
                            votes={item?.vote_count}
                            guid={item?.guid}
                            upvoteAsset={upvoteCollectedAsset}
                            unUpvoteAsset={unUpvoteCollectedAsset}
                            toFavoritesAssets={toFavoritesCollectedAssets}
                            removeFromFavoritesAssets={removeFromFavoritesCollectedAssets}
                            changeAssetVisibility={changeCollectedAssetVisibility}
                            isLoggedIn={true}
                            nodes={item?.nodes}
                            bestOffer={item?.base_node}
                            creator={item?.creator}
                            assetId={item?.asset?.asset_id}
                        />
                    </Grid>
                ))}
            </Grid>

            {loadMoreURLCollectedAssets &&
                (loadMoreLoading ? (
                    <Lottie
                        mt={5}
                        animationData={loader}
                        style={{ height: '49px', width: '100%', marginTop: '40px' }}
                    />
                ) : (
                    <Box textAlign="center" mt={5}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            size="large"
                            onClick={loadMore}
                        >
                            Load More
                        </Button>
                    </Box>
                ))}
        </div>
    );
};

export default ProfileCollected;
