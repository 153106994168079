const marketplaceFeeDescription = '15 % for primary sale or 4 % for secondary sale';
const totalAmountDescription =
    'Your offered amount + 15% or 4% fee + 1 Algo (refundable) for contract';

const minRequiredBalanceDescription = `This amount reflects the minimum balance your wallet 
    requires to maintain your ASA's and Contracts`;

export const MAKE_AN_OFFER_CONFIG = {
    marketplaceFeeDescription,
    totalAmountDescription,
    minRequiredBalanceDescription,
};
