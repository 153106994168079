import axios from 'axios';
import { API_CONFIG } from 'configs';

const getFollowers = () => axios.get(`${API_CONFIG.getUser}?type=followers`);
const loadMoreFollowers = (loadMoreURL) => axios.get(loadMoreURL);

const getFollowingUsers = () => axios.get(`${API_CONFIG.getUser}?type=following`);
const loadMoreFollowingUsers = (loadMoreURL) => axios.get(loadMoreURL);

const data = {
    getFollowers,
    loadMoreFollowers,
    getFollowingUsers,
    loadMoreFollowingUsers,
};

export default data;
