import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import Lottie from 'lottie-react';
import loader from 'assets/img/lf30_editor_dwdfl6eb.json';
import { useDispatch } from 'react-redux';
import { deleteOfferByEscrowContract } from 'redux/algorand/actions';
import { deleteOfferFromAsset } from 'redux/singleAsset/actions';
import { closeContract } from 'transactions/smart-contract/escrow/offerByEscrow/closeContract';
import DraftedContractsModal from 'components/elements/modal/draftedContracts';
import { algorandBaseUrl } from 'transactions/algorand/index';

const OfferByEscrowBtns = ({ offerContract, giveNotification, selectedWallet }) => {
    const dispatch = useDispatch();
    const [loadingOpenOffer, setLoadingOpenOffer] = useState(false);
    const [loadingCancelOffer, setLoadingCancelOffer] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const onSubmit = () => {
        setOpenModal(true);
    };

    const onCloseModal = () => {
        setOpenModal(false);
    };

    const draftedOffers = offerContract?.filter((offer) => offer.status === 'DRAFT');

    const activeOffer = offerContract?.find((offer) => offer.status === 'ACTIVE');

    const openEscrow = async () => {
        try {
            setLoadingOpenOffer(true);
            if (!activeOffer) {
                setLoadingOpenOffer(false);
                return giveNotification('info', 'You do not have contract');
            }
            const a = document.createElement('a');
            a.href = `${algorandBaseUrl}/address/${activeOffer?.compiled_teal_address}`;
            a.target = '_blank';
            a.click();
            setLoadingOpenOffer(false);
        } catch (err) {
            setLoadingOpenOffer(false);
            giveNotification('info', err.message);
        }
    };

    const showResponseOfCanceling = (status, message) => {
        switch (status) {
            case 'none':
                return giveNotification('info', 'This asset does not have contract');
            case 'revoke': {
                return giveNotification('success', 'You have successfully terminated the contract');
            }
            case 'error':
                return giveNotification('error', message);
            default:
                return giveNotification('error', 'Something went wrong');
        }
    };

    const deleteContract = async (guid) => {
        return await dispatch(deleteOfferByEscrowContract(guid)).then((res) => {
            dispatch(deleteOfferFromAsset(guid));
        });
    };

    const cancelOffer = async () => {
        if (!activeOffer) {
            return giveNotification('info', 'You do not have contract');
        }
        setLoadingCancelOffer(true);
        const response = await closeContract(activeOffer, deleteContract);
        setLoadingCancelOffer(false);
        showResponseOfCanceling(response?.status, response?.message);
    };

    return (
        <>
            {activeOffer && (
                <Box display="flex" className="escrow-buttons">
                    {loadingOpenOffer ? (
                        <Lottie animationData={loader} style={{ height: '50px', width: '100%' }} />
                    ) : (
                        <Button variant="outlined" onClick={openEscrow} fullWidth>
                            View Offer
                        </Button>
                    )}
                    {loadingCancelOffer ? (
                        <Lottie animationData={loader} style={{ height: '50px', width: '100%' }} />
                    ) : (
                        <Button variant="outlined" onClick={cancelOffer} fullWidth>
                            Cancel Offer
                        </Button>
                    )}
                </Box>
            )}
            {draftedOffers?.length !== 0 && (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={onSubmit}
                        style={{ width: '100%', marginTop: '48px' }}
                    >
                        Drafted contracts
                    </Button>
                    <DraftedContractsModal
                        draftedContracts={draftedOffers}
                        openModal={openModal}
                        onCloseModal={onCloseModal}
                        type={'offer'}
                    />
                </>
            )}
        </>
    );
};
export default OfferByEscrowBtns;
