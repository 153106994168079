export const GET_COLLECTED_ASSETS_REQUEST = 'GET_COLLECTED_ASSETS_REQUEST';
export const GET_COLLECTED_ASSETS_SUCCESS = 'GET_COLLECTED_ASSETS_SUCCESS';
export const GET_COLLECTED_ASSETS_FAIL = 'GET_COLLECTED_ASSETS_FAIL';

export const LOAD_MORE_COLLECTED_ASSETS_REQUEST = 'LOAD_MORE_COLLECTED_ASSETS_REQUEST';
export const LOAD_MORE_COLLECTED_ASSETS_SUCCESS = 'LOAD_MORE_COLLECTED_ASSETS_SUCCESS';
export const LOAD_MORE_COLLECTED_ASSETS_FAIL = 'LOAD_MORE_COLLECTED_ASSETS_FAIL';

export const UPVOTING_COLLECTED_ASSET_SUCCESS = 'UPVOTING_COLLECTED_ASSET_SUCCESS';
export const UNUPVOTING_COLLECTED_ASSET_SUCCESS = 'UNUPVOTING_COLLECTED_ASSET_SUCCESS';
export const TO_FAVORITES_COLLECTED_SUCCESS = 'TO_FAVORITES_COLLECTED_SUCCESS';
export const REMOVE_FROM_FAVORITES_COLLECTED_SUCCESS = 'REMOVE_FROM_FAVORITES_COLLECTED_SUCCESS';

export const UPDATE_VISIBILITY_COLLECTED_ASSET_REQUEST =
    'UPDATE_VISIBILITY_COLLECTED_ASSET_REQUEST';
export const UPDATE_VISIBILITY_COLLECTED_ASSET_SUCCESS =
    'UPDATE_VISIBILITY_COLLECTED_ASSET_SUCCESS';
export const UPDATE_VISIBILITY_COLLECTED_ASSET_FAIL = 'UPDATE_VISIBILITY_COLLECTED_ASSET_FAIL';

export const CLEAN_CREATED_COLLECTED_SUCCESS = 'CLEAN_CREATED_COLLECTED_SUCCESS';
