import * as TYPES from './types.js';

const initialState = {
    collectedAssets: [],
    loadMoreURLCollectedAssets: '',
    loading: false,
    failMessage: {
        error: false,
        message: '',
    },
    visibilityLoading: false,
    loadMoreLoading: false,
};

const collectedAssetsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TYPES.GET_COLLECTED_ASSETS_SUCCESS:
            return {
                ...state,
                collectedAssets: payload.data,
                loadMoreURLCollectedAssets: payload.next,
                loading: false,
            };
        case TYPES.CLEAN_CREATED_COLLECTED_SUCCESS:
            return {
                ...state,
                collectedAssets: [],
                loadMoreURLCollectedAssets: '',
            };
        case TYPES.LOAD_MORE_COLLECTED_ASSETS_REQUEST:
            return {
                ...state,
                collectedAssets: [...state.collectedAssets, ...payload.data],
                loadMoreURLCollectedAssets: payload.next,
                loadMoreLoading: true,
            };
        case TYPES.LOAD_MORE_COLLECTED_ASSETS_SUCCESS:
            return {
                ...state,
                collectedAssets: [...state.collectedAssets, ...payload.data],
                loadMoreURLCollectedAssets: payload.next,
                loadMoreLoading: false,
            };
        case TYPES.LOAD_MORE_COLLECTED_ASSETS_FAIL:
            return {
                ...state,
                collectedAssets: [...state.collectedAssets, ...payload.data],
                loadMoreURLCollectedAssets: payload.next,
                loadMoreLoading: false,
            };
        case TYPES.UPVOTING_COLLECTED_ASSET_SUCCESS:
            return {
                ...state,
                collectedAssets: state.collectedAssets.map((asset) =>
                    asset.guid === payload.guid
                        ? { ...asset, voted: true, vote_count: ++asset.vote_count }
                        : { ...asset },
                ),
                loading: false,
            };
        case TYPES.UNUPVOTING_COLLECTED_ASSET_SUCCESS:
            return {
                ...state,
                collectedAssets: state.collectedAssets.map((asset) =>
                    asset.guid === payload.guid
                        ? { ...asset, voted: false, vote_count: --asset.vote_count }
                        : { ...asset },
                ),
                loading: false,
            };
        case TYPES.TO_FAVORITES_COLLECTED_SUCCESS:
            return {
                ...state,
                collectedAssets: state.collectedAssets.map((asset) =>
                    asset.guid === payload.guid ? { ...asset, favorite: true } : { ...asset },
                ),
                loading: false,
            };
        case TYPES.REMOVE_FROM_FAVORITES_COLLECTED_SUCCESS:
            return {
                ...state,
                collectedAssets: state.collectedAssets.map((asset) =>
                    asset.guid === payload.guid ? { ...asset, favorite: false } : { ...asset },
                ),
                loading: false,
            };
        // case TYPES.UPDATE_VISIBILITY_COLLECTED_ASSET_REQUEST:
        //     return {
        //         ...state,
        //         visibilityLoading: true,
        //     };
        case TYPES.UPDATE_VISIBILITY_COLLECTED_ASSET_SUCCESS:
            return {
                ...state,
                collectedAssets: state.collectedAssets.map((item) =>
                    item.guid === payload.assetGuid ? payload.updatedAsset : item,
                ),
            };
        case TYPES.UPDATE_VISIBILITY_COLLECTED_ASSET_FAIL:
            return {
                ...state,
                visibilityLoading: false,
            };
        default:
            return state;
    }
};

export default collectedAssetsReducer;
