import React from 'react';
import {
    Button,
    Box,
    Typography,
    CardMedia,
    Dialog,
    Link as LinkComponent,
} from '@material-ui/core';
import PeopleMinCard from '../../elements/cards/peopleMinCard';
import { Tag } from '../../shared';
import ShowMoreText from '../../shared/ShowMoreText';
import { Timer } from '@material-ui/icons';
// import SlickSlider from '../../../pages/upload-asset/Slider';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import AlgoFont from '../../../assets/img/algo-font.svg';

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

const BidModal = ({ bidModal, onCloseBidModal, onOpenSubmittedBid }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);

    return (
        <>
            <Dialog
                open={bidModal}
                className="bid-modal"
                onClose={onCloseBidModal}
                scroll="body"
                maxWidth="md"
            >
                <Box className="modal-body">
                    <Box className="left">
                        <Typography variant="h4">Title of Artwork</Typography>
                        {/*Only one image case*/}
                        <Box className="image">
                            <Tag text="img" className="secondary sm top-left rotated" />
                            <CardMedia
                                image="https://via.placeholder.com/600"
                                style={{ height: 300 }}
                                fullWidth
                            />
                        </Box>
                        {/*More then one image, Slider*/}
                        {/*<SlickSlider />*/}

                        <Box mt={2}>
                            <ShowMoreText
                                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam nim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam nim ad minim."
                                max={150}
                            />
                        </Box>
                    </Box>
                    <Box className="right">
                        <Box className="time">
                            <Tag
                                text="05d   23h  43m  left"
                                className="brand-gold md"
                                icon={<Timer style={{ fontSize: 16 }} />}
                            />
                        </Box>
                        <Box display="flex">
                            <Box className="last-bid">
                                <Box component="p">Last Bid</Box>
                                <Box fontSize={20} fontWeight="bold" className="price-algo">
                                    <img src={AlgoFont} alt="Algo" />
                                    <span>109.99</span>
                                </Box>
                            </Box>
                            <Box>
                                <PeopleMinCard
                                    tag="illustrator"
                                    author="@Peach Plum"
                                    authorAvatar="https://via.placeholder.com/150"
                                />
                            </Box>
                        </Box>
                        <Box className="bid-info" component="ul" mt={4} mb={2}>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                component="li"
                            >
                                <Box component="span">Min bid</Box>
                                <Box fontSize={20} fontWeight="bold" className="price-algo">
                                    <img src={AlgoFont} alt="Algo" />
                                    <Box component="b">109.99</Box>
                                </Box>
                            </Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                component="li"
                            >
                                <Box component="span">Your balance</Box>
                                <Box fontSize={20} fontWeight="bold" className="price-algo">
                                    <img src={AlgoFont} alt="Algo" />
                                    <Box component="b">109.99</Box>
                                </Box>
                            </Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                component="li"
                            >
                                <Box component="span" display="flex" alignItems="center">
                                    Marketplace Fee
                                    <i
                                        className="icon-information-outline"
                                        aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={handlePopoverOpen}
                                        onMouseLeave={handlePopoverClose}
                                        style={{ marginLeft: 5 }}
                                    />
                                </Box>
                                <Box component="b">5%</Box>
                            </Box>
                        </Box>
                        <Box className="your-offer" mb={2}>
                            <Box component="p" mb={1}>
                                Your offer
                            </Box>
                            <TextField fullWidth variant="outlined" />
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            mb={3}
                            className="bid-total"
                        >
                            <Box component="span">Total</Box>
                            <Box fontSize={20} fontWeight="bold" className="price-algo">
                                <img src={AlgoFont} alt="Algo" />
                                <Box component="b">109.99</Box>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <Box>
                                <LinkComponent
                                    color="secondary"
                                    onClick={onCloseBidModal}
                                    className="cancel-btn"
                                >
                                    Cancel
                                </LinkComponent>
                            </Box>
                            <Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    size="large"
                                    onClick={onOpenSubmittedBid}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
            <Popover
                className={`${classes.popover} info-popover`}
                classes={{
                    paper: classes.paper,
                }}
                open={openPopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography>
                    Your algo, including the marketplace fee, will be escrowed in the smart contract
                    until your offer is accepted or you withdraw it.
                </Typography>
            </Popover>
        </>
    );
};

export default BidModal;
