import algosdk from 'algosdk';

import { assignGroupId, combineSignedTxs } from '../util/transactions';

import MyAlgo from '@randlabs/myalgo-connect';

import { getAlgodClient } from 'transactions/algorand';
import { checkAccountAmount, checkAssetAmount } from 'transactions/algorand/validations';
import { waitForConfirmation } from 'transactions/algorand/waitConfirmation';

export async function buyWithPrimarySale(
    buyerAddress,
    contract,
    assetId,
    hostAddress,
    finishBuying,
    collectorMetadata,
    getAccountInfo,
    sendCollectedAsset,
) {
    try {
        console.log('Start buying an asset');
        if (!contract) {
            const message = 'This asset does not have contract';
            throw new Error(message);
        }
        const myAlgoWallet = new MyAlgo();
        const algodClient = getAlgodClient();

        const amountForContract = getAmountForBuyingAsset(
            contract.teal_context.sale_amount,
            contract.teal_context.commission_payment,
        );

        // need to chanched
        const accountAmount = await checkAccountAmount(
            buyerAddress,
            amountForContract,
            getAccountInfo,
        );
        if (!accountAmount) {
            const message = 'Your account balance is not enough for this transaction.';
            throw new Error(message);
        }

        const approvalProgram = new Uint8Array(
            Buffer.from(contract.compiled_teal_result, 'base64'),
        );

        const params = await algodClient.getTransactionParams().do();
        params.fee = 1000;
        params.flatFee = true;

        const lSig = algosdk.makeLogicSig(approvalProgram);
        const LSigAddress = lSig.address();
        const assetAmount = await checkAssetAmount(LSigAddress, assetId);
        if (!assetAmount) {
            finishBuying('This asset has already been bought', 'info');
            return;
        }

        // Opt-in buyer in asset
        const optBuyertxn = {
            ...params,
            type: 'axfer',
            from: buyerAddress,
            to: buyerAddress,
            assetIndex: assetId,
            amount: 0, // 1 Algo
            note: new Uint8Array(Buffer.from('')),
            revocationTarget: undefined,
            closeRemainderTo: undefined,
            appArgs: [],
        };

        const rawSignedTxn = await myAlgoWallet.signTransaction(optBuyertxn);
        let opttx = await algodClient.sendRawTransaction(rawSignedTxn.blob).do();

        // wait for transaction to be confirmed
        await waitForConfirmation(algodClient, opttx.txId);

        const metadataCollectorHash = collectorMetadata.data.IpfsHash;
        const noteData = {
            metadataurl: `ipfs://${metadataCollectorHash}`,
            'specs-version': '0.1',
            'specs-data': [],
        };

        const enc = new TextEncoder();
        const note = enc.encode(JSON.stringify(noteData));
        // Start payments
        // Send asset from escrow to buyer
        const transferASA = {
            ...params,
            type: 'axfer',
            from: LSigAddress,
            to: buyerAddress,
            assetIndex: assetId,
            amount: 1, //contract.asset_amount,
            note: note,
        };

        // Transfer payment from buyer to seller
        const paymentTxnForAsa = {
            ...params,
            fee: 1000,
            flatFee: true,
            type: 'pay',
            from: buyerAddress,
            to: hostAddress,
            amount: contract.teal_context.sale_amount,
            note: new Uint8Array(Buffer.from('...')),
        };

        // Transfer payment from buyer to zestBloom (4%)
        const paymentTxnToZestbloomFee = {
            ...params,
            fee: 1000,
            flatFee: true,
            type: 'pay',
            from: buyerAddress,
            to: contract.teal_context.zestbloom_account,
            amount: contract.teal_context.commission_payment,
            note: new Uint8Array(Buffer.from('...')),
        };

        // Create group
        const txnGroup = assignGroupId([transferASA, paymentTxnForAsa, paymentTxnToZestbloomFee]);

        // create transaction object for logicalSignature
        const txObjASA = new algosdk.Transaction(txnGroup[0]);
        txObjASA.group = txnGroup[0].group;

        // sign lSig
        const signedASA = algosdk.signLogicSigTransaction(txObjASA, lSig);
        // Sign payment transactions
        const signedTxn = await myAlgoWallet.signTransaction([txnGroup[1], txnGroup[2]]);
        // combine transactions
        const combinedTxs = combineSignedTxs([signedASA, ...signedTxn]);

        // send raw transaction
        const response = await algodClient.sendRawTransaction(combinedTxs).do();
        await waitForConfirmation(algodClient, response.txId);
        const collecterData = {
            tx_id: signedASA.txID,
            buyer_address: buyerAddress,
            contract_address: LSigAddress,
        };

        // closeout assetTransaction
        const closeEscrowTxn = {
            ...params,
            fee: 1000,
            type: 'axfer',
            from: LSigAddress,
            to: LSigAddress,
            assetIndex: assetId,
            amount: 0, // 1 Algo
            note: new Uint8Array(Buffer.from('')),
            revocationTarget: undefined,
            closeRemainderTo: hostAddress,
            appArgs: [],
        };

        const txObj = new algosdk.Transaction(closeEscrowTxn);
        const signEscrowTx = algosdk.signLogicSigTransactionObject(txObj, lSig);
        const optEscrowtx = await algodClient.sendRawTransaction(signEscrowTx.blob).do();
        await waitForConfirmation(algodClient, optEscrowtx.txId);

        //closeout transaction
        const txnCloseOutTxn = {
            ...params,
            from: LSigAddress,
            to: hostAddress,
            type: 'pay',
            amount: 0,
            note: new Uint8Array(Buffer.from('...')),
            closeRemainderTo: hostAddress,
        };

        const txCloseOutTxnObj = new algosdk.Transaction(txnCloseOutTxn);
        const signEscrowCloseOutTx = algosdk.signLogicSigTransactionObject(txCloseOutTxnObj, lSig);
        const optEscrowCloseOuttx = await algodClient
            .sendRawTransaction(signEscrowCloseOutTx.blob)
            .do();
        await waitForConfirmation(algodClient, optEscrowCloseOuttx.txId);

        console.log('CloseOut');
        sendCollectedAsset(collecterData);
    } catch (err) {
        finishBuying(err.message, 'error');
        return { status: 400 };
    }
}

function getAmountForBuyingAsset(amount_to_pay_for_nft, zestbloom_percent_amount) {
    const payments = amount_to_pay_for_nft + zestbloom_percent_amount;
    const fees = 4000;
    const amountForContract = 1000000;
    const allAmount = payments + fees + amountForContract;
    return allAmount;
}
