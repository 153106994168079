import React from 'react';
import { Avatar, Box } from '@material-ui/core';
import { Tag } from '../../shared';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { needToLoginAction } from 'redux/auth/actions';

const PeopleMinCard = ({ author, authorAvatar, tags }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { isLoggedIn } = useSelector((state) => state.auth);

    const openSignIn = () => dispatch(needToLoginAction(true));

    const onSubmit = () => {
        if (isLoggedIn) {
            if (user.username === author) {
                return history.push('/profile');
            } else {
                return history.push(`/profile/${author}`);
            }
        }
        return openSignIn();
    };
    return (
        <Box
            className="people-min-card"
            display="flex"
            alignItems="flex-start"
            onClick={onSubmit}
            onMouseOver={(e) => {
                e.target.style.cursor = 'pointer';
            }}
        >
            <div className="min-avatar circle">
                <Avatar alt={author} src={!!authorAvatar ? authorAvatar : './'} />
            </div>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Box>
                    {author && (
                        <Box
                            fontSize="1rem"
                            fontWeight="bold"
                            fontFamily="h1.fontFamily"
                            className="link primary"
                        >
                            @{author}
                        </Box>
                    )}
                </Box>
                {tags?.find((tag) => tag.type === 'static') && (
                    <Box className="tag-body">
                        <Tag
                            text={tags?.find((tag) => tag.type === 'static')?.name}
                            className="secondary sm top-right"
                        />
                    </Box>
                )}
                {tags?.find((tag) => tag.type === 'custom') && (
                    <Box className="tag-body">
                        <Tag
                            text={tags?.find((tag) => tag.type === 'custom')?.name}
                            className="secondary sm top-right"
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default PeopleMinCard;
