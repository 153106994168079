import React, { useEffect, useState } from 'react';
import { Box, Typography, Link } from '@material-ui/core';
import MyAlgo from '@randlabs/myalgo-connect';
import { setMyAlgoAccount, dissconnectMyAlgo } from 'redux/profile/actions';
import { useDispatch } from 'react-redux';
import { Logo } from '.';
import algorandLogo from 'assets/img/algorand-logo.svg';
import { getAlgorandAccountInfo } from 'redux/algorand/actions';
import WalletInfoModal from '../elements/modal/miniWalletInfoModal';
import { useHistory } from 'react-router';

const WalletCard = ({ id, label, connected, address }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const myAlgoWallet = new MyAlgo();
    const [accountInfo, setAccountInfo] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const onSubmit = async (e) => {
        try {
            e.stopPropagation();
            if (!connected) {
                const accounts = await myAlgoWallet.connect();
                dispatch(setMyAlgoAccount(accounts));
            } else {
                dispatch(dissconnectMyAlgo(address));
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        let update = true;
        if (address) {
            dispatch(getAlgorandAccountInfo(address))
                .then((response) => {
                    if (update) {
                        setAccountInfo(response?.account);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        return () => {
            update = false;
        };
    }, [dispatch, address]);

    const openInfo = async () => {
        if (connected) {
            setOpenModal(true);
        }
    };

    const onCloseModal = () => {
        setOpenModal(false);
    };
    return (
        <>
            <Box
                className="wallet-card"
                key={id}
                style={{ cursor: connected ? 'pointer' : 'default' }}
                component="li"
                mx={0}
                onClick={openInfo}
            >
                {connected ? (
                    <img src={algorandLogo} alt="Algorand" style={{ margin: '21px auto' }} />
                ) : (
                    <Logo type="logoIconGray" width="53" />
                )}

                <Typography variant="body2" component="strong" className="name">
                    {label}
                </Typography>
                <Link
                    to={history.location.pathname}
                    color={connected ? 'error' : 'primary'}
                    onClick={onSubmit}
                >
                    {connected ? 'Disconnect' : 'Connect'}
                </Link>
            </Box>
            <WalletInfoModal open={openModal} close={onCloseModal} info={accountInfo} />
        </>
    );
};

export default WalletCard;
