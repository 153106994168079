import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography, Box, Container, Grid } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import SeeMoreBtn from '../../components/shared/SeeMoreBtn';
import PeopleCard from '../../components/elements/cards/peopleCardFollowers';
import { getFeaturedArtists, moveMarketplaceFromFeatured } from 'redux/marketplace/actions';

const FeaturedArtists = () => {
    const dispatch = useDispatch();
    const { featuredArtist } = useSelector((state) => state.marketplace);

    useEffect(() => {
        const sorted = '?sort_by=most_followed&page_size=3';
        dispatch(getFeaturedArtists(sorted));
    }, [dispatch]);

    const showAllPeoples = () => {
        dispatch(moveMarketplaceFromFeatured(true));
    };
    return (
        <div className="home-featured-artists">
            <Container maxWidth="xl">
                <Box mb={4} textAlign="center">
                    <Typography variant="h2">Featured Artists</Typography>
                </Box>

                <Grid container spacing={3} className="list-with-link">
                    {featuredArtist?.map((item) => (
                        <Grid item lg={4} md={4} sm={6} xs={12} key={item?.username}>
                            <PeopleCard
                                key={item?.username}
                                tags={item?.selected_tags}
                                img={item?.banner}
                                author={item?.username}
                                firstName={item?.first_name}
                                lastName={item?.last_name}
                                authorAvatar={item?.avatar}
                                authorName={item?.authorName}
                                bio={item?.bio}
                            />
                        </Grid>
                    ))}

                    {/*Desktop "see more" button (blue round with arrow)*/}
                    <Box display={{ xs: 'none', sm: 'block' }}>
                        <Link to="/marketplace" className="list-link" onClick={showAllPeoples}>
                            <ChevronRight style={{ fontSize: 56 }} />
                        </Link>
                    </Box>

                    {/*Mobile "see more" button*/}
                    <Box
                        display={{ xs: 'flex', sm: 'none' }}
                        justifyContent="center"
                        style={{ width: '100%' }}
                    >
                        <SeeMoreBtn />
                    </Box>
                </Grid>
            </Container>
        </div>
    );
};

export default FeaturedArtists;
