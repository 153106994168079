import * as TYPES from './types.js';
import AssetsServise from './services';
import axios from 'axios';

export const startUpload = () => ({ type: TYPES.START_UPLOAD });
export const finishUpload = () => ({ type: TYPES.FINISH_UPLOAD });

export const addAssets = (data) => (dispatch) => {
    return AssetsServise.addAssets(data)
        .then((response) => {
            return response;
        })
        .catch((err) => {
            if (err.response.data.retry) {
                const originalRequest = err.config;
                return setTimeout(() => {
                    return axios(originalRequest)
                        .then((x) => {})
                        .catch((error) => {
                            console.log(error);
                        });
                }, 2000);
            }
            return err.response;
        });
};

export const sendAssetIds = (accounts) => (dispatch) => {
    dispatch({ type: TYPES.SEND_ASSETS_IDS_REQUEST });
    return AssetsServise.sendAssetIds(accounts)
        .then((response) => {
            if (response.status === 201) {
                return dispatch({ type: TYPES.SEND_ASSETS_IDS_SUCCESS });
            }
            return dispatch({ type: TYPES.SEND_ASSETS_IDS_FAIL, payload: { error: '' } });
        })
        .catch((err) => {
            console.log(err);
            return dispatch({ type: TYPES.SEND_ASSETS_IDS_FAIL, payload: { error: '' } });
        });
};
