import * as TYPES from './types.js';
import ProfileService from './services';
import AuthService from '../auth/services';
import { getUser } from '../auth/actions';
import { v4 as uuid } from 'uuid';

export const empty_user = () => ({ type: TYPES.EMPTY_USER });

export const setNotification = (notification) => {
    const guid = uuid();
    return {
        type: TYPES.SET_NOTIFICATION_SUCCESS,
        payload: { notification: { ...notification, guid } },
    };
};

export const deleteNotification = (guid) => ({
    type: TYPES.DELETE_NOTIFICATION_SUCCESS,
    payload: { guid },
});

export const toClearWallet = () => ({ type: TYPES.SET_MY_ALGO_ACCOUNT_SUCCESS, payload: [] });
export const setMyAlgoAccount = (accounts) => (dispatch) => {
    return ProfileService.setWalletAccounts(accounts)
        .then((response) => {
            if (response.status === 201) {
                dispatch({ type: TYPES.SET_MY_ALGO_ACCOUNT_SUCCESS, payload: accounts });
                return accounts;
            }
        })
        .catch((err) => {
            console.log(err);
            return [];
        });
};

export const dissconnectMyAlgo = (address) => ({
    type: TYPES.DISSCONNECT_MY_ALGO,
    payload: { address },
});

export const changeBanner = (data) => (dispatch) => {
    return ProfileService.changeBanner(data).then(
        (response) => {
            if (response) {
                dispatch(getUser(data.username));
                dispatch({
                    type: TYPES.CHANGE_BANNER_SUCCESS,
                });
                return response.data;
            }
            return Promise.reject();
        },
        (error) => {
            dispatch({
                type: TYPES.CHANGE_BANNER_FAIL,
            });
            return Promise.reject();
        },
    );
};

export const getProfileUser = (username) => (dispatch) => {
    return AuthService.getUser(username)
        .then((response) => {
            if (response) {
                dispatch({
                    type: TYPES.PUBLIC_USER_SUCCESS,
                    payload: response.data,
                });
                return response.data;
            }
            return Promise.reject();
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject();
        });
};

export const followUser = (username) => (dispatch) => {
    return ProfileService.followUser(username)
        .then((response) => {
            if (response.status === 201) {
                dispatch(getFollowing());
                return dispatch({
                    type: TYPES.FOLLOW_USER,
                    payload: username,
                });
            }
            return Promise.reject();
        })
        .catch((e) => console.error(e));
};

export const unFollowUser = (username) => (dispatch) => {
    return ProfileService.unFollowUser(username)
        .then((response) => {
            if (response.status === 204) {
                dispatch(getFollowing());
                return dispatch({
                    type: TYPES.UNFOLLOW_USER,
                    payload: username,
                });
            }
            return Promise.reject();
        })
        .catch((e) => console.error(e));
};

export const getFollowing = () => (dispatch) => {
    return ProfileService.getFollows('following')
        .then((response) => {
            return response.data;
        })
        .then((data) => {
            dispatch({
                type: TYPES.GET_FOLLOWING,
                payload: data,
            });
        })
        .catch((e) => console.error(e));
};

export const becomeCreator = (artworks_url) => (dispatch) => {
    dispatch({ type: TYPES.CHANGE_IS_BECOME_A_CREATOR_REQUEST });
    return ProfileService.becomeCreator(artworks_url)
        .then((response) => {
            if (response.status === 201) {
                return dispatch({
                    type: TYPES.CHANGE_IS_BECOME_A_CREATOR_SUCCESS,
                    payload: true,
                });
            }
            return dispatch({ type: TYPES.CHANGE_IS_BECOME_A_CREATOR_FAIL });
        })
        .catch((e) => {
            return dispatch({ type: TYPES.CHANGE_IS_BECOME_A_CREATOR_FAIL });
        });
};

export const getPdfFile = (staticUrl) => (dispatch) => {
    dispatch({ type: TYPES.GET_PDF_FILE_REQUEST });
    return ProfileService.getPdfFile(staticUrl)
        .then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: TYPES.GET_PDF_FILE_SUCCESS,
                    payload: response?.data?.file,
                });
                return { status: 200, data: response?.data };
            }
            dispatch({ type: TYPES.GET_PDF_FILE_FAIL });
            return { status: 400 };
        })
        .catch((e) => {
            dispatch({ type: TYPES.GET_PDF_FILE_FAIL });
            return { status: 400 };
        });
};

export const changeProfileTabesType = (payload) => ({
    type: TYPES.CHOOSE_PROFILE_TABES_TYPE,
    payload,
});

export const selectWallet = (address) => ({
    type: TYPES.SELECTED_WALLET_SUCCESS,
    payload: { address },
});
