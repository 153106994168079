import * as TYPES from './types.js';

const initialState = {
    users: [],
    loadMoreUsersURL: null,
    marketplaceAssets: [],
    loadMoreMarkURL: null,
    assetsTags: [],
    assetsStaticTags: [],
    peopleTags: [],
    failMessage: {
        error: false,
        message: '',
    },
    loading: false,
    peopleFromFooter: { viewType: '', sort: '' },
    searchFromHomePage: '',
    filterByTagFromSide: null,
    updateVisibility: 0,
    visibilityLoading: false,
    loadMoreLoading: false,
    featuredArtist: [],
    fromFeaturedArtist: false,
};

const profileSettingsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TYPES.GET_USERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TYPES.GET_USERS_SUCCESS:
            return {
                ...state,
                users: payload.data,
                loadMoreUsersURL: payload.next,
                loading: false,
            };
        case TYPES.GET_USERS_FAIL:
            return {
                ...state,
                failMessage: {
                    error: true,
                    message: payload.messagere,
                },
                loading: false,
            };
        case TYPES.LOAD_MORE_USERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TYPES.LOAD_MORE_USERS_SUCCESS:
            return {
                ...state,
                users: [...state.users, ...payload.data],
                loadMoreUsersURL: payload.next,
                loading: false,
            };
        case TYPES.LOAD_MORE_USERS_FAIL:
            return {
                ...state,
                failMessage: {
                    error: true,
                    message: payload.messagere,
                },
                loading: false,
            };
        case TYPES.GET_MARKETPLACE_ASSETS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TYPES.GET_MARKETPLACE_ASSETS_SUCCESS:
            return {
                ...state,
                marketplaceAssets: payload.data,
                loadMoreMarkURL: payload.next,
                loading: false,
            };
        case TYPES.GET_MARKETPLACE_ASSETS_FAIL:
            return {
                ...state,
                failMessage: {
                    error: true,
                    message: payload.messagere,
                },
                loading: false,
            };
        // case TYPES.CHANGE_VISIBILITY_MARKETPLACE_ASSET_REQUEST: {
        //     return {
        //         ...state,
        //         visibilityLoading: true,
        //     };
        // }
        case TYPES.CHANGE_VISIBILITY_MARKETPLACE_ASSET_SUCCESS: {
            return {
                ...state,
                updateVisibility: state.updateVisibility + 1,
            };
        }
        case TYPES.CHANGE_VISIBILITY_MARKETPLACE_ASSET_FAIL: {
            return {
                ...state,
                visibilityLoading: false,
            };
        }
        case TYPES.LOAD_MORE_MARKETPLACE_ASSETS_REQUEST:
            return {
                ...state,
                loadMoreLoading: true,
            };
        case TYPES.LOAD_MORE_MARKETPLACE_ASSETS_SUCCESS:
            return {
                ...state,
                marketplaceAssets: [...state.marketplaceAssets, ...payload.data],
                loadMoreMarkURL: payload.next,
                loadMoreLoading: false,
            };
        case TYPES.LOAD_MORE_MARKETPLACE_ASSETS_FAIL:
            return {
                ...state,
                failMessage: {
                    error: true,
                    message: payload.messagere,
                },
                loadMoreLoading: false,
            };
        case TYPES.UPVOTING_ASSET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TYPES.UPVOTING_ASSET_SUCCESS:
            return {
                ...state,
                marketplaceAssets: state.marketplaceAssets.map((asset) =>
                    asset.guid === payload.guid
                        ? { ...asset, voted: true, vote_count: ++asset.vote_count }
                        : { ...asset },
                ),
                loading: false,
            };
        case TYPES.UPVOTING_ASSET_FAIL:
            return {
                ...state,
                failMessage: {
                    error: true,
                    message: payload.messagere,
                },
                loading: false,
            };
        case TYPES.UNUPVOTING_ASSET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TYPES.UNUPVOTING_ASSET_SUCCESS:
            return {
                ...state,
                marketplaceAssets: state.marketplaceAssets.map((asset) =>
                    asset.guid === payload.guid
                        ? { ...asset, voted: false, vote_count: --asset.vote_count }
                        : { ...asset },
                ),
                loading: false,
            };
        case TYPES.UNUPVOTING_ASSET_FAIL:
            return {
                ...state,
                failMessage: {
                    error: true,
                    message: payload.messagere,
                },
                loading: false,
            };
        case TYPES.TO_FAVORITES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TYPES.TO_FAVORITES_SUCCESS:
            return {
                ...state,
                marketplaceAssets: state.marketplaceAssets.map((asset) =>
                    asset.guid === payload.guid ? { ...asset, favorite: true } : { ...asset },
                ),
                loading: false,
            };
        case TYPES.TO_FAVORITES_FAIL:
            return {
                ...state,
                failMessage: {
                    error: true,
                    message: payload.messagere,
                },
                loading: false,
            };
        case TYPES.REMOVE_FROM_FAVORITES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TYPES.REMOVE_FROM_FAVORITES_SUCCESS:
            return {
                ...state,
                marketplaceAssets: state.marketplaceAssets.map((asset) =>
                    asset.guid === payload.guid ? { ...asset, favorite: false } : { ...asset },
                ),
                loading: false,
            };
        case TYPES.REMOVE_FROM_FAVORITES_FAIL:
            return {
                ...state,
                failMessage: {
                    error: true,
                    message: payload.messagere,
                },
                loading: false,
            };
        case TYPES.GET_ASSETS_TAGS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TYPES.GET_ASSETS_TAGS_SUCCESS:
            return {
                ...state,
                assetsTags: payload,
                loading: true,
            };
        case TYPES.GET_ASSETS_TAGS_FAIL:
            return {
                ...state,
                failMessage: {
                    error: true,
                    message: payload.messagere,
                },
                loading: false,
            };
        case TYPES.GET_ASSETS_STATIC_TAGS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TYPES.GET_ASSETS_STATIC_TAGS_SUCCESS:
            return {
                ...state,
                assetsStaticTags: payload,
                loading: false,
            };
        case TYPES.GET_ASSETS_STATIC_TAGS_FAIL:
            return {
                ...state,
                failMessage: {
                    error: true,
                    message: payload.messagere,
                },
                loading: false,
            };
        case TYPES.ADD_ASSETS_TAGS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TYPES.ADD_ASSETS_TAGS_SUCCESS:
            return {
                ...state,
                assetsTags: [...state.assetsTags, payload],
                loading: false,
            };
        case TYPES.ADD_ASSETS_TAGS_FAIL:
            return {
                ...state,
                failMessage: {
                    error: true,
                    message: payload.messagere,
                },
                loading: false,
            };
        case TYPES.GET_PEOPLE_TAGS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TYPES.GET_PEOPLE_TAGS_SUCCESS:
            return {
                ...state,
                peopleTags: payload,
                loading: false,
            };
        case TYPES.GET_PEOPLE_TAGS_FAIL:
            return {
                ...state,
                failMessage: {
                    error: true,
                    message: payload.messagere,
                },
                loading: false,
            };
        case TYPES.SEARCH_PEOPLE_FROM_FOOTER_SUCCESS:
            return {
                ...state,
                peopleFromFooter: payload,
            };
        case TYPES.SET_VALUE_SEARCH_FROM_HOME_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TYPES.SET_VALUE_SEARCH_FROM_HOME_SUCCESS:
            return {
                ...state,
                searchFromHomePage: payload,
                loading: false,
            };
        case TYPES.SET_VALUE_SEARCH_FROM_HOME_FAIL:
            return {
                ...state,
                failMessage: {
                    error: true,
                    message: payload.message,
                },
                loading: false,
            };
        case TYPES.SET_FILTER_BY_TAG_FROM_HOME:
            return {
                ...state,
                filterByTagFromSide: payload,
            };
        case TYPES.GET_FEATURED_ARTISTS_SUCCESS:
            return {
                ...state,
                featuredArtist: payload.data,
            };
        case TYPES.FROM_FEATURED_ARTIST_SUCCES:
            return {
                ...state,
                fromFeaturedArtist: payload.bool,
            };
        default:
            return state;
    }
};

export default profileSettingsReducer;
