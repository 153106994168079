import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';

import AssetCard from 'components/elements/cards/assetCard';

import { getFilteredAssetElements } from '../util';

import { getAssetsForMarketPlace } from 'redux/marketplace/actions';

import {
    upvoteAsset,
    unUpvoteAsset,
    toFavoritesAssets,
    removeFromFavoritesAssets,
    changeMarketplaceAssetVisibility,
} from 'redux/marketplace/actions';

const Assets = ({ searchAssets, filterObj, sortAssets, filterAssetsByTag }) => {
    const dispatch = useDispatch();
    const { marketplaceAssets, updateVisibility } = useSelector((state) => state.marketplace);
    const { isLoggedIn } = useSelector((state) => state.auth);

    const getAssets = useCallback(() => {
        const filterPath = getFilteredAssetElements(
            filterObj,
            sortAssets,
            filterAssetsByTag,
            searchAssets,
        );

        dispatch(getAssetsForMarketPlace(filterPath));
    }, [dispatch, filterObj, sortAssets, filterAssetsByTag, searchAssets]);

    useEffect(() => {
        getAssets();
    }, [getAssets, isLoggedIn, updateVisibility]);

    return marketplaceAssets?.map((item) => (
        <Grid item lg={3} md={4} sm={6} xs={12} key={item.guid}>
            <AssetCard
                tag={item?.media_types?.find((x) => x.category === 'static')?.name}
                favorite={item?.favorite}
                img={item?.asset?.content_url}
                thumbnail={item?.asset?.thumbnail?.url}
                content_type={item?.asset?.content_type}
                timeLeft={item?.timeLeft}
                title={item?.title}
                price={item?.price}
                numberOfBids={item?.numberOfBids}
                voted={item?.voted}
                votes={item?.vote_count}
                guid={item?.guid}
                upvoteAsset={upvoteAsset}
                unUpvoteAsset={unUpvoteAsset}
                toFavoritesAssets={toFavoritesAssets}
                removeFromFavoritesAssets={removeFromFavoritesAssets}
                isLoggedIn={isLoggedIn}
                changeAssetVisibility={changeMarketplaceAssetVisibility}
                nodes={item?.nodes}
                bestOffer={item?.base_node}
                creator={item?.creator}
                assetId={item?.asset?.asset_id}
            />
        </Grid>
    ));
};

export default Assets;
