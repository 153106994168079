const apiUrl = window.__RUNTIME_CONFIG__.REACT_APP_API_URL;

export const API_CONFIG = {
    signup: `${apiUrl}/api/account/signup/`,
    verifySignup: `${apiUrl}/api/account/signup/verify/`,
    login: `${apiUrl}/api/account/login/`,
    loginRefresh: `${apiUrl}/api/account/login/refresh/`,
    forgotPassword: `${apiUrl}/api/account/password/reset/`,
    verifyForgotPassword: `${apiUrl}/api/account/password/reset/verify/`,
    verifiedForgotPassword: `${apiUrl}/api/account/password/reset/verified/`,
    logout: `${apiUrl}/api/account/logout/`,
    getUser: `${apiUrl}/api/account/profiles/`,
    updateProfile: `${apiUrl}/api/account/profiles/`,
    follow: (username) => `${apiUrl}/api/account/profiles/${username}/follow/`,
    changeEmail: `${apiUrl}/api/account/email/change/`,
    verifyEmail: `${apiUrl}/api/account/email/change/verify/`,
    changePassword: `${apiUrl}/api/account/password/change/`,
    addCustomTag: `${apiUrl}/api/account/tags/`,
    selectTag: `${apiUrl}/api/account/tags/`,
    getTags: `${apiUrl}/api/account/tags/`,
    getFollows: `${apiUrl}/api/account/profiles/`,
    becomeCreator: `${apiUrl}/api/account/become-creator/`,
    setWalletAccounts: `${apiUrl}/api/wallet/connect/`,
    getPdfFile: `${apiUrl}/api/static/`,
};
