import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { setFilterByTagFromSide } from 'redux/marketplace/actions';
import {
    getCurrentAsset,
    getAssetsWithSameTag,
    upvoteAssetWithSameTag,
    unUpvoteAssetWithSameTag,
    toFavoriteAssetWithSameTag,
    removeFromFavoritesAssetWithSameTag,
    changeAssetVisibility,
    emptyCurrentAsset,
    getActivities,
} from 'redux/singleAsset/actions';
import { setValueBestVoted } from 'redux/bestVotedAssets/actions';
import PeopleMinCard from '../../../components/elements/cards/peopleMinCard';
import ShowMoreText from '../../../components/shared/ShowMoreText';
import PreviewTabs from '../../upload-asset/PreviewTabs';
import ReportAnIssueModal from '../../../components/elements/modal/reportAnIssue';
import AssetCard from '../../../components/elements/cards/assetCard';
import SeeMoreBtn from '../../../components/shared/SeeMoreBtn';

import { Box, Typography, Container, Link as LinkComponent, Grid } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

import CardMenu from 'components/elements/cards/cardMenu';
import AssetImage from '../assetImage';
import Upvote from './upvote';
import ToFavorite from './favorite';
import SaleByEcrowBtns from './contractBtns/saleByEscrowcontractBtns';
import OfferByEscrowBtns from './contractBtns/offerByEscrowBtns';
import PriceAndActions from './actionsWithAsset';
import { algorandBaseUrl } from 'transactions/algorand/index';
import { setNotification } from 'redux/profile/actions';

// import TopBidsCard from '../../../components/elements/cards/TopBidsCard';
// import CancelAuctionModal from '../../../components/elements/modal/cancelAuction';
// import CancelBidModal from '../../../components/elements/modal/cancelBid';
// import Popover from '@material-ui/core/Popover';
// import BidDoneModal from 'components/elements/modal/bidDone';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//     popover: {
//         pointerEvents: 'none',
//     },
//     paper: {
//         padding: theme.spacing(1),
//     },
// }));

const Asset = () => {
    const dispatch = useDispatch();
    const { assetId } = useParams();
    const [reportIssue, setReportIssue] = useState(false);
    const [reloadAsset, setRealoadAsset] = useState(0);
    const { currentAsset, assetsWithSameTag, visibilityLoading, activities } = useSelector(
        (state) => state.singleAsset,
    );
    const { user: authUser, isLoggedIn } = useSelector((state) => state.auth);
    const { selectedWallet } = useSelector((state) => state.profile);
    const staticTag = currentAsset?.media_types?.find((tag) => tag.category === 'static');
    // For Auction
    // const [cancelAuction, setCancelAuction] = useState(false);
    // const [cancelBid, setCancelBid] = useState(false);
    // const [anchorEl, setAnchorEl] = useState(null);
    // const openPopover = Boolean(anchorEl);
    // const classes = useStyles();

    // For bid modal
    // const [submittedBid, setSubmittedBid] = useState(false);

    useEffect(() => {
        if (assetId) {
            dispatch(getCurrentAsset(assetId));
        }
        return () => {
            dispatch(emptyCurrentAsset());
        };
    }, [assetId, dispatch, reloadAsset]);

    useEffect(() => {
        if (staticTag) {
            dispatch(getAssetsWithSameTag(staticTag.slug));
        }
    }, [staticTag, dispatch]);

    useEffect(() => {
        if (Object.keys(currentAsset).length !== 0) {
            dispatch(getActivities(currentAsset?.guid));
        }
    }, [dispatch, currentAsset]);

    const createdByMe = () =>
        authUser?.username && authUser?.username === currentAsset?.creator?.username;

    const currentUserHaveAsset = currentAsset?.nodes?.find(
        (node) => node?.owner?.username === authUser?.username,
    );

    const canEdit = currentUserHaveAsset?.quantity > 0;

    const canFollowAndVoted =
        isLoggedIn && authUser?.username !== currentUserHaveAsset?.creator?.username;

    const holderedByMe = () => currentUserHaveAsset?.quantity > 0;

    // For Bid modal
    // const onOpenSubmittedBid = () => {
    //     setSubmittedBid(true);
    // };
    // const onCloseSubmittedBid = () => {
    //     setSubmittedBid(false);
    // };

    // Auction
    // const handlePopoverOpen = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handlePopoverClose = () => {
    //     setAnchorEl(null);
    // };
    // const onOpenCancelAuction = () => {
    //     setCancelAuction(true);
    // };
    // const onCloseCancelAuction = () => {
    //     setCancelAuction(false);
    // };
    // const onOpenCancelBid = () => {
    //     setCancelBid(true);
    // };
    // const onCloseCancelBid = () => {
    //     setCancelBid(false);
    // };

    const onOpenReportIssue = () => {
        setReportIssue(true);
    };
    const onCloseReportIssue = () => {
        setReportIssue(false);
    };

    const redirectToMarketplace = () => {
        dispatch(setValueBestVoted(true));
        if (staticTag) {
            dispatch(setFilterByTagFromSide(staticTag.slug));
        }
    };

    if (!currentAsset) return null;

    const giveNotification = (status, message) => {
        dispatch(setNotification({ status, message }));
    };

    const collectors = currentAsset?.nodes?.filter((x) => x.user_type !== 'creator');
    const holderCollector = collectors?.find((x) => x.quantity !== 0);

    const currenContract = currentAsset?.base_node?.contracts;

    const offerContract = currentAsset?.offers?.filter(
        (offer) => offer.maker.username === authUser?.username,
    );
    return (
        <>
            <Box className="single-asset">
                <Container maxWidth="xl" className="single-asset-container">
                    <Box display="flex" className="asset">
                        <Box className="asset-left">
                            <AssetImage staticTag={staticTag} currentAsset={currentAsset} />
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                mt={3}
                                className="asset-left-bottom"
                            >
                                <Box>
                                    View asset information in{' '}
                                    <LinkComponent
                                        href={`${algorandBaseUrl}/asset/${currentAsset?.asset?.asset_id}`}
                                        color="primary"
                                        target="_blank"
                                    >
                                        AlgoExplorer
                                    </LinkComponent>
                                </Box>
                                <button
                                    className="report-btn hover-opacity"
                                    onClick={onOpenReportIssue}
                                >
                                    <i className="icon-exclamation-triangle" />
                                    Report an Issue
                                </button>
                            </Box>
                            {/* Escrow Status */}
                            {holderedByMe() && currenContract && currenContract.length !== 0 && (
                                <SaleByEcrowBtns
                                    currentAsset={currentAsset}
                                    giveNotification={giveNotification}
                                    selectedWallet={selectedWallet}
                                />
                            )}
                            {offerContract && (
                                <OfferByEscrowBtns
                                    offerContract={offerContract}
                                    giveNotification={giveNotification}
                                    selectedWallet={selectedWallet}
                                    setRealoadAsset={setRealoadAsset}
                                />
                            )}
                        </Box>
                        <Box className="asset-right">
                            <Box className="asset-right-top">
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    className="top-head"
                                >
                                    <Typography variant="h4">{currentAsset?.title}</Typography>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        // className="card-header-actions"
                                    >
                                        {canEdit && (
                                            <Box
                                                border={1}
                                                borderColor="text.black20"
                                                borderRadius={10}
                                                bgcolor="text.white"
                                                overflow="hidden"
                                                height={48}
                                                width={48}
                                                ml={1.5}
                                                className="top-action"
                                            >
                                                <CardMenu
                                                    currentAsset={{
                                                        id: currentAsset?.asset?.asset_id,
                                                        guid: currentAsset?.guid,
                                                    }}
                                                    changeAssetVisibility={changeAssetVisibility}
                                                    node={currentUserHaveAsset}
                                                    loading={visibilityLoading}
                                                />
                                            </Box>
                                        )}
                                        {/* share information */}
                                        {/* <button className="share-btn hover-opacity">
                                            <i className="icon-share-2" style={{ fontSize: 24 }} />
                                        </button> */}
                                    </Box>
                                </Box>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    mb={2.5}
                                    className="like-fav"
                                >
                                    <Upvote
                                        currentAsset={currentAsset}
                                        canFollowAndVoted={canFollowAndVoted}
                                    />
                                    {!createdByMe() && Object.keys(currentAsset).length !== 0 && (
                                        <ToFavorite
                                            currentAsset={currentAsset}
                                            canFollowAndVoted={canFollowAndVoted}
                                        />
                                    )}
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    className="creator-collector"
                                >
                                    <Box>
                                        <Typography variant="h6">Creator</Typography>
                                        <PeopleMinCard
                                            tags={currentAsset?.creator?.selected_tags}
                                            author={currentAsset?.creator?.username}
                                            authorAvatar={currentAsset?.creator?.avatar}
                                        />
                                    </Box>
                                    {holderCollector && (
                                        <Box>
                                            <Typography variant="h6">
                                                {currentAsset?.auction
                                                    ? 'Auctioned by '
                                                    : 'Collector'}
                                            </Typography>
                                            {/* {currentAsset?.holders.map((user) => ( */}
                                            <PeopleMinCard
                                                key={holderCollector?.owner?.username}
                                                tags={holderCollector?.owner?.selected_tags}
                                                author={holderCollector?.owner?.username}
                                                authorAvatar={holderCollector?.owner?.avatar}
                                            />
                                            {/* ))} */}
                                        </Box>
                                    )}
                                </Box>
                                {/*Buttons*/}
                                {/* {createdByMe() &&
                                    (currentAsset?.auction ? (
                                        <Box display="flex" alignItems="center" mt={2}>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                size="large"
                                                onClick={onOpenCancelAuction}
                                            >
                                                Cancel Auction
                                            </Button>
                                        </Box>
                                    ) : (
                                        <Box mt={2}>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                size="large"
                                            >
                                                Make an Auction
                                            </Button>
                                        </Box>
                                    ))} */}
                                {/* <Box display="flex" alignItems="center" mt={2}>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        size="large"
                                        onClick={onOpenCancelBid}
                                    >
                                        Cancel Bid
                                    </Button>
                                    <i
                                        className="icon-information-outline"
                                        aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={handlePopoverOpen}
                                        onMouseLeave={handlePopoverClose}
                                        style={{ marginLeft: 5, color: 'rgba(0, 0, 0, 0.5)' }}
                                    />
                                </Box> */}

                                {/* {currentAsset?.base_node && holderedByMe() && (
                                    <Box
                                        fontSize={20}
                                        mt={2.5}
                                        fontWeight="bold"
                                        className="price-algo"
                                    >
                                        <img src={AlgoFont} alt="Algo" />
                                        <Box className="bid-price">
                                            {currentAsset?.base_node?.price_is_visible &&
                                            currenContract?.length !== 0 ? (
                                                `${Number(currentAsset?.base_node?.price)?.toFixed(
                                                    3,
                                                )}`
                                            ) : (
                                                <Box component="p">No Price</Box>
                                            )}
                                        </Box>
                                    </Box>
                                )} */}
                            </Box>
                            <PriceAndActions
                                currentAsset={currentAsset}
                                giveNotification={giveNotification}
                                setRealoadAsset={setRealoadAsset}
                            />
                            <Box className="asset-right-bottom">
                                <ShowMoreText
                                    text={
                                        currentAsset?.description ? currentAsset?.description : ''
                                    }
                                    max={150}
                                />
                                {/*Tabs*/}
                                <PreviewTabs collectors={collectors} activities={activities} />
                            </Box>
                        </Box>
                    </Box>
                </Container>
                <Container maxWidth="xl">
                    <Box className="more-items">
                        <Typography variant="h3" style={{ marginBottom: 24 }}>
                            More Items
                        </Typography>
                        {assetsWithSameTag.length !== 0 && (
                            <Grid container spacing={3} className="list-with-link">
                                {assetsWithSameTag?.map((item) => (
                                    <Grid item lg={3} md={6} sm={6} xs={12} key={item?.guid}>
                                        <AssetCard
                                            tag={
                                                item?.media_types?.find(
                                                    (x) => x.category === 'static',
                                                )?.name
                                            }
                                            favorite={item?.favorite}
                                            img={item?.asset?.content_url}
                                            thumbnail={item?.asset?.thumbnail?.url}
                                            content_type={item?.asset?.content_type}
                                            timeLeft={item?.timeLeft}
                                            title={item?.title}
                                            price={item?.price}
                                            numberOfBids={item?.numberOfBids}
                                            voted={item?.voted}
                                            votes={item?.vote_count}
                                            guid={item?.guid}
                                            upvoteAsset={upvoteAssetWithSameTag}
                                            unUpvoteAsset={unUpvoteAssetWithSameTag}
                                            toFavoritesAssets={toFavoriteAssetWithSameTag}
                                            removeFromFavoritesAssets={
                                                removeFromFavoritesAssetWithSameTag
                                            }
                                            isLoggedIn={isLoggedIn}
                                            nodes={item?.nodes}
                                            bestOffer={item?.base_node}
                                            creator={item?.creator}
                                            assetId={item?.asset?.asset_id}
                                        />
                                    </Grid>
                                ))}
                                <Box display={{ xs: 'none', sm: 'block' }}>
                                    <Link
                                        to="/marketplace"
                                        className="list-link"
                                        onClick={redirectToMarketplace}
                                    >
                                        <ChevronRight style={{ fontSize: 56 }} />
                                    </Link>
                                </Box>

                                {/*Mobile "see more" button*/}
                                <Box
                                    display={{ xs: 'flex', sm: 'none' }}
                                    justifyContent="center"
                                    style={{ width: '100%' }}
                                >
                                    <SeeMoreBtn />
                                </Box>
                            </Grid>
                        )}
                    </Box>
                </Container>
            </Box>
            {/*Popups*/}
            {/* <CancelAuctionModal
                cancelAuction={cancelAuction}
                onCloseCancelAuction={onCloseCancelAuction}
            />
            <CancelBidModal cancelBid={cancelBid} onCloseCancelBid={onCloseCancelBid} /> */}
            <ReportAnIssueModal
                reportIssue={reportIssue}
                onCloseReportIssue={onCloseReportIssue}
                assetId={currentAsset?.guid}
                giveNotification={giveNotification}
            />
            {/*Popover, for canceling auction   */}
            {/* <Popover
                className={`${classes.popover} info-popover`}
                classes={{
                    paper: classes.paper,
                }}
                open={openPopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography>By canceling the auction you will be charged a fine of $5.</Typography>
            </Popover> */}
            {/* <BidDoneModal submittedBid={submittedBid} onCloseSubmittedBid={onCloseSubmittedBid} /> */}
        </>
    );
};

export default Asset;
