import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addAssets, startUpload, finishUpload } from 'redux/asset/actions';
import {
    getManagerAddress,
    getIPFSHash,
    getAlgorandAccountInfo,
    setContract,
} from 'redux/algorand/actions';
import {
    Typography,
    DialogContent,
    Dialog,
    Box,
    Button,
    RadioGroup,
    Radio,
    FormControlLabel,
    Avatar,
    Link as LinkComponent,
} from '@material-ui/core';
import { ThumbUpOutlined, StarBorderOutlined } from '@material-ui/icons';

import { Tag } from 'components/shared';
import PreviewTabs from './PreviewTabs';
import SlickSlider from './Slider';
import ShowMoreText from '../../components/shared/ShowMoreText';

import loader from 'assets/img/lf30_editor_dwdfl6eb.json';
import Lottie from 'lottie-react';
import { createEscrowContract } from 'transactions/smart-contract/escrow/saleByEscrow/createContract';
import { createAsset } from 'transactions/createAssets';
import AlgoFont from '../../assets/img/algo-font.svg';
import { setNotification } from 'redux/profile/actions';

const AssetPreview = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { previewDialog, onClosePreviewDialog, formValues, file } = props;

    const { selectedWallet } = useSelector((state) => state.profile);
    const user = useSelector((state) => state.auth.user);
    const { loading } = useSelector((state) => state.assets);
    const { zestBloomManagerAddress } = useSelector((state) => state.algorand);

    const [visibility, setVisibility] = useState('private');

    const handleChange = (event) => {
        setVisibility(event.target.value);
    };

    const giveNotification = (status, message) => {
        dispatch(setNotification({ status, message }));
    };
    const getErrorMessage = (message, status = 'error') => {
        giveNotification(status, message);
        dispatch(finishUpload());
    };

    useEffect(() => {
        dispatch(getManagerAddress());
    }, [dispatch]);

    const getAccountInfo = (account) => {
        return dispatch(getAlgorandAccountInfo(account));
    };
    const sendCreatedContract = async (guid) => {
        return await dispatch(setContract(guid));
    };
    const createContract = async (account, assetid, contract) => {
        try {
            const response = await createEscrowContract(
                account,
                contract,
                assetid,
                getAccountInfo,
                sendCreatedContract,
            );
            dispatch(finishUpload());
            if (response.status === 'success') {
                giveNotification('success', 'The contract was created successfully!');
            } else {
                giveNotification('error', response.message);
                // getErrorMessage(response.message);
            }
            history.push('/profile');
        } catch (err) {
            getErrorMessage('You can not create smart contract');
            // history.push('/profile');
        }
    };

    const gettingIPFSHash = async (data) => {
        return await dispatch(getIPFSHash(data));
    };

    const addAssetToDB = (assetsData, guid, account, assetid, createEscroeBySaleContract) => {
        try {
            setTimeout(() => {
                dispatch(addAssets(JSON.stringify(assetsData))).then((response) => {
                    if (response.status === 201) {
                        if (createEscroeBySaleContract) {
                            giveNotification(
                                'success',
                                'The assets was uploaded successfully! Start creating smart contract',
                            );
                            console.log('The asset was uploaded!');
                            const contract = response?.data?.contract;
                            createContract(account, assetid, contract);
                        } else {
                            dispatch(finishUpload());
                            giveNotification('success', 'The assets was uploaded successfully!');
                            history.push('/profile');
                        }
                    } else {
                        giveNotification('error', 'The asset was no uploaded');
                        dispatch(finishUpload());
                    }
                });
            }, 2000);
        } catch (err) {
            getErrorMessage('You can not upload an asset');
            console.log(err);
        }
    };

    const addToBlockchain = async () => {
        try {
            if (!selectedWallet) {
                giveNotification('info', 'You need to connect your wallet');
                return;
            }
            dispatch(startUpload());
            const account = selectedWallet;
            const args = {
                file,
                gettingIPFSHash,
                formValues,
                user,
                account,
                visibility,
                addAssetToDB,
                getErrorMessage,
                zestBloomManagerAddress,
                getAccountInfo,
            };
            createAsset(args);
        } catch (error) {
            giveNotification('error', error?.message);
            dispatch(finishUpload());
            console.log(error);
        }
    };
    return (
        <Dialog
            open={previewDialog}
            onClose={onClosePreviewDialog}
            scroll="body"
            maxWidth="lg"
            className="asset-preview"
            aria-labelledby="max-width-dialog-title"
        >
            <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                className="preview-meta"
            >
                <RadioGroup
                    aria-label="state"
                    name="state"
                    value={visibility}
                    className="radio-group"
                    onChange={handleChange}
                >
                    <FormControlLabel
                        value="private"
                        control={<Radio color="primary" />}
                        label="Private"
                    />
                    <FormControlLabel
                        value="public"
                        control={<Radio color="primary" />}
                        label="Public"
                    />
                </RadioGroup>

                {loading ? (
                    <Lottie animationData={loader} style={{ height: '50px', width: '153px' }} />
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="large"
                        onClick={addToBlockchain}
                    >
                        Publish
                    </Button>
                )}
            </Box>
            <DialogContent style={{ padding: 0 }}>
                <Box display="flex" className="asset-preview-content">
                    <Box className="preview-slider">
                        <SlickSlider
                            isSeries={formValues.isSeries}
                            files={formValues.isSeries ? formValues.filesOfSeries : [file]}
                        />
                    </Box>
                    <Box className="preview-description">
                        <Typography variant="h4">{formValues.title}</Typography>
                        <Box display="flex" alignItems="center" mt={3}>
                            <Box
                                fontWeight="bold"
                                display="flex"
                                fontSize={16}
                                alignItems="center"
                                color="text.black50"
                            >
                                <ThumbUpOutlined
                                    style={{ fontSize: 24 }}
                                    className="pointer hover-opacity"
                                />
                                <Box component="span" ml={1}>
                                    0
                                </Box>
                            </Box>
                            <Box
                                fontWeight="bold"
                                color="text.black50"
                                display="flex"
                                ml={3}
                                alignItems="center"
                            >
                                <StarBorderOutlined
                                    style={{ fontSize: 24 }}
                                    className="pointer hover-opacity"
                                />
                                <Box component="span" ml={1}>
                                    0
                                </Box>
                            </Box>
                        </Box>
                        <Box color="text.black50" size={16} fontWeight="bold" mt={4}>
                            Creator
                        </Box>
                        <Box fontSize={16} display="flex" alignItems="center" mt={1}>
                            <Avatar alt={user?.first_name} src={user?.avatar} className="md" />
                            <Box pl={2}>
                                <Box
                                    display="block"
                                    fontWeight="bold"
                                    fontFamily="h1.fontFamily"
                                    ml={1}
                                    className="link primary ellipsis"
                                    component={LinkComponent}
                                    to="/"
                                    pb={1}
                                >
                                    @{user?.username}
                                </Box>
                                <Tag text="Illustrator" className="secondary sm relative" />
                            </Box>
                        </Box>
                        <Box
                            fontSize={20}
                            pb={1}
                            mt={5}
                            mb={2}
                            fontWeight="bold"
                            className="price-algo"
                        >
                            {!!Number(formValues.price) && (
                                <>
                                    <img src={AlgoFont} alt="Algo" />
                                    <Box
                                        fontSize={32}
                                        lineHeight={1.25}
                                        display="block"
                                        fontWeight="bold"
                                        fontFamily="fontFamily"
                                    >
                                        {Number(formValues.price).toFixed(3)}
                                    </Box>
                                </>
                            )}
                        </Box>
                        <ShowMoreText text={formValues.description} max={150} />
                        <Box>
                            <PreviewTabs />
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default AssetPreview;
