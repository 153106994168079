export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const VERIFY_SIGNUP_SUCCESS = 'VERIFY_SIGNUP_SUCCESS';
export const VERIFY_SIGNUP_FAIL = 'VERIFY_SIGNUP_FAIL';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_REFRESH_SUCCESS = 'LOGIN_REFRESH_SUCCESS';
export const LOGIN_REFRESH_FAIL = 'LOGIN_REFRESH_FAIL';

export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const VERIFY_FORGOT_PASSWORD_SUCCESS = 'VERIFY_FORGOT_PASSWORD_SUCCESS';
export const VERIFY_FORGOT_PASSWORD_FAIL = 'VERIFY_FORGOT_PASSWORD_FAIL';
export const VERIFIED_FORGOT_PASSWORD_SUCCESS = 'VERIFIED_FORGOT_PASSWORD_SUCCESS';
export const VERIFIED_FORGOT_PASSWORD_FAIL = 'VERIFIED_FORGOT_PASSWORD_FAIL';

export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAIL = 'VERIFY_EMAIL_FAIL';

export const LOGOUT = 'LOGOUT';

export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const CHANGE_USER_AVATAR_SUCCESS = 'CHANGE_USER_AVATAR_SUCCESS';

export const NEED_TO_LOGIN = 'NEED_TO_LOGIN';
