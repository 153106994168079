import axios from 'axios';
import { API_CONFIG } from 'configs';

// axios.interceptors.request.use(
//     function (config) {
//         const accessToken = localStorage.getItem('access');
//         if (accessToken) {
//             config.headers['Authorization'] = `Bearer ${accessToken}`;
//             config.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
//         }
//         // Do something before request is sent
//         return config;
//     },
//     function (error) {
//         // Do something with request error
//         return Promise.reject(error);
//     },
// );

const changeBanner = (data) => {
    const formData = new FormData();
    formData.append('banner', data.banner);
    return axios.patch(`${API_CONFIG.updateProfile}${data.username}/`, formData);
};

const followUser = (username) => axios.post(API_CONFIG.follow(username));

const unFollowUser = (username) => axios.delete(API_CONFIG.follow(username));

const getFollows = (follow) =>
    axios.get(API_CONFIG.getFollows, {
        params: {
            type: follow,
        },
    });

const becomeCreator = (artworks_url) => {
    return axios.post(API_CONFIG.becomeCreator, {
        artworks_url,
    });
};

const setWalletAccounts = (accounts) =>
    axios.post(API_CONFIG.setWalletAccounts, JSON.stringify(accounts));

const getPdfFile = (staticUrl) => axios.get(`${API_CONFIG.getPdfFile}${staticUrl}/`);

const data = {
    changeBanner,
    followUser,
    unFollowUser,
    getFollows,
    becomeCreator,
    setWalletAccounts,
    getPdfFile,
};

export default data;
