import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import MakeAnOfferModal from 'components/elements/modal/offers';

const OfferedItems = ({ currentAsset, setRealoadAsset }) => {
    const { user } = useSelector((state) => state.auth);
    const [openModal, setOpenModal] = useState(false);

    const isHolder = currentAsset?.nodes?.find(
        (node) => node.owner.username === user?.username && node.quantity !== 0,
    );

    if (currentAsset?.offers?.length === 0) {
        return null;
    }

    if (!isHolder) return null;

    const onSubmit = () => {
        setOpenModal(true);
    };

    const onCloseModal = () => {
        setOpenModal(false);
    };
    return (
        <>
            {isHolder ? (
                <>
                    <Button variant="contained" color="primary" size="large" onClick={onSubmit}>
                        Offers
                    </Button>
                    <MakeAnOfferModal
                        openModal={openModal}
                        onCloseModal={onCloseModal}
                        offersForMe={currentAsset?.offers}
                        nodeGuid={isHolder.guid}
                        setRealoadAsset={setRealoadAsset}
                    />
                </>
            ) : null}
        </>
    );
};

export default OfferedItems;
