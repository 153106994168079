import React, { useRef } from 'react';
import {
    Box,
    Dialog,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
} from '@material-ui/core';
import algoLogo from '../../../assets/img/algorand-logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { selectWallet } from 'redux/profile/actions';

const ChangeWallet = ({ changeWallet, onCloseChangeWallet }) => {
    const dispatch = useDispatch();
    const { selectedWallet, myAlgoAccounts } = useSelector((state) => state.profile);
    const defaultValue = useRef(selectedWallet?.address);

    const handleChange = (e) => {
        dispatch(selectWallet(e.target.value));
    };
    return (
        <>
            <Dialog
                open={changeWallet}
                onClose={onCloseChangeWallet}
                scroll="body"
                maxWidth="xs"
                className="change-wallet-modal"
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    className="change-wallet-modal-head"
                >
                    <Typography className="title">Select Wallet</Typography>
                    <img src={algoLogo} alt="Algorand" />
                </Box>
                <Box className="change-wallet-modal-body">
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="gender"
                            name="radio-buttons-group"
                            onChange={handleChange}
                            defaultValue={defaultValue.current}
                            value={selectedWallet?.address}
                        >
                            {myAlgoAccounts?.map((wallet) => (
                                <FormControlLabel
                                    className="radio-label"
                                    value={wallet.address}
                                    control={<Radio className="radio-button" />}
                                    label={wallet.name}
                                    key={wallet.address}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Dialog>
        </>
    );
};

export default ChangeWallet;
