import React from 'react';
import { Typography, Box, Card, CardMedia, Avatar } from '@material-ui/core';
import { Tag } from 'components/shared';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { needToLoginAction } from 'redux/auth/actions';
import defaultBg from 'assets/img/defaultBg.png';

const PeopleCard = ({ tags, img, authorName, authorAvatar, author, lastName, firstName, bio }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { isLoggedIn } = useSelector((state) => state.auth);

    const openSignIn = () => dispatch(needToLoginAction(true));

    const onSubmit = () => {
        if (isLoggedIn) {
            if (user.username === author) {
                return history.push('/profile');
            } else {
                return history.push(`/profile/${author}`);
            }
        }
        openSignIn();
    };
    return (
        <Card
            className="h-100"
            onClick={onSubmit}
            onMouseOver={(e) => {
                e.target.style.cursor = 'pointer';
            }}
        >
            <Box className="featured-artist-img-wrap">
                <Box className="featured-artist-tags">
                    {tags?.find((tag) => tag.type === 'static') && (
                        <Tag
                            text={tags?.find((tag) => tag.type === 'static')?.name}
                            className="secondary sm relative"
                        />
                    )}
                    {tags?.find((tag) => tag.type === 'custom') && (
                        <Tag
                            text={tags?.find((tag) => tag.type === 'custom')?.name}
                            className="secondary sm relative"
                        />
                    )}
                </Box>
                <Box style={{ borderRadius: '10px 10px 0 0' }} overflow="hidden">
                    <CardMedia image={img || defaultBg} style={{ height: 162 }} />
                </Box>
                <div className="featured-artist-img circle">
                    <Avatar alt={authorName} src={authorAvatar} className="xl" />
                </div>
            </Box>

            <Box pb={4} px={3} display="flex" justifyContent="space-between">
                <Box>
                    <Typography gutterBottom variant="h5">
                        {firstName} {lastName}
                    </Typography>

                    <Box
                        fontSize={16}
                        fontWeight="bold"
                        fontFamily="h1.fontFamily"
                        className="link primary ellipsis"
                    >
                        @{author}
                    </Box>
                </Box>
            </Box>
            {/* </Box> */}
            <div className="card-bio">
                <Typography style={{ lineHeight: '1.5rem' }}>
                    {bio.length > 120 ? `${bio?.substring(0, 120)} ...` : bio}
                </Typography>
            </div>
        </Card>
    );
};

export default PeopleCard;
