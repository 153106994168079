import * as ROUTES from './routes.js';
import {
    Home,
    Profile,
    ProfileSettings,
    UploadAsset,
    Marketplace,
    Auction,
    // PostAuction,
    ProfileActivity,
    Asset,
    AssetEdit,
    Faq,
    MakeAnOffer,
} from 'pages';
import { NewPassword } from 'components/elements';

export const PROTECTED_ROUTE_CONFIG = [
    {
        title: 'Profile',
        path: ROUTES.PROFILE,
        exact: true,
        isPublic: false,
        hasSearch: false,
        component: Profile,
    },
    {
        title: 'Profile Settings',
        path: ROUTES.SETTINGS,
        exact: true,
        isPublic: false,
        hasSearch: false,
        component: ProfileSettings,
    },
    {
        title: 'Profile Activity',
        path: ROUTES.ACTIVITY,
        exact: true,
        isPublic: false,
        hasSearch: false,
        component: ProfileActivity,
    },
    {
        title: 'Profile',
        path: ROUTES.USER_PROFILE,
        exact: true,
        isPublic: false,
        hasSearch: false,
        component: Profile,
    },

    {
        title: 'Upload Asset',
        path: ROUTES.UPLOADASSET,
        exact: true,
        isPublic: true,
        hasSearch: false,
        component: UploadAsset,
    },
    {
        title: 'Auction',
        path: ROUTES.AUCTION,
        exact: true,
        isPublic: false,
        hasSearch: false,
        component: Auction,
    },
    {
        title: 'Asset',
        path: ROUTES.ASSET,
        exact: true,
        isPublic: false,
        hasSearch: false,
        component: Asset,
    },
    // {
    //     title: 'Post Auction',
    //     path: ROUTES.POST_AUCTION,
    //     exact: true,
    //     isPublic: false,
    //     hasSearch: false,
    //     component: PostAuction,
    // },
    {
        title: 'Edit Asset',
        path: ROUTES.EDIT_ASSET,
        exact: true,
        isPublic: false,
        hasSearch: false,
        component: AssetEdit,
    },
    {
        title: 'Make an offer',
        path: ROUTES.MAKE_AN_OFFER,
        exact: true,
        isPublic: false,
        hasSearch: false,
        component: MakeAnOffer,
    },
];
export const PUBLIC_ROUTE_CONFIG = [
    {
        title: 'Homepage',
        path: ROUTES.HOME,
        exact: true,
        isPublic: true,
        hasSearch: true,
        component: Home,
    },
    {
        title: 'Marketplace',
        path: ROUTES.MARKETPLACE,
        exact: true,
        isPublic: false,
        hasSearch: false,
        component: Marketplace,
    },
    {
        title: 'Create New Password',
        path: ROUTES.CREATE_NEW_PASSWORD,
        exact: true,
        isPublic: true,
        hasSearch: false,
        component: NewPassword,
    },
    {
        title: 'Faq',
        path: ROUTES.FAQ,
        exact: true,
        isPublic: false,
        hasSearch: false,
        component: Faq,
    },
];
