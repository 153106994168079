import { createTheme } from '@material-ui/core/styles';
import { toRem } from 'helpers/functions';

export const THEME_CONFIG = createTheme({
    palette: {
        primary: {
            main: '#485AFD',
            dark: '#3a48cb',
        },
        secondary: {
            main: '#000',
        },
        error: {
            main: '#EC3136',
            light: '#EB7A7D',
        },
        background: {
            default: '#F6F6F6',
        },
        text: {
            black: '#000',
            black20: 'rgba(0,0,0,0.2)',
            black50: 'rgba(0,0,0,0.5)',
            black70: 'rgba(0,0,0,0.7)',
            white: '#fff',
        },
    },
    typography: {
        fontFamily: ['Roboto', 'sans-serif'].join(', '),
        fontSize: 16,
        h1: {
            fontFamily: ['Raleway', 'sans-serif'].join(', '),
            color: '#000',
            fontWeight: 900,
            fontSize: toRem(60),
            lineHeight: toRem(70),
            textTransform: 'uppercase',
        },
        h2: {
            fontFamily: ['Raleway', 'sans-serif'].join(', '),
            color: '#000',
            fontWeight: 900,
            fontSize: toRem(40),
            lineHeight: toRem(47),
            textTransform: 'uppercase',
        },
        h3: {
            fontFamily: ['Raleway', 'sans-serif'].join(', '),
            color: '#000',
            fontWeight: 900,
            fontSize: toRem(32),
            lineHeight: toRem(38),
            textTransform: 'uppercase',
        },
        h4: {
            fontFamily: ['Raleway', 'sans-serif'].join(', '),
            color: '#000',
            fontWeight: 700,
            fontSize: toRem(24),
            lineHeight: toRem(28),
        },
        h5: {
            fontFamily: ['Raleway', 'sans-serif'].join(', '),
            color: '#000',
            fontWeight: 700,
            fontSize: toRem(20),
            lineHeight: toRem(23),
        },
        h6: {
            fontFamily: ['Raleway', 'sans-serif'].join(', '),
            color: '#000',
            fontWeight: 800,
            fontSize: toRem(18),
            lineHeight: toRem(27),
        },
        button: {
            fontFamily: ['Raleway', 'sans-serif'].join(', '),
            fontWeight: 700,
            fontSize: toRem(18),
            lineHeight: toRem(21),
            textTransform: 'none',
            boxShadow: 'none !important',
        },
        body1: {
            fontFamily: ['Raleway', 'sans-serif'].join(', '),
            color: '#000',
            fontWeight: 500,
            fontSize: toRem(15),
            lineHeight: toRem(17),
        },
        body2: {
            fontFamily: ['Raleway', 'sans-serif'].join(', '),
            color: '#000',
            fontWeight: 600,
            fontSize: toRem(15),
            lineHeight: toRem(17),
        },
    },
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1486,
            xxl: 1920,
        },
    },
    mixins: {
        toolbar: {
            minHeight: 88,
        },
    },
    shape: {
        borderRadius: 10,
    },
    shadows: [
        'none',
        '0px 24px 60px rgba(38, 43, 63, 0.1)',
        '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
        '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
        '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
        '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
        '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
        '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
        '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
        '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
        '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
        '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
        '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
        '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
        '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
        '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
        '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
        '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
        '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
        '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
        '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
        '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
        '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
        '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
        '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
    ],
});
