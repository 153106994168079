import algosdk from 'algosdk';

import { getAlgodClient } from 'transactions/algorand';
import { getAccountInfo } from 'transactions/algorand/getAccount';
import { waitForConfirmation } from 'transactions/algorand/waitConfirmation';

export const closeContract = async (contract, deleteContract) => {
    try {
        console.log('Start canceling');
        if (!contract) {
            return { status: 'none' };
        }
        const buyer_address = contract?.teal_context?.asset_buyer;

        const accountInfo = await getAccountInfo(contract.compiled_teal_address);

        const amount = accountInfo?.account?.amount;

        if (!amount || amount < 1000) {
            console.log('Deleted');
            await deleteContract(contract?.guid);
            return { status: 'revoke' };
        }

        const algodClient = getAlgodClient();
        const approvalProgram = new Uint8Array(
            Buffer.from(contract.compiled_teal_result, 'base64'),
        );

        const params = await algodClient.getTransactionParams().do();
        params.fee = 1000;
        params.flatFee = true;

        const lSig = algosdk.makeLogicSig(approvalProgram);
        const LSigAddress = lSig.address();
        // For voucher id // skipped
        // const voucher_asset_id = contract?.teal_context?.voucher_asset_id;
        // const txnCloseOutAsset = {
        //     ...params,
        //     from: LSigAddress,
        //     to: account.address,
        //     assetIndex: voucher_asset_id,
        //     type: 'axfer',
        //     amount: 0,
        //     note: new Uint8Array(Buffer.from('...')),
        //     closeRemainderTo: account.address,
        // };

        // const txnGroup = assignGroupId([txnCloseOutAsset, txnPayment]);

        // const txObj = new algosdk.Transaction(txnCloseOutAsset);
        // const signEscrowTx = algosdk.signLogicSigTransactionObject(txObj, lSig);

        // const optEscrowtx = await algodClient.sendRawTransaction(txObj.blob).do();

        const txnCloseOutTxn = {
            ...params,
            from: LSigAddress,
            to: buyer_address,
            type: 'pay',
            amount: 0,
            note: new Uint8Array(Buffer.from('...')),
            closeRemainderTo: buyer_address,
        };

        const txCloseOutTxnObj = new algosdk.Transaction(txnCloseOutTxn);
        const signEscrowCloseOutTx = algosdk.signLogicSigTransactionObject(txCloseOutTxnObj, lSig);
        const optEscrowCloseOuttx = await algodClient
            .sendRawTransaction(signEscrowCloseOutTx.blob)
            .do();
        await waitForConfirmation(algodClient, optEscrowCloseOuttx.txId);
        await deleteContract(contract?.guid);
        console.log('CloseOut', optEscrowCloseOuttx);
        return { status: 'revoke' };
    } catch (err) {
        console.log(err);
        return { status: 'error', message: err.message };
    }
};
