import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Typography,
    Box,
    Grid,
    Card,
    Button,
    Avatar,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import {
    ThumbUpOutlined,
    ThumbUp,
    Timer,
    StarBorder,
    Star,
    ChevronRight,
    Height,
} from '@material-ui/icons';
import { Tag } from 'components/shared';
import SeeMoreBtn from '../../shared/SeeMoreBtn';
import AlgoFont from '../../../assets/img/algo-font.svg';
import OpenPicturePopover from './fullScreen';
import MiniInfoModal from '../../elements/modal/miniInfoModal';
import { useHistory } from 'react-router';

const TOP_BIDS = [
    {
        id: 1,
        img: 'https://picsum.photos/680/800?random=1',
        tag: 'img',
        favorite: false,
        timeLeft: '2 hours left',
        title: 'AlgoZest ',
        authorAvatar: 'https://via.placeholder.com/150',
        author: '@comingsoon',
        currentBid: '1.02',
        numberOfBids: 120,
        votes: 180,
        voted: true,
    },
    {
        id: 2,
        img: 'https://picsum.photos/680/800?random=2',
        tag: 'img',
        favorite: true,
        timeLeft: '2 hours left',
        title: 'AlgoZest ',
        authorAvatar: 'https://via.placeholder.com/150',
        author: '@comingsoon',
        currentBid: '1.02',
        numberOfBids: 120,
        votes: 180,
        voted: false,
    },
    {
        id: 3,
        img: 'https://picsum.photos/680/800?random=3',
        tag: 'img',
        favorite: true,
        title: 'AlgoZest ',
        authorAvatar: 'https://via.placeholder.com/150',
        author: '@comingsoon',
        currentBid: '1.02',
        numberOfBids: 120,
        votes: 180,
        voted: true,
    },
    {
        id: 4,
        img: 'https://picsum.photos/680/800?random=4',
        tag: 'img',
        favorite: false,
        timeLeft: '2 hours left',
        title: 'AlgoZest ',
        authorAvatar: 'https://via.placeholder.com/150',
        author: '@comingsoon',
        currentBid: '1.02',
        numberOfBids: 120,
        votes: 180,
        voted: false,
    },
];

const TopBidsCard = () => {
    const history = useHistory();
    const [fullScreenPhoto, setFullScreenPhoto] = useState({ isOpen: false, src: '' });
    const [auctionModal, setAuctionModal] = useState(false);
    const onOpenAuctionModal = () => {
        onCloseAuctionModal();
        setAuctionModal(true);
    };
    const onCloseAuctionModal = () => {
        setAuctionModal(false);
    };
    return (
        <Grid container spacing={3} className="list-with-link">
            {TOP_BIDS.map((bid) => (
                <Grid item lg={3} md={6} sm={6} xs={12} key={bid.id}>
                    <Card
                        className="card h-100 asset-card"
                        style={{ background: `url(${bid.img})` }}
                    >
                        <Box className="card-header" style={{ height: 200 }}>
                            <Tag text={bid.tag} className="secondary sm top-left rotated" />
                            <Box display="flex" className="card-header-actions">
                                <Box
                                    border={1}
                                    borderColor="text.black20"
                                    borderRadius={10}
                                    bgcolor="text.white"
                                    overflow="hidden"
                                    height={48}
                                    width={48}
                                    style={{ backgroundColor: 'rgba(255,255,255,.5)' }}
                                    className="card-image-open"
                                    onClick={() => {
                                        setFullScreenPhoto({
                                            isOpen: true,
                                            src: bid.img,
                                        });
                                    }}
                                >
                                    <button>
                                        <Height style={{ fontSize: 30 }} />
                                    </button>
                                </Box>
                                <Box
                                    border={1}
                                    borderColor="text.black20"
                                    borderRadius={10}
                                    bgcolor="text.white"
                                    overflow="hidden"
                                    height={48}
                                    width={48}
                                    style={{ backgroundColor: 'rgba(255,255,255,.5)' }}
                                    className="card-top-action"
                                >
                                    <FormControlLabel
                                        className="favorite-checkbox"
                                        control={
                                            <Checkbox
                                                disableRipple
                                                checked={bid.favorite}
                                                icon={
                                                    <StarBorder
                                                        style={{ fontSize: 24 }}
                                                        color="secondary"
                                                    />
                                                }
                                                checkedIcon={
                                                    <Star
                                                        style={{ fontSize: 24 }}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        }
                                    />
                                </Box>
                            </Box>
                            <Tag
                                text="TOP"
                                className="brand-red md bottom-left rotated"
                                icon={<i className="icon-fire" style={{ fontSize: 16 }} />}
                            />
                        </Box>

                        <Box pt={2} pb={1} px={3} className="card-footer">
                            {bid.timeLeft && (
                                <Tag
                                    text={bid.timeLeft}
                                    className="brand-red md top-right"
                                    icon={<Timer style={{ fontSize: 16 }} />}
                                />
                            )}
                            <Typography gutterBottom variant="h5">
                                {bid.title}
                            </Typography>

                            <Box fontSize={16} display="flex" alignItems="center">
                                <Avatar alt={bid.author} src={bid.authorAvatar} className="sm" />
                                <Box
                                    fontWeight="bold"
                                    fontFamily="h1.fontFamily"
                                    ml={1}
                                    className="link primary ellipsis"
                                    component={Link}
                                    to="/"
                                >
                                    {bid.author}
                                </Box>
                            </Box>

                            <Box
                                display="flex"
                                justifyContent="space-between"
                                mt={3}
                                mb={2}
                                className="bid-row"
                            >
                                <Box>
                                    <Box fontSize={12} color="text.black50">
                                        Current Bid
                                    </Box>
                                    <Box
                                        fontSize={20}
                                        mt={1}
                                        fontWeight="bold"
                                        className="price-algo"
                                    >
                                        <img src={AlgoFont} alt="Algo" />
                                        <Box fontSize={20} fontWeight="bold" className="bid-price">
                                            {bid.currentBid}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="flex-end"
                                    fontWeight="bold"
                                >
                                    <Box
                                        fontSize={14}
                                        color="text.black50"
                                        display="flex"
                                        alignItems="center"
                                        className="num-of-bids"
                                    >
                                        Num of Bids:
                                        <Box color="text.black"> {bid.numberOfBids}</Box>
                                    </Box>
                                    <Box
                                        fontSize={16}
                                        mt={1}
                                        display="flex"
                                        alignItems="center"
                                        className={`voted ${bid.voted ? 'color-primary' : ''}`}
                                    >
                                        {bid.voted ? (
                                            <ThumbUp
                                                style={{ fontSize: 24 }}
                                                className="pointer hover-opacity"
                                            />
                                        ) : (
                                            <ThumbUpOutlined
                                                style={{ fontSize: 24 }}
                                                className="pointer hover-opacity"
                                            />
                                        )}
                                        <Box component="span" ml={1}>
                                            {bid.votes}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Button variant="outlined" color="secondary" size="large" fullWidth>
                                Place a Bid
                            </Button>
                        </Box>
                        {bid.timeLeft && (
                            <Tag
                                text={bid.timeLeft}
                                className="brand-red md bottom-right"
                                icon={<Timer style={{ fontSize: 16 }} />}
                            />
                        )}
                    </Card>
                </Grid>
            ))}

            {/*Desktop "see more" button (blue round with arrow)*/}
            <Box display={{ xs: 'none', sm: 'block' }}>
                <Link
                    to={history.location.pathname}
                    className="list-link"
                    onClick={onOpenAuctionModal}
                >
                    <ChevronRight style={{ fontSize: 56 }} />
                </Link>
            </Box>

            {/*Mobile "see more" button*/}
            <Box
                display={{ xs: 'flex', sm: 'none' }}
                justifyContent="center"
                style={{ width: '100%' }}
            >
                <SeeMoreBtn />
            </Box>
            <OpenPicturePopover
                fullScreenPhoto={fullScreenPhoto}
                onClose={() => {
                    setFullScreenPhoto({ isOpen: false, src: '' });
                }}
            />
            <MiniInfoModal
                open={auctionModal}
                close={onCloseAuctionModal}
                title="This is coming soon! Please check it in our next release!"
            />
        </Grid>
    );
};

export default TopBidsCard;
