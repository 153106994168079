import ProfileCollected from 'pages/profile/ProfileCollected';
import ProfileCreated from 'pages/profile/ProfileCreated';
import ProfileFavorites from 'pages/profile/ProfileFavorites';
import ProfileFollowing from 'pages/profile/ProfileFollowing';
import ProfileFollowers from 'pages/profile/ProfileFollowers';
import ActivityAll from 'pages/profile/activity/ActivityAll';

const PROFILE_TABS_OWN = [
    {
        label: 'Created',
        component: <ProfileCreated />,
    },
    {
        label: 'Collected',
        component: <ProfileCollected />,
    },
    {
        label: 'Favorites',
        component: <ProfileFavorites />,
    },
];
const PROFILE_TABS = [
    {
        label: 'Created',
        component: <ProfileCreated />,
    },
    {
        label: 'Collected',
        component: <ProfileCollected />,
    },
];

const PROFILE_FOLLOWER_TABS = [
    {
        label: 'Followers',
        component: <ProfileFollowers />,
    },
    {
        label: 'Following',
        component: <ProfileFollowing />,
    },
];

let ACTIVITY_TABS = [
    {
        label: 'All',
        component: <ActivityAll />,
    },
    {
        label: 'Following',
        component: '',
    },
    {
        label: 'My Activity',
        component: '',
    },
];

export const TABS = {
    PROFILE_TABS_OWN,
    PROFILE_TABS,
    PROFILE_FOLLOWER_TABS,
    ACTIVITY_TABS,
};
