import React, { useRef, useState, Suspense } from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    Checkbox,
    FormControlLabel,
    Typography,
} from '@material-ui/core';

import { Tag } from '../../shared';
import { Star, StarBorder, ThumbUp, ThumbUpOutlined, Timer, Height } from '@material-ui/icons';
import CreateIcon from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import AlgoFont from '../../../assets/img/algo-font.svg';
import OpenPicturePopover from './fullScreen';
import { needToLoginAction } from 'redux/auth/actions';
import CardMenu from './cardMenu';
import Lottie from 'lottie-react';
import loader from 'assets/img/lf30_editor_dwdfl6eb.json';

const Loading = () => {
    return (
        <div className="card-lottie">
            <Lottie animationData={loader} style={{ height: '50px', width: '153px' }} />
        </div>
    );
};
const LoadImage = React.lazy(() => import('./assetCardImage'));

const AssetCard = ({
    tag,
    favorite,
    img,
    thumbnail,
    content_type,
    timeLeft,
    title,
    currentBid,
    myBid,
    numberOfBids,
    price,
    voted,
    votes,
    button,
    isSeries,
    isDisabled,
    isEditable,
    guid,
    upvoteAsset,
    unUpvoteAsset,
    toFavoritesAssets,
    removeFromFavoritesAssets,
    isLoggedIn,
    changeAssetVisibility,
    nodes,
    bestOffer,
    creator,
    assetId,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { user } = useSelector((state) => state.auth);
    const [fullScreenPhoto, setFullScreenPhoto] = useState({ isOpen: false, src: '' });
    const [disableVoted, setDisableVoted] = useState(false);
    const [disableFollowing, setDisableFollowing] = useState(false);

    const openSignIn = () => dispatch(needToLoginAction(true));

    const currentUserHaveAsset = nodes?.find((node) => node?.owner?.username === user?.username);
    const canEdit = currentUserHaveAsset?.quantity > 0;
    const canFollowAndVoted = isLoggedIn && user?.username !== creator?.username;
    // const showedUser = bestOffer?.owner || creator;
    const showedUser = creator;
    const activeContract = bestOffer?.contracts?.find((x) => x.status === 'ACTIVE');
    const showedPrice = bestOffer?.price && activeContract ? bestOffer?.price : '-';
    const cardRef = useRef(null);

    const upvote = (e) => {
        e.stopPropagation();

        if (canFollowAndVoted && !disableVoted) {
            setDisableVoted(true);
            return dispatch(upvoteAsset(guid)).then((res) => {
                setDisableVoted(false);
            });
        }
        if (!isLoggedIn) {
            openSignIn();
        }
    };

    const unUpvote = (e) => {
        e.stopPropagation();
        if (canFollowAndVoted && !disableVoted) {
            setDisableVoted(true);
            return dispatch(unUpvoteAsset(guid)).then((res) => {
                setDisableVoted(false);
            });
        }
    };

    const toFavorites = () => {
        if (canFollowAndVoted && !disableFollowing) {
            setDisableFollowing(true);
            return dispatch(toFavoritesAssets(guid)).then((res) => {
                setDisableFollowing(false);
            });
        }
        if (!isLoggedIn) {
            openSignIn();
        }
    };

    const removeFromFavorites = () => {
        if (canFollowAndVoted && !disableFollowing) {
            setDisableFollowing(true);
            return dispatch(removeFromFavoritesAssets(guid)).then((res) => {
                setDisableFollowing(false);
            });
        }
        if (!isLoggedIn) {
            openSignIn();
        }
    };

    const handleChangeFavorites = (e) => {
        e.stopPropagation();
        if (favorite) {
            removeFromFavorites();
        } else {
            toFavorites();
        }
    };
    const showAsset = () => {
        if (isLoggedIn) {
            return history.push(`/asset/${assetId}`);
        }
        openSignIn();
    };
    return (
        <Card
            className={`card asset-card h-100 ${isDisabled ? 'card-disabled' : ''} ${
                isSeries ? 'card-series' : ''
            }`}
            style={{
                // background: `url(${img})`,
                cursor: isLoggedIn ? 'pointer' : 'default',
            }}
            onClick={() => showAsset()}
            ref={cardRef}
        >
            <Box className="asset-file-container">
                <Suspense fallback={<Loading />}>
                    <LoadImage content_type={content_type} img={img} thumbnail={thumbnail} />
                </Suspense>
            </Box>
            <Box className="card-header" style={{ height: 200 }}>
                {tag && <Tag text={tag} className="secondary sm top-left rotated" />}
                <Box
                    display="flex"
                    className="card-header-actions"
                    onClick={(e) => e.stopPropagation()}
                >
                    <Box
                        border={1}
                        borderColor="text.black20"
                        borderRadius={10}
                        bgcolor="text.white"
                        overflow="hidden"
                        height={48}
                        width={48}
                        style={{ backgroundColor: 'rgba(255,255,255,.5)' }}
                        className="card-image-open"
                        onClick={(e) => {
                            e.stopPropagation();
                            setFullScreenPhoto({ isOpen: true, src: img });
                        }}
                    >
                        <button>
                            <Height style={{ fontSize: 30 }} />
                        </button>
                    </Box>
                    {canEdit && (
                        <Box
                            border={1}
                            borderColor="text.black20"
                            borderRadius={10}
                            bgcolor="text.white"
                            overflow="hidden"
                            height={48}
                            width={48}
                            style={{ backgroundColor: 'rgba(255,255,255,.5)' }}
                            className="card-top-action"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <CardMenu
                                currentAsset={{
                                    id: assetId,
                                    guid: guid,
                                }}
                                changeAssetVisibility={changeAssetVisibility}
                                node={currentUserHaveAsset}
                                cardRef={cardRef}
                            />
                        </Box>
                    )}
                    {canFollowAndVoted && (
                        <Box
                            border={1}
                            borderColor="text.black20"
                            borderRadius={10}
                            bgcolor="text.white"
                            overflow="hidden"
                            height={48}
                            width={48}
                            style={{ backgroundColor: 'rgba(255,255,255,.5)' }}
                            className="card-top-action"
                        >
                            <FormControlLabel
                                className="favorite-checkbox"
                                control={
                                    <Checkbox
                                        disableRipple
                                        checked={favorite}
                                        onChange={handleChangeFavorites}
                                        icon={<StarBorder style={{ fontSize: 24 }} />}
                                        checkedIcon={
                                            <Star style={{ fontSize: 24 }} color="primary" />
                                        }
                                    />
                                }
                            />

                            {isEditable && (
                                <Button className="hover-bg-secondary h-100">
                                    <CreateIcon />
                                </Button>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>

            <Box pt={2} pb={1} px={3} className="card-footer">
                {timeLeft && (
                    <Tag
                        text={timeLeft}
                        className="brand-red md top-right"
                        icon={<Timer style={{ fontSize: 16 }} />}
                    />
                )}
                {title && (
                    <Typography gutterBottom variant="h5">
                        {title}
                    </Typography>
                )}

                <Box fontSize={16} display="flex" alignItems="center">
                    {showedUser && (
                        <Avatar
                            alt={showedUser?.username}
                            src={showedUser?.avatar}
                            className="sm"
                        />
                    )}
                    {showedUser && (
                        <Box
                            fontWeight="bold"
                            fontFamily="h1.fontFamily"
                            ml={1}
                            className="link primary ellipsis"
                            component={Link}
                            to="/"
                        >
                            @{showedUser?.username}
                        </Box>
                    )}
                </Box>

                <Box
                    display="flex"
                    justifyContent="space-between"
                    mt={3}
                    mb={2}
                    className="bid-row"
                >
                    {currentBid && (
                        <Box>
                            <Box fontSize={12} color="text.black50">
                                Current Bid
                            </Box>
                            <Box fontSize={20} mt={1} fontWeight="bold" className="price-algo">
                                <img src={AlgoFont} alt="Algo" />
                                <Box fontSize={20} fontWeight="bold" className="bid-price">
                                    {currentBid}
                                </Box>
                            </Box>
                        </Box>
                    )}
                    {/* {price && ( */}
                    <Box fontSize={20} fontWeight="bold" className="price-algo">
                        <img src={AlgoFont} alt="Algo" />
                        <span>{showedPrice}</span>
                    </Box>
                    {/* )} */}
                    {myBid && (
                        <Box>
                            <Box fontSize={12} color="text.black50">
                                My Bid
                            </Box>
                            <Box fontSize={20} mt={1} fontWeight="bold" className="price-algo">
                                <img src={AlgoFont} alt="Algo" />
                                <Box fontSize={20} fontWeight="bold" className="bid-price">
                                    {myBid}
                                </Box>
                            </Box>
                        </Box>
                    )}
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                        fontWeight="bold"
                    >
                        {numberOfBids && (
                            <Box
                                fontSize={14}
                                color="text.black50"
                                display="flex"
                                alignItems="center"
                                className="num-of-bids"
                            >
                                Num of Bids:
                                <Box color="text.black"> {numberOfBids}</Box>
                            </Box>
                        )}
                        <Box
                            fontSize={16}
                            mt={1}
                            display="flex"
                            alignItems="center"
                            className={`${voted ? 'color-primary' : ''}`}
                        >
                            {voted ? (
                                <ThumbUp
                                    style={{ fontSize: 24 }}
                                    className="pointer hover-opacity"
                                    onClick={unUpvote}
                                />
                            ) : (
                                <ThumbUpOutlined
                                    style={{ fontSize: 24 }}
                                    className="pointer hover-opacity"
                                    onClick={upvote}
                                />
                            )}
                            <Box component="span" ml={1}>
                                {votes}
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {button && (
                    <Button variant="outlined" color="secondary" size="large" fullWidth>
                        Place a Bid
                    </Button>
                )}
            </Box>

            {timeLeft && (
                <Tag
                    text={timeLeft}
                    className="brand-red md bottom-right"
                    icon={<Timer style={{ fontSize: 16 }} />}
                />
            )}
            {fullScreenPhoto.isOpen && (
                <OpenPicturePopover
                    fullScreenPhoto={fullScreenPhoto}
                    onClose={() => {
                        setFullScreenPhoto({ isOpen: false, src: '' });
                    }}
                    content_type={content_type}
                />
            )}
        </Card>
    );
};

export default AssetCard;
